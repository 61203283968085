import React from 'react'

function PageLoadingAnimation({ loading, generateAfterLoading }) {
    return (
        <div
            style={{
                width: '100%',
                height: '100vh',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column'
            }}
        >
            <img src='/assets/images/page-loading-anim.gif' style={{ width: '40%' }} />
            {/* <img src='/assets/images/upper-text-black.svg' style={{ width: '40%' }} /> */}
            {/* <ReactLoading type={'bars'} color={'black'} height={'20%'} width={'5%'} /> */}
        </div>
    )
    // else {
    //     return (generateAfterLoading)
    // }
}

export default PageLoadingAnimation