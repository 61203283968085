import imageCompression from 'browser-image-compression';

export const readAndCompressImg = async (file) => {
    return new Promise(async (resolve, reject) => {
        const reader = new FileReader();
        const compressedFile = await imageCompression(file, {
            maxSizeMB: 0.2,
            maxWidthOrHeight: 1920,
        });
        reader.readAsDataURL(compressedFile);
        reader.onload = () => {
            resolve(reader.result);
        }
        reader.onerror = (err) => console.log(err);

    })
        .catch((error) => {
            console.log(error);
        });

}