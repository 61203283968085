import React, { useState } from 'react'
function Step1({ handleTextInputs, sendEmailVerification, termsOfUse, setTermsOfUse }) {

    return (
        <div className='appyl-inputs-div'>
            <h5 style={{ color: 'black', fontWeight: 'bold' }}>Freelance boldly with UpperBoard</h5>
            <h6 style={{ color: 'black' }}>Everything a freelancer needs in one place</h6>
            <input className='col-sm-8 col-md-8 col-xl-5 apply-input' placeholder='Email' onChange={(e) => handleTextInputs(e.target.value, 'email')} />

            <p >Please check your spam box for validation code!</p>
            <div>

                <input type="checkbox" checked={termsOfUse} id="vehicle2" name="vehicle2" value="Car"
                    onChange={(e) => setTermsOfUse(e.target.checked)} />
                <label for="vehicle2"> Accept to our <a href='' data-toggle="modal" data-target="#privacyModal" >privacy policy</a> and <a href='' data-toggle="modal" data-target="#cookieModal" >cookie policy</a>
                </label>
            </div>
            <button className='apply-buttons' onClick={sendEmailVerification}>Send Verification Code</button>


        </div>
    )
}

export default Step1