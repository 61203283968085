import React from 'react';

function PlanButton({ onClick, title, description, price, active, index, suggested }) {
    return (
        <div style={{ position: 'relative' }}>
            <button
                className={active ? 'auth-role-button active' : 'auth-role-button'}
                onClick={() => onClick(index)}
                style={{ minHeight: 300, textAlign: 'left', padding: '20px', position: 'relative' }}
            >
                {suggested && (
                    <div style={{
                        position: 'absolute',
                        top: '10px',
                        right: '10px',
                        backgroundColor: '#E6852B',
                        color: 'white',
                        padding: '5px 10px',
                        fontWeight: 'bold',
                        borderRadius: '5px'
                    }}>
                        Suggested
                    </div>
                )}
                <div>
                    <h4>{title}</h4>
                    <div>{description}</div>
                    <div style={{ marginTop: '10px', fontSize: '20px', fontWeight: 'bold', position: 'absolute', bottom: '10px', right: '20px' }}>{price}</div>
                </div>
            </button>
        </div>
    );
}

export default PlanButton;
