export const styles = {
    main: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-evenly',
        backgroundColor: 'blue',
        flexDirection: 'row'
    },
    input: {
        border: '1px solid grey',
        borderRadius: 10,
        background: 'transparent',
        color: 'black',
        padding: 15,
        margin: 10,
        width: '100%',
    },
    inputsDiv: {
        width: '50%',
        textAlign: 'center',
        paddingTop: 50,
    },
    button: {
        display: 'flex',
        width: 200,
        justifyContent: 'center',
        alignItems: 'center',
        margin: 20,
        backgroundColor: 'black',
        fontSize: 15,
        color: 'white',
        border: 'none',
        padding: 10,
        borderRadius: 10
    }

}