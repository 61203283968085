import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import Dropzone from "react-dropzone";
import ReactLoading from "react-loading";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  FREELANCER_CREATE,
  LINK_NICK_IS_AVAILABLE
} from "../../../constants/links";
import {
  getEmailFromCookie,
  getUserIdFromCookie
} from "../../../helpers/cookieHelper";
import { setPortfolio } from "../../../redux/freelancer/portfolioSlice";
import About from "./components/createComponents2/About";
import Experience from "./components/createComponents2/Experience";
import Personal from "./components/createComponents2/Personal";
import ProfileCard from "./components/createComponents2/ProfileCard";
import Skill from "./components/createComponents2/Skill";
import SocialLinks from "./components/createComponents2/SocialLinks";
import Works from "./components/createComponents2/Works";
import { readAndCompressImg } from "./Events/imageEvents";

const CreateUpperProfile = ({ userProfile }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const [skillSections, setSkillSections] = useState([{ title: "", description: "" }]);
  const [experiencesSection, setExperiencesSection] = useState([{ title: "", description: "", date: "" }]);
  const [socialMedias, setSocialMedias] = useState([]);
  const [profile, setProfile] = useState({
    fullName: "",
    role: "",
    linkNick: "",
    about: "",
    profileImg: "",
    showcase: "",
    email: "",
  });
  const [loadingShareButton, setLoadingShareButton] = useState(false);
  const [linkError, setLinkError] = useState({});
  const linkNickRef = useRef();
  const [islinkNickLoading, setIsLinkNickLoading] = useState(false)

  const [cardLoading, setCardLoading] = useState(false)
  const [profileImgLoading, setProfileImgLoading] = useState(false)

  useEffect(() => {
    setProfile({
      fullName: userProfile.fullName,
      role: userProfile.role,
      linkNick: userProfile.linkNick,
      about: userProfile.about,
      profileImg: userProfile.profileImg,
      email: getEmailFromCookie(),
      showcase: userProfile.showcase,
    });
    setSocialMedias(userProfile.socialMedias);
    setSkillSections(userProfile.skills);
    setExperiencesSection(userProfile.experiences);
    linkNickRef.current = userProfile.linkNick;
  }, [userProfile]);

  const handleTextInputs = (value, name) => {
    let updatedValue = { [name]: value };
    setProfile((info) => ({
      ...info,
      ...updatedValue,
    }));
  };

  const handleSubmit = async () => {

    if (linkError.color === 'red') {
      toast.error('Your linknick is not available!', {
        theme: "colored",
        position: "bottom-center",
      });
      return
    }
    setLoadingShareButton(true);
    axios
      .post(FREELANCER_CREATE, {
        withCredentials: true,
        ...profile,
        skills: skillSections,
        experiences: experiencesSection,
        socialMedias: socialMedias,
      }, {
        headers: {
          userid: getUserIdFromCookie()
        }
      }
      )
      .then((res) => {
        console.log(res.data);
        dispatch(setPortfolio(res.data));
        setLoadingShareButton(false);
        toast.success("Profile Created/Updated Successfully", {
          theme: "colored",
          position: "bottom-center",
        });
      })
      .catch((err) => {
        setLoadingShareButton(false);
        console.log(err);
        toast.error(err.response.data.message[0], {
          theme: "colored",
          position: "bottom-center",
        });
      });
  };

  const isLinkNickAvailable = (text) => {
    setIsLinkNickLoading(true)
    axios
      .get(LINK_NICK_IS_AVAILABLE + text)
      .then((res) => {
        if (res.data === true) {
          setLinkError({
            errorMessage: "This link nick is available",
            color: "green",
          });
          setIsLinkNickLoading(false)

        } else {
          setLinkError({
            errorMessage: "This link nick is not available",
            color: "red",
          });
          setIsLinkNickLoading(false)

        }
      })
      .catch((err) => {
        console.log(err);
        setIsLinkNickLoading(false)

      });
  };

  const handleSocialMedias = (value, index) => {
    let tmpMedias = [...socialMedias];
    tmpMedias[index].value = value;
    setSocialMedias(tmpMedias);
  };

  const handleSkillAdd = () => {
    if (skillSections.length < 5) {
      setSkillSections((skillSections) => [
        ...skillSections,
        { title: "", description: "" },
      ]);
    } else {
      toast.error("You can add max 5 skills", {
        theme: "colored",
        position: "bottom-center",
      });
    }
  };

  const handleSkillDelete = (index) => {
    let temp = [...skillSections];
    temp.splice(index, 1);
    setSkillSections(temp);
  };

  const handleOnChangeSkills = (name, index, value) => {
    let temp = [...skillSections];
    temp[index][name] = value;
    setSkillSections(temp);
  };

  const handleExperienceAdd = () => {
    if (experiencesSection.length < 5) {
      setExperiencesSection((experiencesSection) => [
        ...experiencesSection,
        { title: "", description: "", date: "" },
      ]);
    } else {
      toast.error("You can add max 5 experience", {
        theme: "colored",
        position: "bottom-center",
      });
    }
  };

  const handleExperienceDelete = (index) => {
    let temp = [...experiencesSection];
    temp.splice(index, 1);
    setExperiencesSection(temp);
  };

  const handleOnChangeExperience = (name, index, value) => {
    let temp = [...experiencesSection];
    temp[index][name] = value;
    setExperiencesSection(temp);
  };

  const handleDeleteSocialMedia = (index) => {
    let temp = [...socialMedias];
    temp.splice(index, 1);
    setSocialMedias(temp);
  };

  return (
    <div className="outer-container">
      <ToastContainer />
      <div className="header-container">
        <h2 className="portfolio-title">Portfolio Details</h2>
        <div className="common-button-container">
          <button className="common-button discover-themes-button">
            Discover Themes
          </button>
          <button className="common-button" onClick={() => navigate("/dashboard/" + linkNickRef.current)}>
            My Portfolio
          </button>
          <button className="common-button">
            Edit
          </button>
        </div>
      </div>

      <div className="inner-container">
        <div className="card-container">
          <ProfileCard profile={profile} handleTextInputs={handleTextInputs} cardLoading={cardLoading} setCardLoading={setCardLoading} />

          <div style={{ textAlign: 'center' }}>

            <Dropzone onDrop={acceptedFiles => {
              setProfileImgLoading(true)
              readAndCompressImg(acceptedFiles[0])
                .then((compressedFile) => {
                  handleTextInputs(compressedFile, 'profileImg')
                  setProfileImgLoading(false)

                })
            }}>
              {({ getRootProps, getInputProps }) => (
                <section style={{ cursor: 'pointer', display: 'flex', justifyContent: 'center', alignItems: 'center', height: 300, width: 300, borderRadius: '100%', backgroundColor: 'grey' }}>
                  <div {...getRootProps()}>
                    <input  {...getInputProps()} />
                    {
                      profileImgLoading ? (
                        <ReactLoading type={'spinningBubbles'} color={'white'} height={50} width={50} />
                      ) : (
                        <>
                          {
                            profile.profileImg === '' ? (
                              <p style={{ padding: 10 }}>Drag and drop your profile photo here, or click to select file</p>
                            )
                              : (
                                <img style={{ width: 300, height: 300, borderRadius: '100%', objectFit: 'cover' }} src={profile.profileImg} alt="" />
                              )
                          }
                        </>
                      )
                    }


                    {/* */}
                  </div>

                </section>
              )}
            </Dropzone>




          </div>
        </div>

        <Personal
          islinkNickLoading={islinkNickLoading}
          linkNickRef={linkNickRef}
          handleTextInputs={handleTextInputs}
          values={{
            fullName: profile.fullName,
            role: profile.role,
            linkNick: profile.linkNick,
            email: profile.email,
          }}
          linkError={linkError}
          isLinkNickAvailable={isLinkNickAvailable}
        />

        <div className="divider"></div>

        <About
          handleTextInputs={handleTextInputs}
          value={profile.about}
        />

        <div className="divider"></div>

        <Experience
          experiencesSection={experiencesSection}
          handleOnChangeExperience={handleOnChangeExperience}
          handleExperienceDelete={handleExperienceDelete}
          handleExperienceAdd={handleExperienceAdd}
        />

        <div className="divider"></div>

        <Skill
          skillSections={skillSections}
          handleOnChangeSkills={handleOnChangeSkills}
          handleSkillDelete={handleSkillDelete}
          handleSkillAdd={handleSkillAdd}
        />

        <div className="divider"></div>

        <Works
          handleSubmit={handleSubmit} userId={userProfile.userId} />

        <div className="divider"></div>

        <SocialLinks
          socialMedias={socialMedias}
          handleSocialMedias={handleSocialMedias}
          handleDeleteSocialMedia={handleDeleteSocialMedia}
          setSocialMedias={setSocialMedias}
        />
      </div>

      <div className="save-changes-container">
        {/* {
          linkError.color == 'green' ? (
            
          )
        } */}
        <button
          className="common-button save-changes-button"
          onClick={handleSubmit}
        >
          {loadingShareButton ? (
            <ReactLoading type="spinningBubbles" color="white" height={20} width={20} />
          ) : (
            <>Save Changes</>
          )}
        </button>
      </div>
    </div>
  );
};

export default CreateUpperProfile;
