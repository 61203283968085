import React from "react";
import "../createComponents2/Styles/Personal.scss";

function About({ handleTextInputs, value }) {
  return (
    <div className="personal-edit-container">
      <h2>About</h2>
      <div className="input-container">
        <div className="input-group">
          <div>
            <h3>About</h3>
            <textarea
              type="text"
              style={{
                height: "150px",
                width: "100%",
                borderRadius: "10px",
                resize: "none",
                borderColor: "#BFBFBF",
              }}
              value={value}
              onChange={(e) => handleTextInputs(e.target.value, 'about')}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
