import React, { useEffect, useState } from 'react'
import ReactLoading from 'react-loading'
import { readAndCompressImg } from '../../Events/imageEvents'

function BlogInputItem({ index, input, handleOnChange, handleDeleteItem, setAlign, handleChangeImgScale, handleReLocateItems }) {
    const [imgWidth, setImgWidth] = useState('100%')
    const [imgHeight, setImgHeight] = useState('350px')
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if (input.type == 'file') {
            switch (input.scale) {
                case 1:
                    setImgWidth('100%')
                    setImgHeight('350px')
                    break;
                case 2:
                    setImgWidth('100%')
                    setImgHeight('450px')
                    break;
                case 3:
                    setImgWidth('100%')
                    setImgHeight('550px')
                    break;
                default:
                    break;
            }
        }
    }, [input.scale])

    return (
        <div className='blog-content' key={index}>
            <div className='blog-aligns-div'>
                <button className='blog-align-button' onClick={() => setAlign(index, 'start')}>
                    <i className='bx bx-align-left'></i>
                </button>
                <button className='blog-align-button' onClick={() => setAlign(index, 'center')}>
                    <i className='bx bx-align-middle'></i>
                </button>
                <button className='blog-align-button' onClick={() => setAlign(index, 'end')}>
                    <i className='bx bx-align-right'></i>
                </button>
            </div>
            <div className='d-flex justify-content-center w-100'>

                <div>
                    <div style={{ color: 'white', fontSize: '40px', cursor: 'pointer', marginTop: '10px', height: '15px' }}
                        onClick={() => handleReLocateItems(index, 'up')}>
                        <i class='bx bx-chevron-up'></i>
                    </div>
                    <div style={{ color: 'white', fontSize: '40px', cursor: 'pointer', marginTop: '10px', height: '15px' }}
                        onClick={() => handleReLocateItems(index, 'down')}>
                        <i class='bx bx-chevron-down'></i>
                    </div>
                </div>

                <input.devTag
                    className={input.className}
                    type={input.type}
                    placeholder={input.placeholder}
                    value={input.type == 'file' ? '' : input.content}
                    id={input.type == 'file' ? 'content-file-input' + index : ''}
                    style={{ display: input.type == 'file' ? 'none' : '', textAlign: input.align }}
                    onChange={async (e) => {
                        setLoading(true)
                        const value = input.type == 'file' ? await readAndCompressImg(e.target.files[0]) : e.target.value
                        handleOnChange(index, value)
                        setLoading(false)
                    }}
                />

                {
                    input.type == 'file' ? (
                        <div className="cover-img-div" style={{ height: imgHeight, justifyContent: input.align }}>
                            {

                                loading ? (
                                    <div className='d-flex justify-content-center w-100'>
                                        <ReactLoading type={'spinningBubbles'} color={'white'} height={50} width={50} />
                                    </div>
                                ) : (
                                    <>
                                        {input.content !== '' ? (
                                            <label for={'content-file-input' + index} style={{ cursor: 'pointer' }}>
                                                <img src={input.content} style={{
                                                    width: imgWidth,
                                                    height: imgHeight,
                                                    objectFit: 'cover',
                                                    borderRadius: '35px'
                                                }} />
                                            </label>
                                        ) : (
                                            <div className='w-100 d-flex justify-content-center'>
                                                <div className='plus-circle'>
                                                    <label for={'content-file-input' + index} style={{ cursor: 'pointer' }}>
                                                        <i className='bx bx-plus'></i>
                                                    </label>
                                                </div>
                                            </div>
                                        )}
                                    </>
                                )

                            }
                        </div>
                    ) : (<></>)
                }

                <div>
                    <div style={{ color: 'white', fontSize: '40px', cursor: 'pointer', marginTop: '10px', height: '15px' }}
                        onClick={() => handleDeleteItem(index)}>
                        <i className='bx bx-x'></i>
                    </div>
                    {
                        input.type == 'file' ? (
                            <div style={{ color: 'white', cursor: 'pointer', marginTop: '50px', height: '15px', marginLeft: '10px' }}
                                onClick={() => handleChangeImgScale(index)}>
                                <svg xmlns="http://www.w3.org/2000/svg" width='21px' height='21px' viewBox="0 0 24 21" >
                                    <path d="M0 21V19.6875H24V21H0ZM0 17.0625V14.4375H24V17.0625H0ZM0 11.8125V7.875H24V11.8125H0ZM0 5.25V0H24V5.25H0Z" fill="#FEFEFE" />
                                </svg>
                            </div>
                        ) : (<> </>)
                    }

                </div>
            </div>
        </div>
    )
}

export default BlogInputItem