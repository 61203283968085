import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    account: {},
}

export const companyAccountSlice = createSlice({
    name: 'companyAccount',
    initialState,
    reducers: {

        setCompanyAccount: (state, action) => {
            state.account = action.payload
        }
    },
})

// Action creators are generated for each case reducer function
export const { setCompanyAccount } = companyAccountSlice.actions

export default companyAccountSlice.reducer