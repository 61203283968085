import React from 'react'
import { styles } from './styles/experiencesStyle'

function Experiences({ experiences }) {
    return (
        <section style={styles.main}>
            <div >
                <div class="row">
                    <div class="col-lg-6">
                        <div >
                            <div >
                                <h2 >
                                    <span style={styles.span}>Experience</span>
                                </h2>
                            </div>
                        </div>

                    </div>
                    <div class="col-lg-6 ">
                        <div >
                            {
                                experiences !== undefined &&
                                experiences.map((item, index) => {
                                    if (item !== null) {
                                        return (
                                            <div style={styles.experienceDiv} >
                                                <div>
                                                    <h6>{item.title}</h6>
                                                    <p >{item.description}</p>
                                                </div>
                                                <div style={styles.experienceRight}>
                                                    <p>{item.date}</p>
                                                </div>
                                            </div>
                                        )
                                    }
                                })}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Experiences