import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import PageLoadingAnimation from '../../../components/Loading/PageLoadingAnimation'
import UpperModal from '../../../components/UpperModal'
import { DELETE_TICKET, GET_TICKET_BY_ID } from '../../../constants/links'
import { getUserIdFromCookie } from '../../../helpers/cookieHelper'
import CompanyApplyCard from './components/CompanyApplyCard'
import DeleteTicket from './components/DeleteTicket'
import FreelancerApplyCard from './components/FreelancerApplyCard'

function TicketDetail() {
    const location = useLocation()
    const navigate = useNavigate()

    const [loading, setLoading] = useState(true)
    const [publisher, setPublisher] = useState()
    const [ticket, setTicket] = useState({})

    useEffect(() => {

        if (location.state == undefined) {
            navigate('/dashboard/')
        } else {
            axios.get(GET_TICKET_BY_ID, {
                headers: {
                    ticketid: location.state.ticketId
                }
            })
                .then((res) => {
                    console.log(res.data)
                    setTicket(res.data.ticket)
                    setPublisher(res.data.publisher)
                    setLoading(false)
                })
                .catch((err) => {
                    console.log(err)
                })
        }

    }, [])

    const deleteTicket = () => {
        axios.post(DELETE_TICKET, {
            ticketId: ticket._id,
            userId: getUserIdFromCookie()
        })
            .then((res) => {
                navigate('/')
            })
            .catch((err) => console.log(err))
    }


    if (loading) {
        return (
            <PageLoadingAnimation />
        )
    }
    else {
        return (
            <div className='ticket-detail-main row'>
                <UpperModal modalId={'deleteTicket'} type={'md'} contentHTML={<DeleteTicket deleteTicket={deleteTicket} />} />

                <div className='col-lg-4'>
                    {
                        ticket.users.length == 0 ? (
                            <>
                                <h3 className='py-2'>No Matched Freelancers</h3>
                                <p>You can delete this ticket now</p>
                                <button data-toggle="modal" data-target="#deleteTicket" className='common-purple-button' >Delete This Ticket</button>
                            </>

                        ) : (
                            <>
                                <h3 className='py-2'>Applied Freelancers</h3>

                                {
                                    ticket && ticket.ticketPublisherId == getUserIdFromCookie() ? ticket.users.map((user) => (
                                        <CompanyApplyCard user={user} ticket={ticket} setTicket={setTicket} setLoading={setLoading} />
                                    )

                                    ) : (
                                        <FreelancerApplyCard ticket={ticket} />
                                    )
                                }
                            </>
                        )
                    }




                </div>

                <div className='col-lg-8'>

                    <div className='ticket-detail p-4'>
                        <div className='d-flex'>
                            <img className='logo' src={publisher.profileImg} />
                            <div className='company-info'>
                                <div>{publisher.fullName}</div>
                            </div>
                        </div>
                        <div className='mt-4'>
                            <h3>Title</h3>
                            <p>{ticket.title}</p>
                            <h3>Description</h3>
                            <p>{ticket.description}</p>
                        </div>
                    </div>

                </div>
            </div>
        )
    }

}

export default TicketDetail