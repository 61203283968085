import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { SET_FREELANCER_ACCEPTED } from '../../../../constants/links'
import { getUserIdFromCookie } from '../../../../helpers/cookieHelper'
function FreelancerApplyCard({ ticket }) {
    const [user, setUser] = useState(undefined)
    const navigate = useNavigate()

    useEffect(() => {
        if (ticket.users != undefined) {
            ticket.users.forEach(item => {
                if (item.userId == getUserIdFromCookie()) {
                    setUser(item)
                }
            });
        }

        console.log(user)


    }, [])


    const applyTicket = (acceptedValue) => {
        setUser({ ...user, isInviterAccept: acceptedValue })


        axios.post(SET_FREELANCER_ACCEPTED, {
            ticketId: ticket._id,
            inviterUserId: getUserIdFromCookie(),
            acceptedValue: acceptedValue
        })
            .then((res) => {
                console.log(res.data)
                if (!acceptedValue) {
                    navigate('/dashboard')
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }

    return (
        <div className='freelancer-card p-3'>
            <div className='d-flex align-items-center'>
                <div className='company-info'>
                    <div style={{ fontWeight: 'bold' }}>You've matched this ticket.
                        If this project is right for you, apply now.</div>
                </div>
            </div>
            <div className='d-flex justify-content-end pt-4 px-2'>
                {
                    user != undefined && user.isInviterAccept ? (
                        <div>Applied. Company response is pending</div>
                    ) : (
                        <>
                            <button onClick={() => applyTicket(true)} className='mx-2 action-buttons'>Apply</button>
                            <button onClick={() => applyTicket(false)} className='mx-2 action-buttons'>Reject</button>
                        </>
                    )
                }


                {/* {
                    freelancer != undefined && (
                        <>
                            {
                                freelancer.acceptedFreelancer ? (
                                    <div>Applied. Company response is pending</div>
                                ) : (
                                    <>
                                        <button onClick={() => applyTicket(true)} className='mx-2 action-buttons'>Apply</button>
                                        <button onClick={() => applyTicket(false)} className='mx-2 action-buttons'>Reject</button>
                                    </>
                                )
                            }
                        </>
                    )
                } */}

            </div>
        </div>
    )
}

export default FreelancerApplyCard