import { BrowserRouter, Route, Routes } from "react-router-dom";
import './App.scss';
import Home from './pages/Home';
import Redirect from "./pages/Redirect";
import GuessView from "./pages/guessView/GuessView";
// import './index.css'

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Redirect />} />
        <Route path="/*" element={<GuessView />} />
        <Route path="/dashboard/" element={<Home />} />
        <Route path="/dashboard/*" element={<Home />} />
        {/* <Route path="/bla" element={<DenemeSideBar />} /> */}
        {/* <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/cookie-policy" element={<CookiePolicy />} /> */}

      </Routes>
    </BrowserRouter>
  );
}

export default App;
