export const styles = {
    header: {
        paddingTop: 50
    },
    imgDiv: {
        textAlign: 'center'
    },
    img: {
        width: 300,
        height: 300,
        borderRadius: '100%',
        objectFit: 'cover'

    }

}