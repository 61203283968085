import React from 'react'
import ReactLoading from 'react-loading'
import { Link } from 'react-router-dom'
import './styles/collectiveProfileCard.css'

function CollectiveProfileCard({ userName, userRole, userImage, linkNick, isDisable, cardImg, loading, inDashboard, changeBackground, key }) {
    return (
        <div
            key={key}

            // class={isDisable ? "col-lg-4" : "col-xl-3 col-md-4 col-sm-6"}
            style={{ width: 350, height: 350, marginBottom: 20, marginRight: 25, }}
        >

            <div class="card" style={{ width: 350, height: 350, border: 'none', color: 'white' }} >
                {
                    loading ? (
                        <div style={{ width: 350, height: 290, backgroundColor: 'black', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <ReactLoading type={'spinningBubbles'} color={'white'} height={50} width={50} />
                        </div>
                    )
                        :
                        (
                            <img src={cardImg} style={{ height: 290 }} />
                        )
                }


                <div class="card-body  image__overlay image__overlay--blur">
                    <div class="d-flex align-start mb-3">
                        {/* <div class="flex-grow-1">
                            <span class="badge badge-soft-success">
                                Full Time</span>
                        </div> */}
                    </div>
                    <div class="text-center mb-3">
                        <img alt="" class="avatar-sm rounded-circle" src={userImage} style={{ width: 100, height: 100, objectFit: 'cover' }} />
                        <h6 class="font-size-15 mt-3 mb-1 text-white">
                            {userName}</h6>
                        <p class="mb-0 text-white">
                            {userRole}</p>
                    </div>
                    {/* <div class="d-flex mb-3 justify-content-center gap-2 text-white">
                        <div  >
                            <i class="bx bx-map align-middle text-white">
                            </i> Louisiana </div>
                        <p class="mb-0 text-center">
                            <i class="bx bx-money align-middle text-white">
                            </i> $38 / hrs</p>
                    </div> */}
                    {/* <div class="hstack gap-2 justify-content-center">
                        <span class="badge badge-soft-warning">
                            Bootstrap</span>
                        <span class="badge badge-soft-warning">
                            HTML</span>
                        <span class="badge badge-soft-warning">
                            CSS</span>

                    </div> */}
                    {
                        isDisable ? (
                            <div class="mt-2 pt-1">
                                <label for="profileCardBackground" class="btn btn-soft-success d-block" >
                                    Edit Background
                                </label>
                                <input type="file" id='profileCardBackground' onChange={changeBackground} style={{ display: 'none' }} />
                            </div>
                        ) : (
                            <div class="mt-2 pt-1">
                                <Link to={inDashboard ? "/dashboard/" + linkNick : '/' + linkNick} class="btn btn-soft-success d-block" href="/dashboard/collective-profile">
                                    View Profile
                                </Link>
                            </div>
                        )
                    }

                </div>
                {/* <div style={{ backgroundColor: 'white', width: '100%', height: 50, position: 'absolute', bottom: 0, opacity: '0.5' }}>

                </div> */}
                <div className='row px-3' style={{ backgroundColor: 'black', display: 'flex', justifyContent: 'center', alignItems: 'center', marginLeft: 0, marginRight: 0, height: 60 }}>
                    <img alt=""
                        class="avatar-sm rounded-circle p-1"
                        style={{ width: 50, height: 50, marginRight: 10, }}
                        src={userImage} />
                    <p class="w-75" style={{ fontWeight: 'bold' }}>
                        {userName}</p>
                </div>
            </div>
        </div>
    )
}

export default CollectiveProfileCard