import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Route, Routes, useNavigate } from "react-router-dom";
import PageLoadingAnimation from "../components/Loading/PageLoadingAnimation";
import MiniChatContent from "../components/MiniChat/MiniChatContent";
import SideMenu from "../components/SideMenu/SideMenu";
import SideMenuMini from "../components/SideMenuMini/SideMenuMini";
import TopBar from "../components/TopBar/TopBar";
import { GET_BY_USER_ID } from "../constants/links";
import {
  cookieIsDefined,
  getUserIdFromCookie
} from "../helpers/cookieHelper";
import AccountPage from "./contents/account/AccountPage";
import Collective from "./contents/Collective";
import Dashboard from "./contents/Dashboard";
import Notifications from "./contents/notifications/Notifications";
import CreateProject from "./contents/projects/CreateProject";
import ProjectDetail from "./contents/projects/ProjectDetail";
import Projects1 from "./contents/projects/Projects1";
import CreateTask from "./contents/task/CreateTask";
import TicketDetail from "./contents/ticket/TicketDetail";
import Tickets from "./contents/ticket/Tickets";
import CreateBlog from "./contents/upperProfile/blog/CreateBlog";
import ShowBlog from "./contents/upperProfile/blog/ShowBlog";
import CreateUpperProfile from "./contents/upperProfile/CreateUpperProfile";
import ShowUpperProfile from "./contents/upperProfile/ShowUpperProfile";

function Home() {
  const [isMenuOpen, setIsMenuOpen] = useState(true);
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);

  const userProfile = useSelector((state) => state.userProfile.userProfile);
  const [userProfileState, setUserProfile] = useState({});

  useEffect(() => {
    if (!cookieIsDefined()) {
      navigate("/");
    } else {
      if (Object.keys(userProfile).length !== 0) {
        setUserProfile(userProfile);
        setLoading(false);

      } else {

        axios
          .get(GET_BY_USER_ID, {
            headers: {
              userid: getUserIdFromCookie(),
            },
          })
          .then((res) => {
            setUserProfile(res.data);
            setLoading(false);
          })
          .catch((err) => {
            console.log(err);
          });

      }
    }
  }, []);

  return (
    <div className="upper-main">
      {isMenuOpen ? <SideMenu /> : <SideMenuMini />}
      <TopBar
        isMenuOpen={isMenuOpen}
        setIsMenuOpen={setIsMenuOpen}
        userProfile={userProfileState}
      />
      <MiniChatContent />

      <div
        className="upper-main-content"
        style={!isMenuOpen ? { marginLeft: 70 } : {}}
      >
        {loading ? (
          <PageLoadingAnimation />
        ) : (
          <Routes>
            <Route
              path="/collective"
              exact
              element={<Collective inDashboard={true} />}
            />
            <Route
              path="/"
              exact
              element={<Dashboard userProfile={userProfileState} />}
            />
            <Route
              path="/*"
              element={
                <ShowUpperProfile
                  inDashboard={true}
                />
              }
            />
            <Route
              path="/upper-profile-create"
              element={<CreateUpperProfile userProfile={userProfileState} />}
            />
            {/* <Route path="/profile-not-found" element={<ProfileNotFound />} /> */}
            <Route path="/create-blog" element={<CreateBlog userProfile={userProfileState} />} />
            <Route path="/show-blog/*" element={<ShowBlog />} />
            <Route
              path="/notifications"
              element={<Notifications userProfile={userProfileState} />}
            />
            {/* <Route path="/jobs" element={<Jobs />} /> */}
            <Route
              path="/account"
              element={<AccountPage userProfile={userProfileState} />}
            />
            <Route
              path="/projects"
              element={<Projects1 userProfile={userProfileState} />}
            />
            <Route
              path="/project-detail"
              element={<ProjectDetail userProfile={userProfileState} />}
            />

            <Route
              path="/tickets"
              element={<Tickets userProfile={userProfileState} />}
            />
            <Route path="/create-project" element={<CreateProject />} />
            <Route path="/create-task" element={<CreateTask />} />
            <Route path="/ticket-detail" element={<TicketDetail />} />
          </Routes>
        )}
      </div>
    </div>
  );
}

export default Home;
