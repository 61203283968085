import React from 'react'
import { Card, CardBody, Col } from "reactstrap"

function AmountCard({ title, text, iconClass }) {
    return (
        <Col md="4" style={{ marginBottom: 10 }}>
            <Card className="mini-stats-wid" style={{ border: 'none' }}>
                <CardBody>
                    <div className="d-flex">
                        <div className="flex-grow-1">
                            <p className="text-muted fw-medium mb-2">{title}</p>
                            <h4 className="mb-0">{text}</h4>
                        </div>
                        <div className="mini-stat-icon avatar-sm align-self-center rounded-circle bg-primary">
                            <span className="avatar-title">
                                <i className={"bx " + iconClass} style={{ fontSize: 25 }} />
                            </span>
                        </div>
                    </div>
                </CardBody>
            </Card>
        </Col>
    )
}

export default AmountCard