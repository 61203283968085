import React from 'react'
import '../styles/sideMenuDropDown.css'
import { Link } from 'react-router-dom'
function MenuIcon({ iconClass, iconName, link }) {
    return (
        <li _ngcontent-qbq-c88="" style={{ paddingTop: 10 }}>
            <Link _ngcontent-qbq-c88="" routerlinkactive="active" class="side-nav-link-ref nav-link" to={link}>
                <i _ngcontent-qbq-c88="" style={{ fontSize: '250%' }} className={iconClass}>{iconName}</i>
            </Link>
        </li>
    )
}

export default MenuIcon