import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { CREATE_PROJECT, GET_NOTIFICATIONS, NOTIFICATION_SET_ACCEPTED, NOTIFICATION_SET_READ } from '../../../constants/links'
import jwtDecode from 'jwt-decode'
import Cookies from 'js-cookie'
import { sendEmail } from '../../../helpers/sendEmail'
import { getJobRequestEmailBody } from '../../../helpers/getEmailBody'
import { ToastContainer, toast } from 'react-toastify'
import UpperModal from '../../../components/UpperModal'
import DismissModal from './components/DismissModal'
import NotificationDetail from './components/NotificationDetail'
import { getAuthRoleFromCookie, getUserIdFromCookie } from '../../../helpers/cookieHelper'

function Notifications({ userProfile }) {
    const [notifications, setNotifications] = useState([])
    const [activeNotification, setActiveNotification] = useState(null)
    const [mailMessage, setMailMessage] = useState('')

    useEffect(() => {
        console.log(jwtDecode(Cookies.get('jwt_user')))
        axios.get(GET_NOTIFICATIONS + jwtDecode(Cookies.get('jwt_user')).userId).then((res) => {
            setNotifications(res.data)
        })
            .catch(err => {
                console.log(err)
            })
    }, [])

    const setActiveNotificationFilter = index => {
        setActiveNotification(notifications[index])

        if (notifications[index].read === 'false') {
            axios.post(NOTIFICATION_SET_READ,
                { notificationId: notifications[index]._id, userId: jwtDecode(Cookies.get('jwt_user')).userId })
                .then((res) => {
                    let tempNotifications = [...notifications]
                    tempNotifications[index].read = 'true'
                    setNotifications(tempNotifications)
                })
                .catch(err => {
                    console.log(err)
                })
        }

    }

    const setNotificationAccepted = (acceptedValue) => {
        axios.post(NOTIFICATION_SET_ACCEPTED,
            { notificationId: activeNotification._id, userId: jwtDecode(Cookies.get('jwt_user')).userId, accepted: acceptedValue })
            .then(async (res) => {


                if (acceptedValue) {
                    axios.post(CREATE_PROJECT, {
                        ...activeNotification,
                        authRole: getAuthRoleFromCookie(),
                        projectOwnerId: getUserIdFromCookie(),
                        userProfile: {
                            userId: userProfile.userId,
                            fullName: userProfile.fullName,
                            profileImg: userProfile.profileImg,
                            role: userProfile.role,
                            linkNick: userProfile.linkNick,
                        }
                    })
                        .then((res) => {
                            console.log(res.data)
                            toast.success(res.data, { theme: "colored", position: "bottom-right" })

                            sendEmail(activeNotification.email, 'About Job Request',
                                getJobRequestEmailBody(activeNotification.fullName, acceptedValue, mailMessage, res.data.inviteId))

                            let index = notifications.indexOf(activeNotification)
                            let tmpActiveNotification = { ...activeNotification }
                            tmpActiveNotification.accepted = 'true'
                            setActiveNotification(tmpActiveNotification)

                            let tempNotifications = [...notifications]
                            tempNotifications[index].accepted = 'true'
                            setNotifications(tempNotifications)
                            setMailMessage('')

                        })
                        .catch((err) => {
                            console.log(err)
                            toast.error(err.data.response.message, { theme: "colored", position: "bottom-right" })

                        })
                }
                else {
                    sendEmail(activeNotification.email, 'About Job Request',
                        getJobRequestEmailBody(activeNotification.fullName, acceptedValue, mailMessage))

                    let index = notifications.indexOf(activeNotification)
                    let tmpActiveNotification = { ...activeNotification }
                    tmpActiveNotification.accepted = 'true'
                    setActiveNotification(tmpActiveNotification)

                    let tempNotifications = [...notifications]
                    tempNotifications[index].accepted = 'true'
                    setNotifications(tempNotifications)
                    setMailMessage('')
                }




            })
            .catch(err => {
                console.log(err)
            })
    }

    return (
        <div className='notifications-main'>
            <ToastContainer />
            <UpperModal modalId='notification-dismiss-modal' contentHTML={<DismissModal />} />
            {/* <a data-toggle="modal" data-target="#notification-dismiss-modal">asdas</a> */}
            <div className='notification-section'>
                {
                    notifications.length !== 0 ? notifications.map((notification, index) => {
                        return (
                            <div className='notification-item'
                                onClick={() => setActiveNotificationFilter(index)}
                                style={{ backgroundColor: activeNotification === notification ? '#2d2d2d' : '' }}
                            >
                                <h4>{notification.fullName}</h4>
                                <p>{notification.jobDesc}</p>
                                {notification.read === 'true' ? <p>Read</p> : <p>Unread</p>}
                            </div>
                        )
                    }) : (
                        <div className='notification-item'>
                            <h4>No Notifications</h4>
                        </div>
                    )
                }
            </div>
            {
                activeNotification === null ? (
                    <div className='notification-details'>
                        <h1><i class='bx bx-left-arrow-alt'></i> Click on a notification to view details</h1>
                    </div>
                ) : (
                    <NotificationDetail
                        activeNotification={activeNotification}
                        setNotificationAccepted={setNotificationAccepted}
                        mailMessage={mailMessage}
                        setMailMessage={setMailMessage}
                    />
                )
            }

        </div>
    )
}


export default Notifications