export const getVerificationCodeBody = (code) => {
    let body = '<div style="background-color: #1f1f1f; padding: 50px; text-align: center; color: white;">'
    body += '<h1 style="font-size: 60px; margin-bottom: 50px;">Freelance With Upper Social</h1>'
    body += '<h2 style="font-size: 30px; margin-bottom: 50px;">Verification Code: ' + code + '</h2>'
    body += '<h3 style="font-size: 20px; margin-bottom: 50px; color:white;">Please enter this code in the verification code section of the application form.</h3>'
    body += '</div>'
    return body
}

export const getJobRequestEmailBody = (fullName, accepted, mailMessage, inviteId) => {
    let body = '<div style="background-color: #1f1f1f; padding: 50px; text-align: center; color: white;">'
    body += '<h1 style="font-size: 60px; margin-bottom: 50px;">Freelance With Upper Social</h1>'
    accepted ? body += '<h2 style="font-size: 30px; margin-bottom: 50px;">Your job request is accepted by: ' + fullName + ' </h2>'
        : body += '<h2 style="font-size: 30px; margin-bottom: 50px;">Your job request is dismissed by: ' + fullName + ' </h2>'
    body += '<h2 style="font-size: 30px; margin-bottom: 50px;">You invited in project by ' + fullName + ' </h2>'
    body += '<h3 style="font-size: 20px; margin-bottom: 50px; color:white;">Your project id: ' + inviteId + '</h3>'
    body += '<a href="https://upperboard.com.tr/apply"><h3 style="font-size: 20px; margin-bottom: 50px; color:white;">Click here for register the UpperBoard now!</h3></a>'
    body += '<h3 style="font-size: 20px; margin-bottom: 50px; color:white;">' + mailMessage + '</h3>'
    // body += '<h3 style="font-size: 20px; margin-bottom: 50px; color:white;">Please click the link below for the contact with ' + fullName + '</h3>'
    body += '</div>'
    return body
}

export const getProjectInviteBody = (projectInviteId, inviterFullName, role) => {
    let body = '<div style="background-color: #1f1f1f; padding: 50px; text-align: center; color: white;">'
    body += '<h1 style="font-size: 60px; margin-bottom: 50px;">Freelance With Upper Social</h1>'
    body += '<h2 style="font-size: 30px; margin-bottom: 50px;">You invited in project by ' + inviterFullName + ' as a ' + role + ' </h2>'
    body += '<h3 style="font-size: 20px; margin-bottom: 50px; color:white;">Your project id: ' + projectInviteId + '</h3>'
    if (role == 'Freelancer') {
        body += '<a href="https://upperboard.com.tr/freelancer-apply"><h3 style="font-size: 20px; margin-bottom: 50px; color:white;">Click here for register the UpperBoard now!</h3></a>'
    }
    else {
        body += '<a href="https://upperboard.com.tr/company-apply"><h3 style="font-size: 20px; margin-bottom: 50px; color:white;">Click here for register the UpperBoard now!</h3></a>'
    }
    // body += '<h3 style="font-size: 20px; margin-bottom: 50px; color:white;">Please click the link below for the contact with ' + fullName + '</h3>'
    body += '</div>'
    return body
}
