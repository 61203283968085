import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    topBarMenuData: {},
}

export const topBarMenuSlice = createSlice({
    name: 'topBarMenuData',
    initialState,
    reducers: {
        setTopBarData: (state, action) => {
            state.portfolio = action.payload
        }
    },
})

// Action creators are generated for each case reducer function
export const { setTopBarData } = topBarMenuSlice.actions

export default topBarMenuSlice.reducer