import React from 'react'

function NotificationDetail({ activeNotification, setNotificationAccepted, mailMessage, setMailMessage }) {
    return (
        <div className='notification-details'>
            <label>Job Title</label>
            <h1>From: {activeNotification.fullName}</h1>
            <p><strong>From: </strong>{activeNotification.fullName + " " + activeNotification.email}</p>
            <br />
            <div className='estimateds'>
                <div>
                    <p><strong>Estimated Budget: </strong></p>
                    <p><strong>Estimated Time: </strong></p>
                </div>
                <div>
                    <p>{activeNotification.budget}</p>
                    <p>{activeNotification.startDate} - {activeNotification.endDate}</p>
                </div>
            </div>
            <br />
            <p>Job Title</p>
            <div className='job-desc-div'>
                {activeNotification.projectTitle}
            </div><br />
            <p>Job Description</p>
            <div className='job-desc-div'>
                {activeNotification.projectDescription}
            </div><br />
            {/* <div className='notification-buttons-div'>
                <button className='notification-detail-done' >Accept</button>
                <button className='notification-detail-dismiss' >Dismiss</button>
            </div> */}

            <textarea
                className='notification-mail-message'
                placeholder='Your message...' maxLength={2000}
                onChange={(e) => setMailMessage(e.target.value)}
                value={mailMessage}
            />


            {
                {
                    'true': <p>Mail already sended!</p>,
                    'false': <p>Job already dismissed!</p>,
                    null: <div> <button className='notification-detail-done' onClick={() => setNotificationAccepted(true)}>Accept</button>
                        <button className='notification-detail-dismiss' onClick={() => setNotificationAccepted(false)}>Dismiss</button>
                    </div>
                }[activeNotification.accepted]

            }

        </div>
    )
}

export default NotificationDetail