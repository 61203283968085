import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { GET_PROJECT_BY_ID, PROJECT_SEND_MESSAGE } from '../../../constants/links'
import { useLocation } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import UpperModal from '../../../components/UpperModal'
import ProjectDetailCard from './components/ProjectDetailCard'
import TeamMembersCard from './components/TeamMembersCard'
import AttachedFiles from './components/AttachedFiles'
import Chat from '../../../components/Chat'
import AddProjectFile from './components/AddProjectFile'
import AddTeamMember from './components/AddTeamMember'
import LeaveTheProject from './components/LeaveTheProject'
import { getUserIdFromCookie } from '../../../helpers/cookieHelper'
import CreateProject from './CreateProject'
import PageLoadingAnimation from '../../../components/Loading/PageLoadingAnimation'

function ProjectDetail({ userProfile }) {
    const [projectDetail, setProjectDetail] = useState()
    const location = useLocation()
    const [messageText, setMessageText] = useState('')
    const [projectId, setProjectId] = useState(undefined)
    const [loading, setLoading] = useState()

    const { fullName } = userProfile

    useEffect(() => {
        if (location.state != undefined && location.state.projectId != undefined) {
            setProjectId(location.state.projectId)

            axios.get(GET_PROJECT_BY_ID, {
                headers: {
                    projectid: location.state.projectId
                }
            })
                .then((res) => {
                    setProjectDetail(res.data)
                    setLoading(false)
                })
                .catch((err) => {
                    console.log(err)
                })
        }

    }, [])

    const sendMessage = () => {
        axios.post(PROJECT_SEND_MESSAGE, {
            projectId: projectId,
            userId: getUserIdFromCookie(),
            fullName: fullName,
            message: messageText,
            date: Date.now().toString()
        })
            .then((res) => {
                setProjectDetail({ ...projectDetail, chat: res.data })
                setMessageText('')

            })
            .catch((err) => {
                console.log(err)
            })
    }
    if (loading || projectDetail == undefined) {

        return (
            <PageLoadingAnimation />
        )


    }
    else {
        return (
            <div className='projects-main-div'>
                <ToastContainer />
                <UpperModal modalId={'addFile'} type={'lg'} contentHTML={<AddProjectFile projectId={projectId && projectId} fullName={fullName} />} />
                <UpperModal modalId={'addTeamMember'} type={'sm'} contentHTML={<AddTeamMember inviteId={projectDetail.inviteId} fullName={fullName} />} />
                <UpperModal modalId={'leaveTheProject'} type={'md'} contentHTML={<LeaveTheProject projectId={projectId && projectId} userCount={projectDetail.users.length} />} />

                <div className='p-4'>
                    <div className='d-flex justify-content-between'>
                        <h2>Projects</h2>
                        <button data-toggle="modal" data-target="#leaveTheProject" className='common-purple-button' >Leave The Project</button>

                        {/* <CommonPurpleButton text={'Create Project'} onClick={() => navigate('/dashboard/create-project')} /> */}
                    </div>
                    {/* 
                <div className='row'>
                    {
                        projects == undefined ? (<></>) : projects.map((project) => (
                            <ProjectCard name={project.projectTitle} description={project.projectDescription} onClick={() => { setProjectId(project.projectId) }} />
                        )
                        )
                    }
                </div> */}

                </div>
                <div className='row p-4'>
                    {
                        projectDetail == undefined ? (<></>) : (
                            <ProjectDetailCard name={projectDetail.projectTitle} description={''}
                                detailsText={projectDetail.projectDescription}
                                startDate={projectDetail.startDate}
                                dueDate={projectDetail.endDate}
                            />
                        )
                    }

                    {
                        projectDetail == undefined ? (<></>) : (
                            <TeamMembersCard team={projectDetail.freelancers} companies={projectDetail.companies} />
                        )
                    }

                </div>
                <div className='row p-4'>

                    <AttachedFiles files={projectDetail.files} />
                    {/* <TasksTable /> */}
                    <Chat chat={projectDetail.chat} setMessageText={setMessageText} messageText={messageText} sendMessage={sendMessage} projectTitle={projectDetail.projectTitle} />
                </div>
            </div>
        )
    }

}

export default ProjectDetail