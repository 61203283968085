import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify'
import { getUserIdFromCookie } from '../../../../helpers/cookieHelper'
import BlogAddItemSection from './components/BlogAddItemSection'
import BlogInputItem from './components/BlogInputItem'
import BlogTopSection from './components/BlogTopSection'
import { createBlog, getBlogById, updateBlog } from './requests/requests'

function CreateBlog({ userProfile }) {
    const location = useLocation()
    const navigate = useNavigate()
    const [blogTitle, setBlogTitle] = useState('')
    const [blogDesc, setBlogDesc] = useState('')
    const [blogCardImg, setBlogCardImg] = useState('')
    const [contentInputs, setContentInputs] = useState([])
    const [isUpdate, setIsUpdate] = useState(false)
    const [directLink, setDirectLink] = useState('')

    const [blogBottomShown, setBlogBottomShown] = useState(true)

    useEffect(() => {
        window.scrollTo(0, 0)
        if (location.state !== undefined && location.state !== null && location.state.itemId !== undefined && location.state.itemId !== null) {
            getBlogById(location.state.itemId).then((res) => {
                setContentInputs(res.contents)
                setBlogTitle(res.title)
                setBlogDesc(res.description)
                setBlogCardImg(res.cardImg)
                setDirectLink(res.directLink)
            })
            setIsUpdate(true)
        }
    }, [location.state])

    useEffect(() => {
        if (directLink !== undefined) {
            if (directLink.length > 0) {
                setBlogBottomShown(false)
            }
            else {
                setBlogBottomShown(true)
            }
        }
    }, [directLink])

    const addItem = (itemName) => {
        if (contentInputs.length < 10) {
            switch (itemName) {
                case 'title':
                    setContentInputs([...contentInputs, {
                        devTag: 'input',
                        tag: 'h2',
                        type: 'text',
                        placeholder: 'Headline',
                        className: 'blog-item-input-title',
                        showClassName: 'blog-item-title',
                        align: 'left',
                        content: ''
                    }])
                    break;
                case 'text':
                    setContentInputs([...contentInputs, {
                        tag: 'p',
                        devTag: 'textarea',
                        showTag: 'p',
                        type: 'text',
                        placeholder: 'Tell your story...',
                        className: 'blog-item-input-textarea',
                        showClassName: 'blog-item-text',
                        align: 'left',
                        content: ''
                    }])
                    break;
                case 'img':
                    setContentInputs([...contentInputs, {
                        devTag: 'input',
                        tag: 'img',
                        type: 'file',
                        placeholder: 'Resim Seçiniz',
                        className: 'blog-item-input-img',
                        showClassName: 'blog-item-img',
                        align: 'left',
                        content: '',
                        scale: 1
                    }])
                    break;
                default:
                    break;
            }
        }
        else {
            toast.error('You can add up to 10 items.', { theme: "colored", position: "bottom-center" })
        }

    }

    const handleOnChange = (index, value) => {
        const newContentInputs = contentInputs
        newContentInputs[index].content = value
        setContentInputs(contentInputs => [...newContentInputs])
    }

    const setAlign = (index, align) => {
        let newContentInputs = contentInputs
        newContentInputs[index].align = align
        setContentInputs(contentInputs => [...newContentInputs])
    }

    const handleChangeImgScale = (index) => {
        let newContentInputs = contentInputs
        newContentInputs[index].scale = newContentInputs[index].scale == 3 ? 1 : newContentInputs[index].scale + 1
        setContentInputs(contentInputs => [...newContentInputs])
    }

    const handleSubmit = () => {

        let filteredContentInputs = contentInputs.filter(item => {
            if (item.content != '') {
                return item
            }
        })
        if (isUpdate) {
            updateBlog({
                title: blogTitle,
                directLink: directLink,
                contents: filteredContentInputs,
                description: blogDesc,
                cardImg: blogCardImg !== '' ? blogCardImg : '/assets/images/works/work_default.png',
                linkNick: userProfile.linkNick
            },
                {
                    headers: {
                        'Authorization': getUserIdFromCookie(),
                        'BlogId': location.state !== undefined && location.state !== null && location.state.itemId !== undefined && location.state.itemId !== null ? location.state.itemId : undefined,
                    }
                })
                .then((res) => {
                    console.log(res)
                }
                )
                .catch(err => {
                    console.log(err)
                })
        }
        else {
            createBlog({
                title: blogTitle,
                directLink: directLink,
                contents: filteredContentInputs,
                description: blogDesc,
                cardImg: blogCardImg !== '' ? blogCardImg : '/assets/images/works/work_default.png',
                linkNick: userProfile.linkNick,
                userId: userProfile.userId
            },
                {
                    headers: {
                        'Authorization': getUserIdFromCookie(),
                        'BlogId': location.state !== undefined && location.state !== null && location.state.itemId !== undefined && location.state.itemId !== null ? location.state.itemId : undefined,
                    }
                })
                .then((res) => {
                    console.log(res)
                }
                )
                .catch(err => {
                    console.log(err)
                })
        }

    }

    const handleDeleteItem = (index) => {
        let newContentInputs = contentInputs
        newContentInputs.splice(index, 1)
        setContentInputs(contentInputs => [...newContentInputs])
    }

    const handleReLocateItems = (index, type) => {
        if (type == 'up') {
            if (index == 0) {
                toast.error('You can not move up the first item.', { theme: "colored", position: "bottom-center" })
            }
            else {
                let newContentInputs = contentInputs
                let temp = newContentInputs[index]
                newContentInputs[index] = newContentInputs[index - 1]
                newContentInputs[index - 1] = temp
                setContentInputs(contentInputs => [...newContentInputs])
            }
        }
        else {
            if (index == contentInputs.length - 1) {
                toast.error('You can not move down the last item.', { theme: "colored", position: "bottom-center" })
            }
            else {
                let newContentInputs = contentInputs
                let temp = newContentInputs[index]
                newContentInputs[index] = newContentInputs[index + 1]
                newContentInputs[index + 1] = temp
                setContentInputs(contentInputs => [...newContentInputs])
            }
        }
    }

    return (
        <div className='create-blog-main'>
            <ToastContainer />
            <BlogTopSection
                blogTitle={blogTitle}
                setBlogTitle={setBlogTitle}
                blogDesc={blogDesc}
                setBlogDesc={setBlogDesc}
                setBlogCardImg={setBlogCardImg}
                blogCardImg={blogCardImg}
                directLink={directLink}
                setDirectLink={setDirectLink}
            />

            {
                contentInputs.map((input, index) => {
                    return (
                        <BlogInputItem
                            index={index}
                            input={input}
                            handleOnChange={handleOnChange}
                            handleDeleteItem={handleDeleteItem}
                            setAlign={setAlign}
                            handleChangeImgScale={handleChangeImgScale}
                            handleReLocateItems={handleReLocateItems}
                        />
                    )
                })
            }
            <BlogAddItemSection addItem={addItem} blogBottomShown={blogBottomShown} />

            <div className='blog-bottom-done-cancel'>
                <button className='create-blog-done' onClick={handleSubmit}>Done</button>
                <button className='create-blog-cancel' onClick={() => { navigate(-1) }}>Cancel</button>
            </div>
        </div>
    )
}

export default CreateBlog