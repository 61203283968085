import React from 'react'
import { readAndCompressImg } from '../../Events/imageEvents'
function BlogTopSection({ blogTitle, setBlogTitle, blogDesc, setBlogDesc, setBlogCardImg, blogCardImg, directLink, setDirectLink }) {
    return (
        <div className='create-blog-top'>
            <div className='create-blog-top-left-section'>
                <h1 className="create-blog-title">Project Title</h1>
                <input className='blog-title-input'
                    maxLength={80}
                    placeholder='Give your project a title.'
                    onChange={(e) => setBlogTitle(e.target.value)} value={blogTitle} />

                <h1 className="create-blog-title">Project Description</h1>
                <input className='blog-title-input'
                    maxLength={100}
                    placeholder='How would you categorize this project?'
                    onChange={(e) => setBlogDesc(e.target.value)} value={blogDesc} />

                <h1 className="create-blog-title">Direct Link</h1>
                <input className='blog-title-input'
                    maxLength={100}
                    placeholder='https://'
                    onChange={(e) => setDirectLink(e.target.value)} value={directLink} />

            </div>
            <div className='create-blog-top-right-section'>
                <h1 className="create-blog-title">Project Cover</h1>
                <div className="cover-img-div">
                    {blogCardImg !== '' ? (
                        <label for='file-input' style={{ cursor: 'pointer' }}>
                            <img src={blogCardImg} style={{ width: '100%', height: '465px', objectFit: 'cover', borderRadius: '35px' }} />
                        </label>
                    ) : (
                        <div className='plus-circle' style={{ cursor: 'pointer' }}>
                            <label for='file-input' style={{ cursor: 'pointer' }}><i className='bx bx-plus'></i></label>
                        </div>
                    )}
                    <input type='file'
                        id='file-input'
                        style={{ display: 'none' }}
                        maxLength={100} onChange={async (e) => {
                            const imagesBase64 = await readAndCompressImg(e.target.files[0]);
                            setBlogCardImg(imagesBase64)
                        }} />

                </div>
            </div>
        </div>
    )
}

export default BlogTopSection