import React from 'react'
import { getUserIdFromCookie } from '../helpers/cookieHelper'
function Chat({ chat, sendMessage, setMessageText, projectTitle, messageText }) {

    return (
        <div class="col-xl-6">
            <div class="card" style={{ border: 'none', marginBottom: 15 }}>
                <div class="card-body border-bottom">
                    <div class="row">
                        <div class="col-md-12 col-12">
                            <h5 class="font-size-15 mb-1">{projectTitle}</h5>
                            <p class="text-muted mb-0"><i class="mdi mdi-circle text-success align-middle me-1"></i>
                                Active now</p>
                        </div>
                        {/* <div class="col-md-8 col-3">
                            <ul class="list-inline user-chat-nav text-end mb-0">
                                <li class="list-inline-item d-none d-sm-inline-block">
                                    <div class="dropdown" >
                                        <button class="btn nav-btn dropdown-toggle" type="button" data-toggle="dropdown"
                                            aria-haspopup="true" aria-expanded="false" >
                                            <i class="bx bx-search-alt-2"></i>
                                        </button>
                                        <div class="dropdown-menu dropdown-menu-end dropdown-menu-md" >
                                            <form class="p-3">
                                                <div class="m-0">
                                                    <div class="input-group">
                                                        <input type="text" class="form-control" placeholder="Search ..."
                                                            aria-label="Recipient's username" />
                                                        <div class="input-group-append">
                                                            <button class="btn btn-primary" type="submit"><i
                                                                class="mdi mdi-magnify"></i></button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </li>
                                <li class="list-inline-item d-none d-sm-inline-block">
                                    <div class="dropdown" >
                                        <button class="btn nav-btn dropdown-toggle" type="button" data-toggle="dropdown"
                                            aria-haspopup="true" aria-expanded="false" >
                                            <i class="bx bx-cog"></i>
                                        </button>
                                        <div class="dropdown-menu dropdown-menu-end" >
                                            <a class="dropdown-item" href="javascript: void(0);">View Profile</a>
                                            <a class="dropdown-item" href="javascript: void(0);">Clear chat</a>
                                            <a class="dropdown-item" href="javascript: void(0);">Muted</a>
                                            <a class="dropdown-item" href="javascript: void(0);">Delete</a>
                                        </div>
                                    </div>
                                </li>

                                <li class="list-inline-item">
                                    <div class="dropdown" >
                                        <button class="btn nav-btn dropdown-toggle" type="button" data-toggle="dropdown"
                                            aria-haspopup="true" aria-expanded="false" >
                                            <i class="bx bx-dots-horizontal-rounded"></i>
                                        </button>
                                        <div class="dropdown-menu dropdown-menu-end" >
                                            <a class="dropdown-item" href="javascript: void(0);">Action</a>
                                            <a class="dropdown-item" href="javascript: void(0);">Another action</a>
                                            <a class="dropdown-item" href="javascript: void(0);">Something else</a>
                                        </div>
                                    </div>
                                </li>

                            </ul>
                        </div> */}
                    </div>
                </div>
                <div class="card-body pb-0">
                    <div style={{}}>
                        <div class="chat-conversation" style={{ overflowY: 'scroll', height: '260px' }} >
                            <ul class="list-unstyled" >

                                {
                                    chat != undefined ? chat.map((message) => (
                                        <li className={message.userId == getUserIdFromCookie() ? 'right' : ''} >
                                            <div class="conversation-list">
                                                {/* <div class="dropdown" >
                                                    <a class="dropdown-toggle" href="javascript: void(0);" role="button"
                                                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                                                    >
                                                        <i class="bx bx-dots-vertical-rounded"></i>
                                                    </a>
                                                    <div class="dropdown-menu" >
                                                        <a class="dropdown-item" href="javascript: void(0);">Copy</a>
                                                        <a class="dropdown-item" href="javascript: void(0);">Save</a>
                                                        <a class="dropdown-item" href="javascript: void(0);">Forward</a>
                                                        <a class="dropdown-item" href="javascript: void(0);">Delete</a>
                                                    </div>
                                                </div> */}
                                                <div class="ctext-wrap">
                                                    <div class="conversation-name">{message.fullName}</div>
                                                    <p>
                                                        {message.message}
                                                    </p>
                                                    {/* <p class="chat-time mb-0"><i
                                                        class="bx bx-time-five align-middle mr-1"></i>
                                                        {message.date}</p> */}
                                                </div>

                                            </div>

                                        </li>
                                    )) : (
                                        <p>No sended message yet.</p>
                                    )
                                }
                                {/* 
                                <li>
                                    <div class="chat-day-title">
                                        <span class="title">Today</span>
                                    </div>
                                </li>
                                <li >
                                    <div class="conversation-list">
                                        <div class="dropdown" >
                                            <a class="dropdown-toggle" href="javascript: void(0);" role="button"
                                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                                            >
                                                <i class="bx bx-dots-vertical-rounded"></i>
                                            </a>
                                            <div class="dropdown-menu" >
                                                <a class="dropdown-item" href="javascript: void(0);">Copy</a>
                                                <a class="dropdown-item" href="javascript: void(0);">Save</a>
                                                <a class="dropdown-item" href="javascript: void(0);">Forward</a>
                                                <a class="dropdown-item" href="javascript: void(0);">Delete</a>
                                            </div>
                                        </div>
                                        <div class="ctext-wrap">
                                            <div class="conversation-name">UpperBoard</div>
                                            <p>
                                                Welcome to UpperBoard!
                                            </p>
                                            <p class="chat-time mb-0"><i
                                                class="bx bx-time-five align-middle mr-1"></i>
                                                datatime</p>
                                        </div>

                                    </div>

                                </li>
                                <li >
                                    <div class="conversation-list">
                                        <div class="dropdown" >
                                            <a class="dropdown-toggle" href="javascript: void(0);" role="button"
                                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                                            >
                                                <i class="bx bx-dots-vertical-rounded"></i>
                                            </a>
                                            <div class="dropdown-menu" >
                                                <a class="dropdown-item" href="javascript: void(0);">Copy</a>
                                                <a class="dropdown-item" href="javascript: void(0);">Save</a>
                                                <a class="dropdown-item" href="javascript: void(0);">Forward</a>
                                                <a class="dropdown-item" href="javascript: void(0);">Delete</a>
                                            </div>
                                        </div>
                                        <div class="ctext-wrap">
                                            <div class="conversation-name">UpperBoard</div>
                                            <p>
                                                We're thrilled to have you on board. UpperBoard is your go-to platform
                                                for streamlining your freelance projects. Whether you're managing tasks,
                                                collaborating with team members, or showcasing your portfolio, UpperBoard has got you covered.
                                            </p>
                                            <p class="chat-time mb-0"><i
                                                class="bx bx-time-five align-middle mr-1"></i>
                                                datatime</p>
                                        </div>

                                    </div>

                                </li>

                                <li >
                                    <div class="conversation-list">
                                        <div class="dropdown" >
                                            <a class="dropdown-toggle" href="javascript: void(0);" role="button"
                                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                                            >
                                                <i class="bx bx-dots-vertical-rounded"></i>
                                            </a>
                                            <div class="dropdown-menu" >
                                                <a class="dropdown-item" href="javascript: void(0);">Copy</a>
                                                <a class="dropdown-item" href="javascript: void(0);">Save</a>
                                                <a class="dropdown-item" href="javascript: void(0);">Forward</a>
                                                <a class="dropdown-item" href="javascript: void(0);">Delete</a>
                                            </div>
                                        </div>
                                        <div class="ctext-wrap">
                                            <div class="conversation-name">UpperBoard</div>
                                            <p>
                                                Stay tuned, as our messaging feature will soon be activated for all
                                                projects. In the meantime, we encourage you to explore the platform and share your experience with
                                                your connections. Your feedback is invaluable as we continue to enhance UpperBoard for your success.
                                            </p>
                                            <p class="chat-time mb-0"><i
                                                class="bx bx-time-five align-middle mr-1"></i>
                                                datatime</p>
                                        </div>

                                    </div>

                                </li>
                                <li >
                                    <div class="conversation-list">
                                        <div class="dropdown" >
                                            <a class="dropdown-toggle" href="javascript: void(0);" role="button"
                                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                                            >
                                                <i class="bx bx-dots-vertical-rounded"></i>
                                            </a>
                                            <div class="dropdown-menu" >
                                                <a class="dropdown-item" href="javascript: void(0);">Copy</a>
                                                <a class="dropdown-item" href="javascript: void(0);">Save</a>
                                                <a class="dropdown-item" href="javascript: void(0);">Forward</a>
                                                <a class="dropdown-item" href="javascript: void(0);">Delete</a>
                                            </div>
                                        </div>
                                        <div class="ctext-wrap">
                                            <div class="conversation-name">UpperBoard</div>
                                            <p>
                                                Happy freelancing!
                                            </p>
                                            <p class="chat-time mb-0"><i
                                                class="bx bx-time-five align-middle mr-1"></i>
                                                datatime</p>
                                        </div>

                                    </div>

                                </li> */}

                            </ul>
                        </div>

                    </div>
                </div>

                <div class="p-3 chat-input-section">

                    <div class="row">
                        <div class="col">
                            <div class="position-relative">
                                <input type="text" class="form-control rounded chat-input"
                                    placeholder="Enter Message..." formControlName="message"
                                    value={messageText}
                                    onKeyDown={(e) => e.key === 'Enter' ? sendMessage() : null}
                                    onChange={(e) => setMessageText(e.target.value)}
                                />
                                <div class="invalid-feedback">
                                    <span>Message is required.</span>
                                </div>
                                {/* <div class="chat-input-links">
                                    <ul class="list-inline mb-0">
                                        <li class="list-inline-item"><a href="javascript: void(0);" data-placement="top"
                                            ngbTooltip="Emoji"><i class="mdi mdi-emoticon-happy-outline"></i></a>
                                        </li>
                                        <li class="list-inline-item"><a href="javascript: void(0);" data-placement="top"
                                            ngbTooltip="Images"><i class="mdi mdi-file-image-outline"></i></a></li>
                                        <li class="list-inline-item"><a href="javascript: void(0);" data-placement="top"
                                            ngbTooltip="Add Files"><i class="mdi mdi-file-document-outline"></i></a>
                                        </li>
                                    </ul>
                                </div> */}
                            </div>
                        </div>
                        <div class="col-auto">
                            <button class="btn btn-primary chat-send w-md" onClick={sendMessage}>
                                <span class="d-none d-sm-inline-block me-2">Send</span>
                                <i class="mdi mdi-send"></i>
                            </button>
                        </div>
                    </div>

                </div >
            </div >
        </div >
    )
}

export default Chat