import React from 'react'

function CookiePolicy() {
    return (
        <div className='privacy-main'>
            <h2 className='modal-header'>ÇEREZ (COOKIE) POLİTİKASI</h2>

            <p>
                Çerez Politikamız, Gizlilik Politikamızın bir parçasını oluşturur.
            </p>
            <p>
                Çerez (Cookie) Nedir?
            </p>
            <p>
                Günümüzde neredeyse her web sitesi çerez kullanmaktadır. Size daha iyi, hızlı ve güvenli bir deneyim sağlamak için, çoğu internet sitesi gibi biz de çerezler kullanıyoruz. Çerez, bir web sitesini ziyaret ettiğinizde cihazınıza (örneğin; bilgisayar veya cep telefonu) depolanan küçük bir metin dosyasıdır. Çerezler, bir web sitesini ilk ziyaretiniz sırasında tarayıcınız aracılığıyla cihazınıza depolanabilirler. Aynı siteyi aynı cihazla tekrar ziyaret ettiğinizde tarayıcınız cihazınızda site adına kayıtlı bir çerez olup olmadığını kontrol eder. Eğer kayıt var ise, kaydın içindeki veriyi ziyaret etmekte olduğunuz web sitesine iletir. Bu sayede web sitesi, sizin siteyi daha önce ziyaret ettiğinizi anlar ve size iletilecek içeriği de ona göre tayin eder.
            </p>
            <p>
                Çerezler Neden Kullanılır?
            </p>
            <p>
                Bazı çerezler, daha önceki ziyaretlerinizde kullandığınız tercihlerin web
                sitesi tarafından hatırlanmasını sağlayarak, sonraki ziyaretlerinizin çok daha
                kullanıcı dostu ve kişiselleştirilmiş bir deneyim sunmasını sağlar.
            </p>
            <p>
                Ayrıca, web sitesinde bulunan üçüncü taraflara ait linkler, bu üçüncü taraflara ait gizlilik politikalarına tabi olmakla birlikte, gizlilik uygulamalarına ait sorumluluk uppersocial.com.tr’a ait olmamaktadır ve bu bağlamda ilgili link kapsamındaki site ziyaret edildiğinde siteye ait gizlilik politikasının okunması önerilmektedir.
            </p>
            <p>
                Çerez Türleri
            </p>
            <p>
                Ana kullanım amacı kullanıcılara kolaylık sağlamak olan çerezler, temel olarak 4 ana grupta toplanmaktadır:
            </p>
            <ul>
                <li>
                    Oturum Çerezleri: Internet sayfaları arasında bilgi taşınması ve kullanıcı tarafından girilen bilgilerin sistemsel olarak hatırlanması gibi çeşitli özelliklerden faydalanmaya olanak sağlayan çerezlerdir ve internet sitesine ait fonksiyonların düzgün bir şekilde işleyebilmesi için gereklidir.
                </li>
                <li>
                    Performans Çerezleri: Sayfaların ziyaret edilme frekansı, olası hata iletileri, kullanıcıların ilgili sayfada harcadıkları toplam zaman ile birlikte siteyi kullanım desenleri konularında bilgi toplayan çerezlerdir ve internet sitesinin performansını arttırma amacıyla kullanılmaktadır.
                </li>
                <li>
                    Fonksiyonel Çerezler: Kullanıcıya kolaylık sağlanması amacıyla önceden seçili olan seçeneklerin hatırlatılmasını sağlayan çerezlerdir ve internet sitesi kapsamında kullanıcılara gelişmiş Internet özellikleri sağlanmasını hedeflemektedir.
                </li>
                <li>
                    Reklam Ve Üçüncü Taraf Çerezleri: Üçüncü parti tedarikçilere ait çerezlerdir ve internet sitesindeki bazı fonksiyonların kullanımına ve reklam takibinin yapılmasına olanak sağlamaktadır.
                </li>

            </ul>
            <p>
                Çerezlerin Kullanım Amaçları
            </p>
            <p>
                uppersocial.com.tr tarafından kullanılmakta olan çerezlere ait kullanım amaçları aşağıdaki gibidir:
            </p>
            <ul>
                <li>
                    Güvenlik amaçlı kullanımlar:  uppersocial.com.tr, sistemlerinin idaresi ve güvenliğinin sağlanması amacıyla, bu sitedeki fonksiyonlardan yararlanmayı sağlayan veyahut düzensiz davranışları tespit eden çerezler kullanabilmektedir.
                </li>
                <li>
                    İşlevselliğe yönelik kullanımlar: uppersocial.com.tr, sistemlerinin kullanımını kolaylaştırmak ve kullanıcı özelinde kullanım özellikleri sağlamak amacıyla, kullanıcıların bilgilerini ve geçmiş seçimlerini hatırlatan çerezler kullanabilmektedir.
                </li>
                <li>
                    Performansa yönelik kullanımlar:  uppersocial.com.tr, sistemlerinin performansının artırılması ve ölçülmesi amacıyla, gönderilen iletilerle olan etkileşimi ve kullanıcı davranışlarını değerlendiren ve analiz eden çerezler kullanabilmektedir.
                </li>
                <li>
                    Reklam amaçlı kullanımlar:  uppersocial.com.tr, kendine veya üçüncü taraflara ait sistemlerin üzerinden kullanıcıların ilgi alanları kapsamında reklam ve benzeri içeriklerin iletilmesi amacıyla, bu reklamların etkinliğini ölçen veya tıklanma durumunu analiz eden çerezler kullanabilmektedir.
                </li>

            </ul>
            <p>
                Reklam Amaçlı Çerez Kullanımımız
            </p>
            <p>
                uppersocial.com.tr, Google Adsense reklam sistemi kullanmaktadır. Bu sistem Google tarafından İçerik için AdSense reklamlarının görüntülendiği yayıncı web sitelerinde sunulan reklamlarda kullanalan DoubleClick DART çerezi içerir.
            </p>
            <p>
                Üçüncü taraf satıcı olarak Google, sitemizde reklam yayınlamak için çerezlerden yararlanır. Bu çerezlerini kullanarak kullanıcılarınıza, sitenize ve İnternet’teki diğer sitelere yaptıkları ziyaretlere dayalı reklamlar sunar. Google reklam ve içerik ağı gizlilik politikasını ziyaret ederek DART çerezinin kullanılmasını engelleyebilirsiniz.
            </p>
            <p>
                Google Web sitemizi ziyaret ettiği zamanlarda reklam hizmeti vermek için üçüncü taraf reklam şirketlerini kullanmaktadır. Söz konusu şirketler, bu sitelere ve diğer web sitelerine yaptığınız ziyaretlerden elde ettikleri (adınız, adresiniz, e-posta adresiniz veya telefon numaranız dışındaki) bilgileri ilginizi çekecek ürün ve hizmetlerin reklamını size göstermek için kullanabilir. Bu uygulama hakkında bilgi edinmek için ve söz konusu bilgilerin bu şirketler tarafından kullanılmasını engellemek üzere seçeneklerinizin neler olduğunu öğrenmek ve daha fazla bilgi için NAI Self-Regulatory principles for publishers (PDF) belgesinin A Eki’nden yararlanabilirsiniz.
            </p>
            <p>
                Çerezleri Kontrol Etme ve Silme
            </p>
            <p>
                Çerezlerin kullanımına ilişkin tercihlerinizi değiştirmek ya da çerezleri engellemek veya silmek için tarayıcınızın ayarlarını değiştirmeniz yeterlidir. Birçok tarayıcı çerezleri kontrol edebilmeniz için size çerezleri kabul etme veya reddetme, yalnızca belirli türdeki çerezleri kabul etme ya da bir web sitesi cihazınıza çerez depolamayı talep ettiğinde tarayıcı tarafından uyarılma seçeneği sunar. Aynı zamanda daha önce tarayıcınıza kaydedilmiş çerezlerin silinmesi de mümkündür. Çerezleri kontrol edilmesine veya silinmesine ilişkin işlemler kullandığınız tarayıcıya göre değişebilmektedir. Bazı popüler tarayıcıların çerezlere izin verme ya da çerezleri engelleme veya silme talimatlarına aşağıdaki linklerden ulaşılması mümkündür.
            </p>
            <p>
                Çerez kullanım seçiminin değiştirilmesine ait yöntem, tarayıcı tipine bağlı olarak değişmekte olup, ilgili hizmet sağlayıcıdan dilendiği zaman öğrenilebilmektedir.
            </p>

            Bu politikanın en son güncellendiği tarih: 13/09/2023

        </div>
    )
}

export default CookiePolicy