import React from "react";
import { toast } from "react-toastify"; // Toastify'ı unutmayalım
import "../createComponents2/Styles/Personal.scss";

function SocialLinks({ socialMedias, handleSocialMedias, handleDeleteSocialMedia, setSocialMedias }) {

  const addLinkHandle = (item) => {
    if (socialMedias.length >= 5) {
      toast.error("You can add max 5 social media links!", { theme: "colored", position: "bottom-center" });
    } else {
      setSocialMedias((socialMedias) => [...socialMedias, item]);
    }
  };

  const addingButtons = [
    { icon: 'bx bxl-behance', onClick: () => addLinkHandle({ icon: 'bx bxl-behance', placeholder: "Behance", value: "" }) },
    { icon: 'bx bxl-instagram', onClick: () => addLinkHandle({ icon: 'bx bxl-instagram', placeholder: "Instagram", value: "" }) },
    { icon: 'bx bxl-linkedin-square', onClick: () => addLinkHandle({ icon: 'bx bxl-linkedin-square', placeholder: "Linkedin", value: "" }) },
    { icon: 'bx bx-world', onClick: () => addLinkHandle({ icon: 'bx bx-world', placeholder: "Web Link", value: "" }) },
  ];

  return (
    <div className="personal-edit-container">
      <h2>Social Links</h2>
      {socialMedias.map((item, index) => (
        <div key={index} className="input-container">
          <div className="input-group">
            <div>
              <h3>{item.placeholder}</h3>
              <input
                type="text"
                value={item.value}
                onChange={(e) => handleSocialMedias(e.target.value, index)}
              />
            </div>
            <div onClick={() => handleDeleteSocialMedia(index)} className="delete-social-btn">
              <i className="bx bxs-trash"></i>
            </div>
          </div>
        </div>
      ))}
      {/* <CreateUpperProfileButton buttonText="Add Social Link" /> */}

      {/* Add Social Media Buttons */}
      <div className="create-profile-skill-add-div justify-content-evenly my-4">
        {addingButtons.map((item, index) => (
          <button key={index} className='create-profile-skill-add' onClick={item.onClick}>
            <i className={item.icon}></i>
          </button>
        ))}
      </div>
    </div>
  );
}

export default SocialLinks;
