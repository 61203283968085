import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Cookies from 'js-cookie';
import jwt from 'jwt-decode';
import { ToastContainer, toast } from 'react-toastify';
import { LOGIN } from '../../constants/links';
import ReactLoading from 'react-loading';
import { useDispatch } from 'react-redux';
import { setUserProfile } from '../../redux/userProfile/userProfileSlice';
function Login() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [inputShow, setInputShow] = useState(false)
    const [info, setInfo] = useState({
        'email': '',
        'password': ''
    })
    const dispatch = useDispatch()

    const handleTextInputs = (value, name) => {
        let updatedValue = { [name]: value };
        setInfo(info => ({
            ...info,
            ...updatedValue
        }));
    }

    const login = () => {
        if (info.email != '' && info.password != '') {
            setLoading(true)
            axios.post(LOGIN, { ...info })
                .then(res => {
                    Cookies.set("jwt_user", res.data.token, {
                        expires: new Date(jwt(res.data.token).exp * 1000)
                    })
                    setLoading(false)
                    navigate('/dashboard')

                }).
                catch(err => {
                    toast.error(err.response.data.message, { theme: "colored", position: "bottom-right" })
                    setLoading(false)
                })
        }
        else {
            toast.error('Please fill all the fields', { theme: "colored", position: "bottom-right" })
        }
    }
    return (

        <div className="login-main" tabIndex={0} onKeyDown={(e) => e.key === 'Enter' ? login() : null} >
            <div className='login-form'>
                <h5 >Welcome to UpperBoard</h5>
                <p >Enter your email and password to sign in</p>

                <div className="mt-4">
                    <div >
                        <div className="mb-3">
                            <label >Email</label>
                            <input
                                maxLength={40}
                                onChange={(e) => handleTextInputs(e.target.value, 'email')}
                                placeholder='Your email adress'
                                className="form-control"
                            />
                            <div className="invalid-feedback">
                                <div >Email is required</div>
                                <div >Email must be a valid email address
                                </div>
                            </div>
                        </div>

                        <div className="mb-3">
                            <label >Password</label>
                            <div className='login-form-password-div'>
                                <input
                                    type={inputShow ? "text" : "password"}
                                    maxLength={40}
                                    className="form-control"
                                    onChange={(e) => handleTextInputs(e.target.value, 'password')}
                                    placeholder='Password'
                                />
                                <button style={{ height: 38 }} onClick={() => setInputShow(!inputShow)} className="btn btn-dark" type="button" id="password-addon"><i
                                    className="mdi mdi-eye-outline"></i></button>

                            </div>
                            <div className="invalid-feedback">
                                <span >Password is required</span>
                            </div>
                        </div>
                        <div >
                            <input type="checkbox" />
                            <label style={{ paddingLeft: 10 }} >
                                Remember me
                            </label>
                        </div>
                        <ToastContainer />
                        <div className="mt-3 d-grid">
                            <button onClick={login} className="btn btn-dark btn-block d-flex justify-content-center align-items-center">{
                                loading ? (
                                    <ReactLoading type={'spinningBubbles'} color={'white'} height={20} width={20} />
                                ) : 'Log In'
                            }</button>
                        </div>

                    </div>
                    <div className="mt-5 text-center">
                        <p >Don't have an account?
                            <Link to={'/apply'} style={{ textDecoration: 'none' }}>
                                <span> Sign Up</span>
                            </Link>
                        </p>
                    </div>
                </div>
            </div >
            <img src='/assets/images/signUpBanner.png' className='login-img' />
        </div >
    )
}

export default Login