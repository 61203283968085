import React from "react";
import "../createComponents2/Styles/Personal.scss";
import CreateUpperProfileButton from "./CreateUpperProfileButton";

function Experience({ handleExperienceAdd, experiencesSection, handleOnChangeExperience, handleExperienceDelete }) {
  return (
    <div className="personal-edit-container">
      <h2>Experience</h2>
      {experiencesSection.map((item, index) => (
        <div key={index} className="input-container">
          <div className="input-group">
            <div style={{ flex: 3 }}>
              <h3>Experience Title</h3>
              <input
                type="text"
                value={item.title}
                onChange={(e) => handleOnChangeExperience('title', index, e.target.value)}
              />
            </div>
            <div style={{ flex: 6 }}>
              <h3>Experience Description</h3>
              <input
                type="text"
                value={item.description}
                onChange={(e) => handleOnChangeExperience('description', index, e.target.value)}
              />
            </div>
            <div style={{ flex: 3 }}>
              <h3>Year(Optional)</h3>
              <input
                type="text"
                value={item.date}
                onChange={(e) => handleOnChangeExperience('date', index, e.target.value)}
              />
            </div>
            <div onClick={() => handleExperienceDelete(index)} className="delete-experience-btn">
              <i className="bx bxs-trash"></i>
            </div>
          </div>
        </div>
      ))}
      <CreateUpperProfileButton onClick={handleExperienceAdd} buttonText="Add Experience" />
    </div>
  );
}

export default Experience;
