import React, { useLayoutEffect, useState } from 'react'
import TransparentTopBar from '../../components/TransparentTopBar'
import { Route, Routes, useLocation } from 'react-router-dom'
import Collective from '../contents/Collective'
import Login from './Login'
import ShowUpperProfile from '../contents/upperProfile/ShowUpperProfile'
import ShowBlog from '../contents/upperProfile/blog/ShowBlog'
import { returnBackgroundColor } from '../../helpers/cssHelper'
import FreelancerApply from './apply/FreelancerApply'
import CompanyApply from './apply/CompanyApply'
import ApplyHome from './apply/ApplyHome'
import Apply from './apply/Apply'

function GuessView() {
    const [pageBackground, setPageBackground] = useState('')
    const location = useLocation()
    useLayoutEffect(() => {
        const path = location.pathname.split('/')
        const pageBackground = returnBackgroundColor(path[path.length - 2])
        setPageBackground(pageBackground)
    }, [])
    return (
        <div className='guess-view-main'>
            <TransparentTopBar />
            <Routes >
                <Route path="/" exact element={<Collective inDashboard={false} />} />
                <Route path="/*" exact element={<ShowUpperProfile />} />
                <Route path="/collective" element={<Collective inDashboard={false} />} />
                <Route path="/login" element={<Login />} />
                {/* <Route path="/apply" element={<ApplyHome />} /> */}
                <Route path="/freelancer-apply" element={<FreelancerApply />} />
                <Route path="/apply" element={<Apply />} />
                <Route path="/show-blog/*" element={<ShowBlog />} />
            </Routes>

        </div >
    )
}

export default GuessView