import axios from 'axios'
import Cookies from 'js-cookie'
import jwtDecode from 'jwt-decode'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify'
import { Card, CardBody, CardTitle, Col, Row } from 'reactstrap'
import { CHANGE_COLLECTIVE_SHOWN } from '../../../constants/links'
import { getAuthRoleFromCookie } from '../../../helpers/cookieHelper'
import AmountCard from './components/AmountCard'
import ApexRevenue from './components/AppRevenue'
import MyProjectsTable from './components/MyProjectsTable'

function AccountPage({ userProfile }) {

    const [collectiveShown, setCollectiveShown] = useState(userProfile.workState)
    const navigate = useNavigate()


    const handleCollectiveShown = (value) => {
        setCollectiveShown(value)

        axios.post(CHANGE_COLLECTIVE_SHOWN + jwtDecode(Cookies.get('jwt_user')).userId, {
            collectiveShown: value
        })
            .then(res => {
                toast.success(res.data, { theme: "colored", position: "bottom-center" })
            })
            .catch((e) => {
                toast.error(e, { theme: "colored", position: "bottom-center" })
            })
    }

    const [miniCards, setMiniCards] = useState([
        {
            title: "Completed Projects",
            iconClass: "bx-check-circle",
            text: "0",
        },
        { title: "Pending Projects", iconClass: "bx-hourglass", text: userProfile.projects != undefined ? userProfile.projects.length : '0' },
        { title: "Total Revenue", iconClass: "bx-package", text: "$0" },
    ]);

    return (
        <div className='account-main-div p-5 d-flex row'>
            <ToastContainer />
            <Col lg={3} sm={12} md={12} className='user-info-div mb-3'>
                <div className='text-center'>

                    <img src={userProfile.profileImg} className='account-img' width={120} />

                    <p className='fw-bold fs-5 '>{userProfile.fullName}</p>


                    <div className='w-100 d-flex justify-content-center'>
                        <label class="switch d-flex">
                            <input type="checkbox" id='switch-input' checked={collectiveShown} onChange={(e) => handleCollectiveShown(e.target.checked)} />
                            <span class="slider round"></span>
                        </label>
                        <label className='px-2 switch-label' for='switch-input' >Accepting new clients</label>
                    </div>



                    <p className='px-5 py-4 fw-light'>For a while we will show you on the Collective. You will then need to have a premium account to continue being featured.</p>



                    {
                        getAuthRoleFromCookie() == 'frelancer' ? (
                            <button onClick={() => navigate("/dashboard/" + userProfile.linkNick)} >
                                Your Portfolio
                            </button>
                        ) : (<></>)
                    }

                </div>

                <div className='py-5 px-4'>
                    <h4>Personal Information</h4>
                    <table className='w-100 m-2'>
                        <tr>
                            <th scope='row'>Full Name</th>
                            <td>{userProfile.fullName}</td>
                        </tr>
                        <tr>
                            <th scope='row'>Mobile</th>
                            <td>{userProfile.role}</td>
                        </tr>
                        <tr>
                            <th scope='row'>Email</th>
                            <td>{userProfile.email}</td>
                        </tr>
                        <tr>
                            <th scope='row'>Location</th>
                            <td>{userProfile.city}/{userProfile.country}</td>
                        </tr>
                    </table>
                </div>


                <div className='business-div'>
                    <h5>Business Account (Coming Soon)</h5>
                    <p>You need to add the company details to verify your account.</p>
                    <div className='d-flex justify-content-evenly w-100 my-4'>
                        <button className='business-button'>I am incorporated</button>
                        <button className='business-button'>I want to start my company
                            by Mükellef</button>
                    </div>
                    {/* <h5>Why Should You Incorporate?</h5>
                            <p>You are legally required to work with your customers to invoice your business relationship.
                                You are tax exempt up to a certain amount. But customers also want to work with professionals commercially. </p>
                            <p>If you want to get detailed information about the subject, you can get information from our partner Mükellef.</p> */}

                </div>


            </Col>

            <Col lg={9} md={12} sm={12}  >

                <Row>
                    {
                        miniCards.map((card, key) => (
                            <AmountCard
                                title={card.title}
                                text={card.text}
                                iconClass={card.iconClass}
                                key={"_card_" + key}
                            />
                        ))
                    }
                </Row>


                <Card style={{ border: 'none', marginTop: 20 }}>
                    <CardBody>
                        <CardTitle className="mb-4">Revenue</CardTitle>
                        <div id="revenue-chart">
                            <ApexRevenue />
                        </div>
                    </CardBody>
                </Card>

                <MyProjectsTable projects={userProfile.projects} />
            </Col>
        </div>
    )
}

export default AccountPage