import axios from 'axios'
import React, { useState } from 'react'
import { Container } from 'reactstrap'
import { LEAVE_PROJECT } from '../../../../constants/links'
import { getAuthRoleFromCookie, getUserIdFromCookie } from '../../../../helpers/cookieHelper'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'

function LeaveTheProject({ projectId, userCount }) {
    const [isLastUser, setIsLastUser] = useState(userCount == 1 ? true : false)
    const navigate = useNavigate()

    const leaveTheProject = () => {
        axios.post(LEAVE_PROJECT, {
            userId: getUserIdFromCookie(),
            authRole: getAuthRoleFromCookie(),
            projectId
        })
            .then((res) => {
                toast.success(res.data, { theme: "colored", position: "bottom-right" })
                setTimeout(() => {
                    navigate('/')
                }, 2000);

            })
            .catch((err) => {
                console.log(err)
            })
    }

    return (
        <div className="py-5" style={{ textAlign: 'center' }}>

            <Container className='d-flex' style={{ flexDirection: 'column', justifyContent: 'center' }}>

                {
                    isLastUser ? (
                        <>
                            <h4>Are you sure to leave the project?</h4>
                            <p>You are the last user in this project. When you leave, this project will delete!</p>
                        </>
                    ) : (
                        <h4>Are you sure to leave the project?</h4>

                    )
                }
                <div style={{ marginTop: 30 }}>
                    <button className={'common-purple-button px-5 py-2'} style={{ marginRight: 20 }} data-dismiss="modal">No</button>
                    <button className={'common-purple-button px-5 py-2'} data-dismiss='modal' onClick={leaveTheProject}>Yes</button>
                </div>
            </Container>
        </div>
    )
}

export default LeaveTheProject