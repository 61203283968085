import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { getBlogById } from './requests/requests'

function ShowBlog() {
    const [blog, setBlog] = useState([])
    const location = useLocation()
    const [loading, setLoading] = useState(true)
    const [imgWidth, setImgWidth] = useState('100%')
    const [imgHeight, setImgHeight] = useState('428px')
    useEffect(() => {
        const path = location.pathname.split('/')
        getBlogById(path[path.length - 1]).then((res) => {
            setBlog(res)
            console.log(res)
            setLoading(false)
        })
    }, [])

    if (loading) return <h1>Yükleniyorr</h1>
    return (
        <div className='show-blog-main'>
            <div className='w-100'>
                <h1 className='show-blog-title'>{blog.title}</h1>
                <p className='show-blog-desc'>{blog.description}</p>
            </div>
            {
                blog.length !== 0 ? blog.contents.map((item, index) => {
                    if (item.tag === 'img') {
                        return (
                            <div className='item-margin' style={{ textAlign: item.align, paddingBottom: '100px', width: '100%' }}>
                                <item.tag src={item.content}
                                    className={item.showClassName + String(item.scale)}
                                />
                            </div>
                        )
                    }
                    else if (item.tag === 'p') {
                        return (
                            <item.tag className={'item-margin ' + item.showClassname} style={{ textAlign: item.align }}>
                                {item.content}
                            </item.tag>
                        )
                    }
                    else {
                        return (
                            <item.tag className={'item-margin ' + item.showClassname} style={{ textAlign: item.align }}>
                                {item.content}
                            </item.tag>
                        )
                    }

                })
                    : <div></div>
            }
        </div>
    )
}

export default ShowBlog