import axios from "axios"
import { toast } from "react-toastify"
import { SEND_NOTIFICATION } from "../constants/links"

export const sendNotification = (userId, notificationInfo) => {
    axios.post(SEND_NOTIFICATION + userId, notificationInfo)
        .then((res) => {
            toast.success('Job Request Sent Successfully', { theme: "colored", position: "bottom-right" })
        })
        .catch(err => {
            toast.error("Something Went Wrong. Notification could not be sent", { theme: "colored", position: "bottom-right" })
            console.log(err)
        })
}