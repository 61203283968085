import React from 'react'

function UpperModal({ modalId, contentHTML, type, width }) {
    return (
        <div class="modal fade bd-example-modal-lg" id={modalId} tabindex="-1" role="dialog" aria-labelledby={modalId} aria-hidden="true">
            <div className={"modal-dialog modal-dialog-centered modal-" + type} role="document">
                <div class="modal-content" style={{ width: width != undefined ? width : 1000 }} >
                    {/* <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLongTitle">Modal title</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                    </div> */}
                    <div class="modal-body">
                        {contentHTML}
                    </div>
                    {/* <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                        <button type="button" class="btn btn-primary">Save changes</button>
                    </div> */}
                </div>
            </div>
        </div>
    )
}

export default UpperModal