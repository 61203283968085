import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import ReactLoading from 'react-loading';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import UpperModal from '../../../components/UpperModal';
import { IS_EMAIL_AVAILABLE } from '../../../constants/links';
import { getVerificationCodeBody } from '../../../helpers/getEmailBody';
import { registerAndLoginUser } from '../../../helpers/registerHelper';
import { sendEmail } from '../../../helpers/sendEmail';
import CookiePolicy from '../kvkkContents/CookiePolicy';
import PrivacyPolicy from '../kvkkContents/PrivacyPolicy';
import Step1 from './applySteps/Step1';
import Step2 from './applySteps/Step2';
import Step3 from './applySteps/Step3';
import Step4 from './applySteps/Step4';
import Step5 from './applySteps/Step5';
import Step6 from './applySteps/Step6';
import AuthRoleButton from './components/AuthRoleButton';

const avatars = [
    { src: '/assets/images/avatars/1.png' },
    { src: '/assets/images/avatars/2.png' },
    { src: '/assets/images/avatars/3.png' },
    { src: '/assets/images/avatars/4.png' },
];

const AUTH_ROLES = [
    { text: "Project Manager", active: false },
    { text: "Designer", active: false },
    { text: "Digital Marketer", active: false },
    { text: "Developer", active: false },
    { text: "Coach / Mentor", active: false },
    { text: "Team Leader", active: false },
    { text: "Client", active: false },
    { text: "Agency", active: false },
];

const PLANS = [
    {
        title: "Freemium",
        description: (
            <ul>
                <li>Default Portfolio Theme</li>
                <li>Payment (14% Commision)</li>
            </ul>
        ),
        price: (
            <>
                <span style={{ color: '#A9A9A9', fontWeight: 'bold' }}>Free</span>
            </>
        ),
        active: false,
    },
    {
        title: "Premium",
        description: (
            <ul>
                <li>All Portfolio Themes</li>
                <li>Project Management</li>
                <li>Task Management</li>
                <li>Chat</li>
                <li>Contracting</li>
                <li>Payment (10% Commision)</li>
                <li>All Portfolio Themes</li>
            </ul>
        ),
        price: (
            <>
                <span style={{ color: '#A9A9A9', fontWeight: 'bold', textDecoration: 'line-through' }}>$45</span>
                <span style={{ fontWeight: 'bold', marginLeft: '10px' }}>$30</span>
            </>
        ),
        active: false,
        suggested: true,
    },
];

function Apply() {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [apply, setApply] = useState({
        authRole: '',
        logo: 'https://www.shutterstock.com/image-vector/vector-flat-illustration-grayscale-avatar-600nw-2281862025.jpg',
        avatar: '/assets/images/avatars/1.png'
    });

    const [authRoles, setAuthRoles] = useState(AUTH_ROLES);
    const [plans, setPlans] = useState(PLANS);

    const authRole = useRef('')
    const plan = useRef('')

    const setAuthRole = (index) => {
        const updatedRoles = authRoles.map((role, i) => ({
            ...role,
            active: i === index,
        }));
        setAuthRoles(updatedRoles);

        const selectedRole = updatedRoles.find(role => role.active);
        setApply(prevApply => ({
            ...prevApply,
            authRole: selectedRole ? selectedRole.text : '',
        }));
        authRole.current = selectedRole.text
        console.log(selectedRole.text)
    };

    const setPlan = (index) => {
        const updatedPlans = plans.map((plan, i) => ({
            ...plan,
            active: i === index,
        }));
        setPlans(updatedPlans);

        const selectedPlan = updatedPlans.find(plan => plan.active);
        setApply(prevApply => ({
            ...prevApply,
            paymentType: selectedPlan ? selectedPlan.title.toUpperCase() : '',
        }));
        plan.current = selectedPlan.title.toUpperCase()
        console.log(plan.current)
        // console.log(selectedPlan.title.toUpperCase())

    };

    const [loading, setLoading] = useState(false);
    const [second, setSecond] = useState(59);
    const [termsOfUse, setTermsOfUse] = useState(false);
    const [minute, setMinute] = useState(2);
    const [step, setStep] = useState(1);
    const [resend, setResend] = useState(true);
    const [verificationCodeState, setVerificationCode] = useState('');
    const [verificationCodeInput, setVerificationCodeInput] = useState('');

    useEffect(() => {
        if (location.state == null) {
            navigate('/apply');
        }
    }, []);

    useEffect(() => {
        if (second < 0) {
            setSecond(59);
            setMinute(minute => minute - 1);
        }
        if (minute < 0) {
            clearInterval();
            setMinute(0);
            setSecond(0);
            setResend(false);
        }
    }, [second]);

    const handleTextInputs = (value, name) => {
        let updatedValue = { [name]: value };
        setApply(info => ({
            ...info,
            ...updatedValue
        }));
    };

    const register = () => {
        if (apply.password !== undefined && apply.password !== '') {
            if (apply.password == apply.rePassword) {
                setLoading(true);
                registerAndLoginUser(apply, navigate, dispatch);
            } else {
                toast.error('Passwords do not match!', { theme: "colored", position: "bottom-right" });
            }
        } else {
            toast.error('Please fill the about input.', { theme: "colored", position: "bottom-right" });
        }
    };

    const sendEmailVerification = () => {
        if (apply.email !== undefined && apply.email !== '') {
            if (termsOfUse) {
                axios.post(IS_EMAIL_AVAILABLE, {
                    email: apply.email
                })
                    .then((res) => {
                        let verificationCode = Math.floor((Math.random() * 10000) + 1000);
                        console.log(verificationCode)
                        setVerificationCode(verificationCode.toString());
                        setInterval(() => {
                            setSecond(second => second - 1);
                        }, 1000);
                        sendEmail(apply.email, 'UpperBoard Verification Code', getVerificationCodeBody(verificationCode));
                        if (step == 1) {
                            setStep(step + 1);
                        } else {
                            setResend(true);
                            setSecond(59);
                            setMinute(2);
                        }
                    })
                    .catch((err) => {
                        toast.error(err.response.data.message, { theme: "colored", position: "bottom-right" });
                    });
            } else {
                toast.error('Please accept the terms of use.', { theme: "colored", position: "bottom-right" });
            }
        } else {
            toast.error('Please fill in the blanks.', { theme: "colored", position: "bottom-right" });
        }
    };

    const verifyEmail = () => {
        if (verificationCodeInput !== undefined && verificationCodeInput !== '' && verificationCodeInput === verificationCodeState) {
            toast.success('Email verified successfully.', { theme: "colored", position: "bottom-right" });
            setTimeout(() => {
                setStep(step + 1);
            }, 1500);
        } else {
            toast.error('Please fill in the verification code.', { theme: "colored", position: "bottom-right" });
        }
    };

    if (loading) {
        return (
            <div
                style={{
                    width: '100%',
                    height: '100vh',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column'
                }}
            >
                <ToastContainer />
                <img src='/assets/images/upper-text-black.svg' style={{ width: '40%' }} />
                <ReactLoading type={'bars'} color={'black'} height={'20%'} width={'5%'} />
            </div>
        );
    } else {
        return (
            <div className='apply-main'>
                <ToastContainer />
                <UpperModal modalId='privacyModal' contentHTML={<PrivacyPolicy />} />
                <UpperModal modalId='cookieModal' contentHTML={<CookiePolicy />} />
                {{
                    1: <Step1 handleTextInputs={handleTextInputs} sendEmailVerification={sendEmailVerification} termsOfUse={termsOfUse} setTermsOfUse={setTermsOfUse} />,
                    2: <Step2
                        setVerificationCodeInput={setVerificationCodeInput}
                        sendEmailVerification={sendEmailVerification}
                        verifyEmail={verifyEmail}
                        second={second}
                        minute={minute}
                        resendDisabled={resend}
                    />,
                    3: <Step3 authRoles={authRoles} setStep={setStep} step={step} setAuthRole={setAuthRole} />,
                    4: <Step4 plans={plans} plan={plan.current} setStep={setStep} step={step} setPlan={setPlan} />,
                    5: <Step5 handleTextInputs={handleTextInputs} setStep={setStep} step={step} />,
                    6: <Step6 handleTextInputs={handleTextInputs} register={register} setStep={setStep} step={step} plan={plan.current} />,
                }[step]}
                <div>
                    <div className='profile-img-div'>
                        <img src='/assets/images/signUpBanner.png' className='apply-img' />
                        <div className='apply-profile-card-div'>
                            <div className='apply-profile-card'>
                                <img src={apply.avatar ? apply.avatar : '/assets/images/avatars/1.png'} style={{ height: 170 }} />
                                <div>
                                    {avatars.map((item, index) => (
                                        <img
                                            key={index}
                                            onClick={() => handleTextInputs(item.src, 'avatar')}
                                            src={item.src}
                                            style={{
                                                height: 50,
                                                marginRight: 10,
                                                borderRadius: 50,
                                                cursor: 'pointer',
                                                border: apply.avatar === item.src ? '2px solid black' : 'none'
                                            }}
                                        />
                                    ))}
                                </div>
                                {
                                    authRole.current !== '' && (<AuthRoleButton
                                        text={authRole.current}
                                    />)
                                }

                                <div style={{ textAlign: 'center' }}>
                                    <h3>{apply.fullName}</h3>
                                </div>
                                <p style={{ color: 'black' }}>{apply.email}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Apply;
