import React from 'react';

function AuthRoleButton({ onClick, text, active, index }) {
    return (
        <button
            className={active ? 'auth-role-button active' : 'auth-role-button'}
            onClick={() => onClick ? onClick(index) : {}}
        >
            <span className="material-symbols-outlined px-1">person</span>
            <span>{text}</span>
        </button>
    );
}

export default AuthRoleButton;
