// CreateUpperProfileButton.js

import React from "react";
import "../createComponents2/Styles/CreateUpperProfileButton.scss";

function CreateUpperProfileButton({ buttonText, onClick }) {
  return (
    <div className="button-container">
      <button className="custom-button" onClick={onClick} >{buttonText}</button>
    </div>
  );
}

export default CreateUpperProfileButton;
