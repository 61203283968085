import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Card, CardBody, CardTitle, Col, Row, Table } from 'reactstrap'

function TeamMembersCard({ team, companies }) {
    const navigate = useNavigate()
    return (
        <Col lg={4}>
            <Card style={{ border: 'none' }}>
                <CardBody>
                    <CardTitle className="mb-4">
                        <Row className='justify-content-between'>
                            <Col >Team Members</Col>
                            <Col ><button data-toggle="modal" data-target="#addTeamMember" className='common-purple-button' >Add Team Member</button></Col>

                        </Row>
                    </CardTitle>
                    <div className="table-responsive">
                        Freelancers
                        <Table className="table align-middle table-nowrap">
                            <tbody>
                                {team != undefined ? team.map((member, k) => (
                                    <tr key={"_member_" + k}>
                                        <td style={{ width: "50px" }} >
                                            {member.img !== "Null" ? (
                                                <img
                                                    src={member.profileImg}
                                                    className="rounded-circle avatar-xs"
                                                    alt=""
                                                />
                                            ) : (
                                                <div className="avatar-xs" >
                                                    <span className="avatar-title rounded-circle bg-primary text-white font-size-16">
                                                        {member.fullName[0]}
                                                    </span>
                                                </div>
                                            )}
                                        </td>
                                        <td>
                                            <h5 style={{ cursor: 'pointer' }} className="font-size-14 m-0" onClick={() => navigate('/dashboard/' + member.linkNick)}>
                                                {member.fullName}
                                            </h5>
                                        </td>
                                        <td>
                                            {/* <div>
                                                {member.skills.map((skill, key) => (
                                                    <Link
                                                        to="#"
                                                        className="badge bg-primary bg-soft text-primary font-size-11 me-1"
                                                        key={"_skill_" + key}
                                                    >
                                                        {skill.name}
                                                    </Link>
                                                ))}
                                            </div> */}
                                        </td>
                                    </tr>
                                )) : (<></>)
                                }
                            </tbody>
                        </Table>
                        Companies
                        <Table className="table align-middle table-nowrap">
                            <tbody>
                                {companies != undefined ? companies.map((member, k) => (
                                    <tr key={"_member_" + k}>
                                        <td style={{ width: "50px" }}>
                                            {member.img !== "Null" ? (
                                                <img
                                                    src={member.profileImg}
                                                    className="rounded-circle avatar-xs"
                                                    alt=""
                                                />
                                            ) : (
                                                <div className="avatar-xs">
                                                    <span className="avatar-title rounded-circle bg-primary text-white font-size-16">
                                                        {member.fullName[0]}
                                                    </span>
                                                </div>
                                            )}
                                        </td>
                                        <td>
                                            <h5 className="font-size-14 m-0">
                                                {member.fullName}
                                            </h5>
                                        </td>
                                        <td>
                                            {/* <div>
                                                {member.skills.map((skill, key) => (
                                                    <Link
                                                        to="#"
                                                        className="badge bg-primary bg-soft text-primary font-size-11 me-1"
                                                        key={"_skill_" + key}
                                                    >
                                                        {skill.name}
                                                    </Link>
                                                ))}
                                            </div> */}
                                        </td>
                                    </tr>
                                )) : (<></>)
                                }
                            </tbody>
                        </Table>
                    </div>
                </CardBody>
            </Card>
        </Col>
    )
}

export default TeamMembersCard