import React from "react";
import ReactLoading from 'react-loading';
import "../createComponents2/Styles/Personal.scss";

function Personal({ islinkNickLoading, linkNickRef, handleTextInputs, values, isLinkNickAvailable, linkError }) {
  return (
    <div className="personal-edit-container">
      <h2>Personal</h2>
      <div className="input-container">
        <div className="input-group">
          <div style={{ flex: 6 }}>
            <h3>Name</h3>
            <input
              type="text"
              value={values.fullName}
              onChange={(e) => handleTextInputs(e.target.value, 'fullName')}
            />
          </div>

          <div style={{ flex: 6 }}>
            <h3>Your Link Nick</h3>
            <input
              type="text"
              value={values.linkNick}
              onChange={(e) => {
                isLinkNickAvailable(e.target.value);
                handleTextInputs(e.target.value, 'linkNick');
              }}
            />
            {
              islinkNickLoading ? (<ReactLoading type={'bars'} color={'black'} height={30} width={30} />) : (
                <>
                  {
                    linkNickRef.current !== values.linkNick ? (<p style={{ color: linkError.color }}>{linkError.errorMessage}</p>) : (<></>)
                  }
                </>
              )
            }


          </div>
        </div>
        <div className="input-group">
          <div style={{ flex: 6 }}>
            <h3>Role</h3>
            <input
              type="text"
              value={values.role}
              onChange={(e) => handleTextInputs(e.target.value, 'role')}
            />
          </div>
          <div style={{ flex: 6 }}>
            <h3>Slogan(Optional)</h3>
            <input type="text" placeholder="" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Personal;
