import axios from "axios"
import Cookies from "js-cookie"
import jwtDecode from "jwt-decode"
import { toast } from "react-toastify"
import { CREATE_USER, CREATE_USER_PROFILE, FREELANCER_CREATE } from "../constants/links"
import { setCompanyAccount } from "../redux/company/companyAccountSlice"
import { setPortfolio } from "../redux/freelancer/portfolioSlice"
import { setTopBarData } from "../redux/freelancer/topBarMenuSlice"
import { getUserIdFromCookie } from "./cookieHelper"

export const registerAndLoginFreelancer = (apply, navigate, dispatch) => {
    axios.post(CREATE_USER,
        {
            email: apply.email,
            password: apply.password,
            role: apply.authRole
        }
    )
        .then(res => {
            console.log(res.data)
            Cookies.set("jwt_user", res.data.token, {
                expires: new Date(jwtDecode(res.data.token).exp * 1000)
            })

            let socialMedias = []
            //kontrol et diziye ekle
            if (apply.web != '') {
                socialMedias.push(
                    { icon: 'bx bx-world', placeholder: 'Web', value: apply.web }
                )
            }

            if (apply.linkedin != '') {
                socialMedias.push(
                    { icon: 'bx bxl-linkedin-square', placeholder: 'Linkedin', value: apply.linkedin }
                )
            }

            axios.post(FREELANCER_CREATE + jwtDecode(Cookies.get('jwt_user')).userId,
                {
                    withCredentials: true,
                    fullName: apply.name + ' ' + apply.surname,
                    email: apply.email,
                    linkNick: apply.linkNick,
                    profileImg: apply.avatar,
                    role: apply.role,
                    city: apply.city,
                    country: apply.country,
                    about: apply.about,
                    showcase: '',
                    web: apply.web,
                    linkedin: apply.linkedin,
                    works: [],
                    skills: [],
                    experiences: [],
                    socialMedias: socialMedias
                }
            )
                .then(res => {
                    dispatch(setPortfolio(res.data))
                    dispatch(setTopBarData({
                        fullName: res.data.fullName,
                        profileImg: res.data.profileImg,
                        linkNick: res.data.linkNick
                    }))

                    navigate('/dashboard')
                })
                .catch(err => {
                    console.log(err)
                    toast.error(err.response.data.message[0], { theme: "colored", position: "bottom-center" })
                })





        })
        .catch(err => {
            console.log(err)
            toast.error(err.response.data.message, { theme: "colored", position: "bottom-right" })
        })
}

export const registerAndLoginUser = (apply, navigate, dispatch) => {
    axios.post(CREATE_USER,
        {
            email: apply.email,
            password: apply.password,
            role: apply.authRole, //Project manager gibi kendisi belirlicek sçecek
            paymentType: apply.paymentType
        }
    )
        .then(res => {
            Cookies.set("jwt_user", res.data.token, {
                expires: new Date(jwtDecode(res.data.token).exp * 1000)
            })

            axios.post(CREATE_USER_PROFILE,
                {
                    withCredentials: true,
                    fullName: apply.fullName,
                    email: apply.email,
                    companyName: "",
                    tags: [],
                    linkNick: "",
                    about: "",
                    city: "",
                    country: "",
                    showcase: "/assets/images/collective.jpg",
                    profileImg: apply.avatar,
                    works: [],
                    socialMedias: [],
                    workState: false,
                    skills: [],
                    experiences: []
                },
                {
                    headers: {
                        userid: getUserIdFromCookie()
                    }
                }
            )
                .then(res => {
                    dispatch(setCompanyAccount(res.data))
                    navigate('/dashboard')
                })
                .catch(err => {
                    toast.error(err.response.data.message, { theme: "colored", position: "bottom-center" })
                    console.log(err)
                })
        })
}