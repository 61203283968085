import axios from 'axios';
import { default as React, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import PageLoadingAnimation from '../../../components/Loading/PageLoadingAnimation';
import UpperModal from '../../../components/UpperModal';
import { GET_ALL_TICKETS, GET_USER_TICKETS } from '../../../constants/links';
import { getUserIdFromCookie } from '../../../helpers/cookieHelper';
import CreateTicket from './CreateTicket';
const TOP_TOOL_ITEMS = [
    {
        title: 'Create Ticket',
        path: '#createTicket'
    }
];
function Tickets({ userProfile }) {
    const [tickets, setTickets] = useState([]);
    const [loading, setLoading] = useState(true);

    const [projectMembers, setProjectsMembers] = useState([])

    const [showMyTickets, setShowMyTickets] = useState(false)

    const navigate = useNavigate()

    useEffect(() => {
        if (showMyTickets) {
            axios
                .get(GET_USER_TICKETS, { headers: { userid: getUserIdFromCookie() } })
                .then((res) => {
                    console.log(res.data)
                    const sortedTickets = res.data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
                    setTickets(sortedTickets);
                    setLoading(false);
                })
                .catch((err) => {
                    console.log(err);
                    setLoading(false);
                });
        }
        else {

            axios
                .get(GET_ALL_TICKETS)
                .then((res) => {
                    console.log(res.data)
                    const sortedTickets = res.data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
                    setTickets(sortedTickets);
                    setLoading(false);
                })
                .catch((err) => {
                    console.log(err);
                    setLoading(false);
                });
        }


    }, [showMyTickets])

    useEffect(() => {

    }, []);

    const formatDate = (dateString) => {
        const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
        return new Date(dateString).toLocaleDateString('tr-TR', options);
    };

    if (loading) {
        return <PageLoadingAnimation />;
    } else {
        return (
            <div>
                <div className="projects-header d-flex justify-content-between align-items-center mb-4 p-4">
                    <h2 className="projects-title">Tickets</h2>
                    <div className="top-buttons">
                        {TOP_TOOL_ITEMS.map((item, index) => (
                            <button
                                key={index}
                                className="custom-button"
                                data-toggle="modal"
                                data-target={item.path}
                            >
                                {item.title}
                            </button>
                        ))}
                    </div>
                    <div className='py-3 d-flex justify-content-center'>
                        <label class="switch d-flex">
                            <input type="checkbox" id='switch-input' checked={showMyTickets} onChange={(e) => setShowMyTickets(e.target.checked)} />
                            <span class="slider round"></span>
                        </label>
                        <label className='px-2 switch-label' for='switch-input' >Show My Tickets</label>
                    </div>
                </div>

                <div className="projects-main-div p-4">
                    <ToastContainer />
                    <UpperModal modalId={'createTicket'} contentHTML={<CreateTicket userProfile={userProfile} />} type={'lg'} />

                    <div className="table-responsive" style={{ minWidth: '1000px', overflowX: 'auto' }}>
                        <table className="table">
                            <thead>
                                <tr>
                                    <th scope="col">Ticket Name</th>
                                    <th scope="col">Budget</th>
                                    <th scope="col">Start Date</th>
                                    <th scope="col">Due Date</th>
                                    <th scope="col">Inviters</th>
                                </tr>
                            </thead>
                            <tbody>
                                {tickets.length > 0 ? (
                                    tickets.map((ticket, index) => (
                                        <tr key={index}>
                                            <td onClick={() => navigate('/dashboard/ticket-detail', {
                                                state: {
                                                    ticketId: ticket._id
                                                }
                                            })}>
                                                {ticket.title || 'N/A'}

                                            </td>
                                            <td>{ticket.budget || 'N/A'}</td>
                                            <td>{ticket.startDate ? formatDate(ticket.startDate) : 'N/A'}</td>
                                            <td>{ticket.endDate ? formatDate(ticket.endDate) : 'N/A'}</td>

                                            <td>{ticket.users ? ticket.users.map((user) => (
                                                <img src={user.profileImg} alt="" class="rounded-circle avatar-sm" width={70} />
                                            )) : 'N/A'}</td>
                                            <td>{ticket.ticketPublisherId == getUserIdFromCookie() ? "Sahip" : ""}</td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan="5" className="text-center">
                                            No tickets available.
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        );
    }
}

export default Tickets