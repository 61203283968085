import React, { useEffect, useState } from 'react'
import { styles } from '../../pageStyles/applyStyles'
import { toast, ToastContainer } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import { sendEmail } from '../../../helpers/sendEmail'
import { getVerificationCodeBody } from '../../../helpers/getEmailBody'
import UpperModal from '../../../components/UpperModal'
import PrivacyPolicy from '../kvkkContents/PrivacyPolicy'
import CookiePolicy from '../kvkkContents/CookiePolicy'
import axios from 'axios'
import { LINK_NICK_IS_AVAILABLE } from '../../../constants/links'
import { useDispatch } from 'react-redux'
import { registerAndLoginFreelancer } from '../../../helpers/registerHelper'
import ReactLoading from 'react-loading'

function FreelancerApply() {
    const navigate = useNavigate()
    const [apply, setApply] = useState({
        avatar: '/assets/images/avatars/1.png',
        authRole: 'freelancer'
    })
    const [linkError, setLinkError] = useState({})

    const [loading, setLoading] = useState(false)

    const dispatch = useDispatch()

    const [second, setSecond] = useState(59)
    const [termsOfUse, setTermsOfUse] = useState(false)
    const [minute, setMinute] = useState(2)
    const [step, setStep] = useState(1)
    const [resend, setResend] = useState(true)
    const [verificationCodeState, setVerificationCode] = useState('')
    const roles = [
        'Videographer',
        'Photographer',
        'UI Design',
        'UX Design',
        'Graphic Design',
        'Public relations manager',
        'Business consultant',
        'Media buyer',
        'Artificial intelligence (AI) professional',
        'Data analyst',
        'Copywriter',
        'Project manager',
        'Digital marketing consultant',
        'Editor',
        'Mobile app developer',
        'Social media manager',
        'Accountant',
        'Web designer',
        'Web developer',
        'Programmer',
        'Translator',

    ]
    const [rolesState, setRoles] = useState(roles)
    const inputs = [
        { placeholder: 'Email', tag: 'input', name: 'email', step: 1 },
        { placeholder: 'Verification Code', tag: 'input', name: 'code', step: 2 },
        { placeholder: 'Name', tag: 'input', name: 'name', step: 3 },
        { placeholder: 'Surname', tag: 'input', name: 'surname', step: 3 },
        { placeholder: 'Your Role', tag: 'input', name: 'role', step: 3 },
        { placeholder: 'City', tag: 'input', name: 'city', step: 4 },
        { placeholder: 'Country', tag: 'input', name: 'country', step: 4 },
        // { placeholder: 'Website (Optional)', tag: 'input', name: 'web', step: 5 },
        // { placeholder: 'Linkedin, Github, Behance etc. (Optional)', tag: 'input', name: 'linkedin', step: 5 },
        { placeholder: 'Tell about yourself...', tag: 'textarea', name: 'about', step: 5 },
        { placeholder: 'Your Link Nick (uppersocial.com.tr/{your-link})', tag: 'input', name: 'linkNick', step: 5 },
        { placeholder: 'Enter Password', tag: 'input', name: 'password', step: 6 },
        // { placeholder: 'Upload Your CV', tag: 'input', type: 'file', name: 'cv', step: 5 }
        // { label: 'Upload Your CV', tag: 'input', type: 'file' },
    ]

    const avatars = [
        { src: '/assets/images/avatars/1.png' },
        { src: '/assets/images/avatars/2.png' },
        { src: '/assets/images/avatars/3.png' },
        { src: '/assets/images/avatars/4.png' },
    ]

    const buttons = [
        { text: 'Send Verification Code', onClick: () => sendEmailVerification(), step: 1 },
        { text: 'Resend', onClick: () => sendEmailVerification(), step: 2 },
        { text: 'Verify', onClick: () => verifyEmail(), step: 2 },
        { text: 'Continue', onClick: () => hadleContinue(), step: 3 },
        { text: 'Continue', onClick: () => hadleContinue(), step: 4 },
        { text: 'Continue', onClick: () => hadleContinue(), step: 5 },
        { text: 'Register', onClick: () => register(), step: 6 }
    ]

    const hadleContinue = () => {
        if (step == 3) {
            if (apply.name !== undefined && apply.surname !== undefined && apply.role !== undefined &&
                apply.name !== '' && apply.surname !== '' && apply.role !== '') {
                setStep(step + 1)
            }
            else {
                toast.error('Please Fill All Inputs', { theme: "colored", position: "bottom-right" })
            }
        }
        else if (step == 4) {
            if (apply.city !== undefined && apply.country !== undefined &&
                apply.city !== '' && apply.country !== '') {
                setStep(step + 1)
            } else {
                toast.error('Please Fill All Inputs', { theme: "colored", position: "bottom-right" })
            }
        }
        else if (step == 5) {
            if (apply.about !== undefined && apply.about !== '' && apply.linkNick !== undefined && apply.linkNick !== '' && !linkError.error) {
                setStep(step + 1)
            } else {
                toast.error('Please Fill About Section', { theme: "colored", position: "bottom-right" })
            }
        }
    }

    const register = () => {
        if (apply.password !== undefined && apply.password !== '' && apply.avatar !== undefined && apply.avatar !== '') {
            // navigate('/', {
            //     state: {
            //         apply: apply
            //     }
            // })
            setLoading(true)
            registerAndLoginFreelancer(apply, navigate, dispatch)

        }
        else {
            toast.error('Please Fill About Input', { theme: "colored", position: "bottom-right" })
        }
    }

    useEffect(() => {
        if (apply.linkNick != '') {
            axios.get(LINK_NICK_IS_AVAILABLE + apply.linkNick)
                .then(res => {
                    if (res.data === true) {
                        setLinkError({
                            errorMessage: 'This link nick is available',
                            color: 'green',
                            error: false
                        })
                    } else {
                        setLinkError({
                            errorMessage: 'This link nick is not available',
                            color: 'red',
                            error: true
                        })
                    }
                })
                .catch(err => {
                    console.log(err)
                }
                )
        }

    }, [apply.linkNick])


    const handleTextInputs = (value, name) => {
        let updatedValue = { [name]: value };
        setApply(info => ({
            ...info,
            ...updatedValue
        }));
    }

    const sendEmailVerification = () => {
        if (apply.email !== undefined && apply.email !== '') {
            if (termsOfUse) {
                let verificationCode = Math.floor((Math.random() * 10000) + 1000);
                // console.log(verificationCode)
                setVerificationCode(verificationCode.toString())
                setInterval(() => {
                    setSecond(second => second - 1)
                }, 1000);
                sendEmail(apply.email, 'UpperBoard Verification Code', getVerificationCodeBody(verificationCode))
                if (step == 1) {
                    setStep(step + 1)
                }
                else {
                    setResend(true)
                    setSecond(59)
                    setMinute(2)
                }
            }
            else {
                toast.error('Please Accept Terms Of Use', { theme: "colored", position: "bottom-right" })
            }

        }
        else {
            toast.error('Please fill the blanks.', { theme: "colored", position: "bottom-right" })
        }

    }

    const verifyEmail = () => {
        if (apply.code !== undefined && apply.code !== '' && apply.code === verificationCodeState) {
            toast.success('Email Verified Successfully', { theme: "colored", position: "bottom-right" })
            setTimeout(() => {
                setStep(step + 1)
            }, 2500);
        }
        else {
            toast.error('Please Fill Verification Code', { theme: "colored", position: "bottom-right" })
        }
    }

    useEffect(() => {
        if (second < 0) {
            setSecond(59)
            setMinute(minute => minute - 1)
        }
        if (minute < 0) {
            clearInterval()
            setMinute(0)
            setSecond(0)
            setResend(false)
        }
    }, [second])

    useEffect(() => {
        if (apply.role !== undefined && apply.role !== '') {
            const array = roles.filter(item => item.includes(apply.role))
            setRoles(array)
        }
        else {
            setRoles(roles)
        }
    }, [apply.role])




    // const sendEmail = () => {

    //     let sendMessage = '<p style="font-weight:bold">Name: </p><p>' + apply.name + '</p>'
    //     sendMessage += '<p style="font-weight:bold">Surname: </p><p>' + apply.surname + '</p>'
    //     sendMessage += '<p style="font-weight:bold">Email: </p><p>' + apply.email + '</p>'
    //     sendMessage += '<p style="font-weight:bold">Phone: </p><p>' + apply.phone + '</p>'
    //     sendMessage += '<p style="font-weight:bold">City: </p><p>' + apply.city + '</p>'
    //     sendMessage += '<p style="font-weight:bold">Country: </p><p>' + apply.country + '</p>'
    //     sendMessage += '<p style="font-weight:bold">Website: </p><p>' + apply.website + '</p>'
    //     sendMessage += '<p style="font-weight:bold">Linkedin, Github, Behance etc.: </p><p>' + apply.linkedin + '</p>'
    //     sendMessage += '<p style="font-weight:bold">Tell about yourself...: </p><p>' + apply.about + '</p>'

    //     if (apply.name !== undefined && apply.surname !== undefined && apply.email !== undefined && apply.phone !== undefined && apply.city !== undefined && apply.country !== undefined && apply.about !== undefined &&
    //         apply.name !== '' && apply.surname !== '' && apply.email !== '' && apply.phone !== '' && apply.city !== '' && apply.country !== '' && apply.about !== ''
    //     ) {
    //         window.Email.send({
    //             // SecureToken: "89993999-4a90-4b74-a5c0-8c6a7e97c354",
    //             Host: "smtp.elasticemail.com",
    //             Port: 2525,
    //             Username: "uppernewmedia@gmail.com",
    //             Password: "250E06D43180D5318A77FCE2D4B0BF52D7F3",
    //             To: 'uppernewmedia@gmail.com',
    //             From: "uppernewmedia@gmail.com",
    //             Subject: "Apply For UpperBoard",
    //             Body: sendMessage,
    //             Attachments: [
    //                 {
    //                     name: 'cv.pdf',
    //                     data: apply.cv
    //                 }
    //             ]
    //         }).then(
    //             message => {
    //                 if (message === 'OK') {
    //                     window.Email.send({
    //                         // SecureToken: "89993999-4a90-4b74-a5c0-8c6a7e97c354",
    //                         Host: "smtp.elasticemail.com",
    //                         Port: 2525,
    //                         Username: "uppernewmedia@gmail.com",
    //                         Password: "250E06D43180D5318A77FCE2D4B0BF52D7F3",
    //                         To: apply.email,
    //                         From: "uppernewmedia@gmail.com",
    //                         Subject: "Your Apply Successfully Sent!!",
    //                         Body: sendMessage
    //                     })
    //                         .then(message => {
    //                             if (message === 'OK') {
    //                                 toast.success('Apply Sent Successfully', { theme: "colored", position: "bottom-right" })
    //                                 setApply({
    //                                     name: '',
    //                                     surname: '',
    //                                     email: '',
    //                                     phone: '',
    //                                     city: '',
    //                                     country: '',
    //                                     website: '',
    //                                     linkedin: '',
    //                                     about: '',

    //                                 })
    //                                 setTimeout(() => {
    //                                     navigate('/')
    //                                 }, 2500);
    //                             }
    //                             else {
    //                                 toast.error('Apply Not Sent. Please make sure you have entered your e-mail address correctly.', { theme: "colored", position: "bottom-right" })
    //                             }
    //                         })
    //                 }
    //                 else {
    //                     toast.error('Apply Not Sent', { theme: "colored", position: "bottom-right" })
    //                 }

    //             }
    //         )
    //             .catch(err => alert(err))
    //     }
    //     else {
    //         toast.error('Please Fill All Inputs', { theme: "colored", position: "bottom-right" })
    //     }

    // }
    if (loading) {
        return (<div
            style={{
                width: '100%',
                height: '100vh',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column'
            }}
        >
            <ToastContainer />
            <img src='/assets/images/upper-text-black.svg' style={{ width: '40%' }} />
            <ReactLoading type={'bars'} color={'black'} height={'20%'} width={'5%'} />
        </div>)
    } else {
        return (
            <div className='apply-main'>
                <ToastContainer />
                <div className='appyl-inputs-div'>
                    <h5 style={{ color: 'black', fontWeight: 'bold' }}>Freelance With UpperSocial</h5>
                    <h6 style={{ color: 'black' }}>Personal Informations</h6>
                    {
                        inputs.map((item, index) => {
                            if (item.step === step) {
                                if (item.name === 'role') {
                                    return (
                                        <item.tag
                                            className='col-sm-8 col-md-8 col-xl-5 apply-input'
                                            value={apply[item.name]}
                                            placeholder={item.placeholder}
                                            onChange={(e) => handleTextInputs(e.target.value, item.name)}
                                            data-toggle="dropdown" id="combobox"
                                        />)
                                }
                                else {
                                    return (
                                        <>
                                            <item.tag
                                                className='col-sm-8 col-md-8 col-xl-5 apply-input'
                                                value={apply[item.name]}
                                                placeholder={item.placeholder}
                                                onChange={(e) => handleTextInputs(e.target.value, item.name)}
                                            />
                                            {item.name == 'linkNick' ? (
                                                <p style={{ color: linkError.color }}>{linkError.errorMessage}</p>
                                            ) : (<></>)}

                                            {item.name == 'email' ? (
                                                <p >Please check your spam box for validation code!</p>
                                            ) : (<></>)}
                                        </>
                                    )
                                }

                            }

                        })
                    }
                    <div class="dropdown-menu" aria-labelledby="combobox" style={{ backgroundColor: '#1a1a1a', width: '24.5%', overflowY: 'auto', maxHeight: 200 }}>
                        {
                            rolesState.map((item, index) => {
                                return (
                                    <a onClick={() => handleTextInputs(item, 'role')} class="dropdown-item" href="#" style={{ color: 'white' }}>{item}</a>
                                )
                            })
                        }

                    </div>
                    <UpperModal modalId='privacyModal' contentHTML={<PrivacyPolicy />} />
                    <UpperModal modalId='cookieModal' contentHTML={<CookiePolicy />} />

                    {step == 1 ? (<div>

                        <input type="checkbox" checked={termsOfUse} id="vehicle2" name="vehicle2" value="Car"
                            onChange={(e) => setTermsOfUse(e.target.checked)} />
                        <label for="vehicle2"> Accept to our <a href='' data-toggle="modal" data-target="#privacyModal" >privacy policy</a> and <a href='' data-toggle="modal" data-target="#cookieModal" >cookie policy</a>
                        </label>
                    </div>) : (<></>)}

                    {step == 2 ? (<p style={{ color: 'black' }}>0{minute}:{second < 10 ? ('0') : (<></>)}{second}</p>) : (<></>)}

                    <div style={{ marginTop: 20, display: 'flex' }}>
                        {
                            buttons.map((item, index) => {
                                if (item.step === step) {
                                    return (
                                        <button onClick={item.onClick} style={styles.button} disabled={item.text === 'Resend' ? resend : false}>{item.text}</button>
                                    )
                                }
                            })
                        }
                    </div>
                </div>
                <div >

                    <div className='profile-img-div'>
                        <img src='/assets/images/signUpBanner.png' className='apply-img' />
                        <div className='apply-profile-card-div'>
                            <div className='apply-profile-card' >
                                <img src={apply.avatar ? apply.avatar : '/assets/images/avatars/1.png'} style={{ height: 170 }} />
                                <div>
                                    {
                                        avatars.map((item, index) => {
                                            return (
                                                <img onClick={() => handleTextInputs(item.src, 'avatar')}
                                                    src={item.src}
                                                    style={{ height: 50, marginRight: 10, borderRadius: 50, cursor: 'pointer', border: apply.avatar === item.src ? '2px solid black' : 'none' }}
                                                />
                                            )
                                        })
                                    }
                                </div>
                                <div style={{ textAlign: 'center' }}>
                                    <h3>{apply.name} {apply.surname}</h3>
                                    <h5>{apply.role}</h5>
                                    <div>{apply.city}, {apply.country}</div>
                                </div>
                                <p style={{ color: 'black' }}>{apply.email}</p>
                            </div>

                        </div>
                    </div>
                </div>
            </div >
        )
    }

}

export default FreelancerApply