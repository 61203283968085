import React from 'react'
import { Container } from 'reactstrap'

function PostubyPopup() {
    return (
        <div className="py-5" style={{ textAlign: 'center' }}>

            <Container className='d-flex' style={{ flexDirection: 'column', justifyContent: 'center' }}>


                <h4>UPPER20</h4>
                <p>With this special code for UpperBoard users, you can use Postuby with 20% discount.</p>

                <div style={{ marginTop: 30 }}>
                    <a href='https://postuby.com/'>
                        <button className={'common-purple-button px-5 py-2'} >Go to Postuby!</button>
                    </a>
                </div>
            </Container>
        </div>
    )
}

export default PostubyPopup