import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
// import { GET_BY_LINK_NICK, GET_USER_PROFILE } from './constants/links'
import axios from 'axios'
import ErrorPage from '../../../components/Errors/ErrorPage'
import PageLoadingAnimation from '../../../components/Loading/PageLoadingAnimation'
import { GET_BY_LINK_NICK, INC_VISIT_COUNT } from '../../../constants/links'
import useFetch from '../../../customHooks/useFetch'
import { cookieIsDefined } from '../../../helpers/cookieHelper'
import { getLinkNickFromUrl } from '../../../helpers/urlHelper'
import Contact from './components/showComponents/Contact'
import Experiences from './components/showComponents/Experiences'
import ProfileTop from './components/showComponents/ProfileTop'
import ShareButton from './components/showComponents/ShareButton'
import Skills from './components/showComponents/Skills'
import Works from './components/showComponents/Works'
function ShowUpperProfile({ inDashboard }) {
    const navigate = useNavigate()
    const location = useLocation()

    const [emailFrom, setEmailFrom] = useState('')
    const [notificationInfo, setNotificationInfo] = useState({
        email: '',
        fullName: '',
        projectDescription: '',
        estimatedBudget: '',
        startDate: '',
        endDate: '',
    })

    const { linkNick, length } = getLinkNickFromUrl(location.pathname)

    const { loading, error, value } = useFetch(GET_BY_LINK_NICK + linkNick, { method: 'GET' }, [linkNick]);


    useEffect(() => {

        window.scrollTo(0, 0)

        if (length === 2 && cookieIsDefined()) {
            navigate('/dashboard/' + linkNick)
        }
        else if (!cookieIsDefined()) {
            navigate('/' + linkNick)
        }


        axios.get(INC_VISIT_COUNT + linkNick)
            .then((res) => {
            })
            .catch((err) => {
                console.log(err)
            })


    }, [location.pathname])

    const handleNotificationInfo = (value, name) => {
        let updatedValue = { [name]: value };
        setNotificationInfo(notificationInfo => ({
            ...notificationInfo,
            ...updatedValue
        }));
    }


    if (error) {
        return <ErrorPage error={error} />
    }

    if (loading) {
        return (
            <PageLoadingAnimation />
        )
    }

    return (
        <div className='show-profile-main' style={{ paddingTop: inDashboard ? 0 : 150, width: '100%' }}>

            <main className='show-profile-main'>
                <ToastContainer />

                <ShareButton />

                <ProfileTop
                    profileImg={value.profileImg}
                    fullName={value.fullName}
                    role={value.role}
                />

                <Skills
                    about={value.about}
                    skills={value.skills}
                />

                <Works
                    works={value.works}
                    userId={value.userId}
                />

                <Experiences experiences={value.experiences} />
            </main>

            <Contact
                emailFrom={emailFrom}
                setEmailFrom={setEmailFrom}
                socialMedias={value.socialMedias}
                email={value.email}
                handleNotificationInfo={handleNotificationInfo}
                notificationInfo={notificationInfo}
                userId={value.userId}
                setNotificationInfo={setNotificationInfo}

            />

        </div >
    )


}

export default ShowUpperProfile