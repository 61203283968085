import axios from 'axios';
import Cookies from 'js-cookie';
import jwtDecode from 'jwt-decode';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Col } from 'reactstrap';
import PostubyPopup from '../../components/Dashboard/PostubyPopup';
import Ticket from '../../components/Dashboard/Ticket';
import PageLoadingAnimation from '../../components/Loading/PageLoadingAnimation';
import UpperModal from '../../components/UpperModal';
import { GET_NOTIFICATIONS, GET_USER_PROJECTS, GET_VISIT_COUNT } from '../../constants/links';
import { getAuthRoleFromCookie, getUserIdFromCookie } from '../../helpers/cookieHelper';
import { styles } from './dashboardStyles';
import JoinWithID from './projects/components/JoinWithID';
import CreateProject from './projects/CreateProject';
import CreateTicket from './ticket/CreateTicket';

const Dashboard = ({ userProfile }) => {
    const { fullName, tickets } = userProfile;
    const [notifications, setNotifications] = useState([]);
    const [unReadNotifications, setUnReadNotifications] = useState([]);
    const [workingFreelancerCount, setWorkingFreelancerCount] = useState(0);
    const [loading, setLoading] = useState(true);
    const [userProjects, setUserProjects] = useState([]);

    const [visitCount, setVisitCount] = useState(0)
    const navigate = useNavigate();

    useEffect(() => {
        if (getAuthRoleFromCookie() === 'company' && userProjects.length > 0) {
            let count = 0;
            userProjects.forEach((item) => {
                if (item.freelancers) {
                    count += item.freelancers.length;
                }
            });
            setWorkingFreelancerCount(count);
        }
    }, [userProjects]);


    useEffect(() => {
        axios.get(GET_VISIT_COUNT + userProfile.linkNick)
            .then((res) => {
                setVisitCount(res.data.visits)
            })
            .catch((err) => {
                console.log(err)
            })
    }, [])


    useEffect(() => {
        const userId = jwtDecode(Cookies.get('jwt_user')).userId;
        axios.get(GET_NOTIFICATIONS + userId)
            .then((res) => {
                setNotifications(res.data);
            })
            .catch(err => {
                console.log(err);
            });
    }, []);

    useEffect(() => {
        axios.get(GET_USER_PROJECTS, {
            headers: {
                userid: getUserIdFromCookie(),
                indashboard: true
            },
        })
            .then((res) => {
                const sortedProjects = res.data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
                setUserProjects(sortedProjects);
                setLoading(false);
            })
            .catch((err) => {
                console.log(err);
                setLoading(false);
            });
    }, []);

    const formatDate = (dateString) => {
        const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
        return new Date(dateString).toLocaleDateString('tr-TR', options);
    };

    if (loading) {
        return <PageLoadingAnimation />;
    }

    return (
        <div className="dashboard-main flex flex-col flex-wrap content-start p-5">
            <UpperModal modalId={'createTicket'} contentHTML={<CreateTicket />} type={'lg'} />
            <UpperModal modalId={'createProject'} contentHTML={<CreateProject userProfile={userProfile} />} type={'lg'} />
            <UpperModal modalId={'joinWithID'} contentHTML={<JoinWithID userProfile={userProfile} />} width={500} type={'sm'} />
            <UpperModal modalId={'postubyPopup'} contentHTML={<PostubyPopup />} width={500} type={'md'} />

            {/* Dashboard Header */}
            <div className="dashboard-header row mb-3 px-4">
                <Col lg={4} className="d-flex gap-3 align-items-center">
                    <img
                        className="rounded-circle"
                        src={getAuthRoleFromCookie() === 'company' ? userProfile.logo : userProfile.profileImg}
                        width={120}
                    />
                    <div className="ml-3">
                        <h5>Welcome to UpperBoard!</h5>
                        <h4>{fullName}</h4>
                    </div>
                </Col>
                <Col lg={4} className="d-flex justify-content-around align-items-center p-2">
                    <div className="flex-fill portfolio-view p-4 mx-1" style={styles.portfolioView}>
                        <p style={styles.pTitle}>Portfolio View</p>
                        <h2>{visitCount}</h2>
                        <a href="#" style={{ color: 'black', fontSize: '14px' }}>Upgrade your portfolio with premium templates</a>
                    </div>
                </Col>
                <Col lg={4} className="d-flex justify-content-around align-items-center p-2">
                    <div className="flex-fill upper-rate p-4 mx-1" style={styles.upperRate}>
                        <p style={styles.pTitle}>UpperRate</p>
                        <h2>1,156</h2>
                        <a href="#" style={{ color: 'black', fontSize: '14px' }}>Do you know how to higher your UpperRate?</a>
                    </div>
                </Col>
            </div>

            {/* Dashboard Content Row 1 */}
            <div className="dashboard-content row mb-3 px-4">
                <Col lg={6} className="p-2">
                    <div className="dashboard-card p-4 overflow-auto p-2 mx-1" style={{ ...styles.box, height: '200px' }}>
                        <p style={styles.pTitle}>TicketBoard</p>
                        {tickets.length === 0 ? (
                            <div className="ms-2">
                                <p style={{ marginBottom: '0px' }}>You don't have any tickets yet</p>
                                <p style={{ color: '#c0c2c3' }}>Tickets are shown when we match you with a project. If you like the project, you can apply to be involved.</p>
                            </div>
                        ) : (
                            tickets.map((ticket, index) => <Ticket key={index} ticket={ticket} />)
                        )}
                    </div>
                </Col>
                <Col lg={3} className="p-2">
                    <div className="dashboard-card p-4 overflow-auto p-2 mx-1" style={{ ...styles.box, height: '200px' }}>
                        <p style={styles.pTitle}>Completion Bar</p>
                        <div style={{ display: 'flex', alignItems: 'center', margin: '20px 0' }}>
                            <span style={{ marginRight: '10px', color: 'black' }}>25%</span>
                            <div className="progress" style={{ flexGrow: 1 }}>
                                <div className="progress-bar" style={{ width: '25%', backgroundColor: 'black' }}></div>
                            </div>
                        </div>
                        <a href="#" style={{ color: 'black' }}>Let's Complete Your Profile!</a>
                    </div>
                </Col>
                <Col lg={3} className="p-2" >
                    {/* <div className="dashboard-card p-4 overflow-auto p-2 mx-1" style={{ ...styles.box, height: '200px' }}>
                        <p style={styles.pTitle}>
                            Notifications {unReadNotifications.length > 0 && `(${unReadNotifications.length})`}
                        </p>
                        {notifications.length > 0 && (
                            <div>
                                {notifications.slice(0, 3).map((notification) => (
                                    <div key={notification._id} className="notification-item" style={{ margin: '10px 0' }}>
                                        <h4 style={{ fontSize: '14px', margin: 0, paddingBottom: 20 }}>{notification.email}</h4>
                                        <p>{notification.jobDesc}</p>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div> */}
                    <div className="dashboard-card p-4 overflow-auto p-2 mx-1" style={{ ...styles.box, height: '200px' }}>
                        <p style={styles.pTitle}>
                            Notifications {unReadNotifications.length > 0 && `(${unReadNotifications.length})`}
                        </p>
                        <table className="table" style={{ width: '100%' }}>
                            <thead className="bg-gray-50">

                                <tr>
                                    <th scope="col" className="px-6 py-3 text-left text-xs font-normal text-gray-500 uppercase tracking-wider">
                                        Title
                                    </th>
                                    <th scope="col" className="px-6 py-3 text-left text-xs font-normal text-gray-500 uppercase tracking-wider">
                                        Email
                                    </th>
                                </tr>
                            </thead>
                            <tbody className="divide-y divide-gray-200">
                                {notifications.length > 0 && notifications.slice(0, 3).map((notificationDetail, index) => (
                                    <tr key={index}>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900" >
                                            <Link className="text-decoration-none">
                                                {notificationDetail.title || 'N/A'}
                                            </Link>
                                        </td>
                                        <td className="px-6 py-4 text-sm text-gray-900">
                                            {notificationDetail.email || 'N/A'}
                                        </td>

                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </Col>
            </div>

            {/* Dashboard Content Row 2 */}
            <div className="dashboard-content row mb-3 px-4">
                <Col lg={12} className="p-2">
                    <div className="dashboard-card p-4 mx-1" style={{ ...styles.box, overflowX: 'auto' }}>
                        <div className="d-flex justify-content-between align-items-center mb-4" style={{ minWidth: '800px' }}>
                            <p style={{ ...styles.pTitle, margin: '0', paddingLeft: '10px' }}>Projects</p>
                            <div className="d-flex justify-content-end gap-3">
                                <button
                                    className="custom-button"
                                    data-toggle="modal"
                                    data-target="#joinWithID"
                                >
                                    Join With ID
                                </button>
                                <button
                                    className="custom-button"
                                    data-toggle="modal"
                                    data-target="#createProject"
                                >
                                    Create Project
                                </button>
                                <button
                                    className="custom-button"
                                    onClick={() => navigate("/dashboard/projects")}
                                >
                                    Show More
                                </button>
                            </div>
                        </div>
                        <div >
                            <div style={{ minWidth: '800px' }}> {/* Minimum genişliği belirledik */}
                                {userProjects.length === 0 ? (
                                    <p>You don't have any projects yet</p>
                                ) : (
                                    <table className="table" style={{ width: '100%' }}>
                                        <thead className="bg-gray-50">
                                            <tr>
                                                <th scope="col" className="px-6 py-3 text-left text-xs font-normal text-gray-500 uppercase tracking-wider">
                                                    Project Name
                                                </th>
                                                <th scope="col" className="px-6 py-3 text-left text-xs font-normal text-gray-500 uppercase tracking-wider">
                                                    Budget
                                                </th>
                                                <th scope="col" className="px-6 py-3 text-left text-xs font-normal text-gray-500 uppercase tracking-wider">
                                                    Start Date
                                                </th>
                                                <th scope="col" className="px-6 py-3 text-left text-xs font-normal text-gray-500 uppercase tracking-wider">
                                                    Due Date
                                                </th>
                                                <th scope="col" className="px-6 py-3 text-left text-xs font-normal text-gray-500 uppercase tracking-wider">
                                                    Members
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody className="divide-y divide-gray-200">
                                            {userProjects.map((projectDetail, index) => (
                                                <tr key={index}>
                                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900" onClick={() => navigate('/dashboard/project-detail', {
                                                        state: {
                                                            projectId: projectDetail._id != undefined ? projectDetail._id : projectDetail.projectId
                                                        }
                                                    })}>
                                                        <Link className="text-decoration-none">
                                                            {projectDetail.projectTitle || 'N/A'}
                                                        </Link>
                                                    </td>
                                                    <td className="px-6 py-4 text-sm text-gray-900">
                                                        {projectDetail.budget || 'N/A'}
                                                    </td>
                                                    <td className="px-6 py-4 text-sm text-gray-900">
                                                        {projectDetail.startDate ? formatDate(projectDetail.startDate) : 'N/A'}
                                                    </td>
                                                    <td className="px-6 py-4 text-sm text-gray-900">
                                                        {projectDetail.endDate ? formatDate(projectDetail.endDate) : 'N/A'}
                                                    </td>
                                                    <td className="px-6 py-4 text-sm text-gray-900">
                                                        {projectDetail.users ? projectDetail.users.map((user, userIndex) => (
                                                            <img key={userIndex} src={user.profileImg} alt="" className="rounded-circle avatar-sm" width={70} />
                                                        )) : 'N/A'}
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                )}
                            </div>
                        </div>
                    </div>
                </Col>

                {/* <Col lg={4} className="p-2">
                    <div className="dashboard-card p-4 overflow-auto p-2 mx-1" style={styles.box}>
                        <p style={styles.pTitle}>TaskBoard</p>
                        <TasksTable />
                    </div>
                </Col> */}
            </div>

            {/* Partnership Section */}
            <h2 className='px-5 py-4 fw-bold' style={{ fontSize: '20px' }}>Partnership</h2>
            <div className='px-5 d-flex row pb-5'>
                <a href='https://register.mukellef.co/?partner=upper-social' className='col-lg-3' style={{ marginBottom: 50 }}>
                    <img className='dashboard-partnership-img' src='/assets/images/mukellef_button.png' />
                </a>
                <a href='https://www.copetract.com/' className='col-lg-3 '>
                    <img className='dashboard-partnership-img' src='/assets/images/copetract.png' />
                </a>
                <a href=' https://linqiapp.com/' className='col-lg-3 '>
                    <img className='dashboard-partnership-img' src='/assets/images/linqi.png' />
                </a>
                <a className='col-lg-3' data-toggle="modal" data-target="#postubyPopup" style={{ cursor: 'pointer' }}>
                    <img className='dashboard-partnership-img' src='/assets/images/postuby.png' />
                </a>
            </div>
        </div>
    );
};

export default Dashboard;
