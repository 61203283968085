export const styles = {
    portfolioView: {
        backgroundColor: '#e5ecf6',
        minHeight: '20vh',
        borderRadius: 20
    },
    upperRate: {
        backgroundColor: '#e3f5ff',
        minHeight: '20vh',
        borderRadius: 20,
    },
    box: {
        backgroundColor: '#f7f9fb',
        minHeight: '20vh',
        borderRadius: 20
    },
    pTitle: {
        fontWeight: 'bold',
        marginBottom: '3px'
    }
}