import React from 'react'

function MyProjectsTable({ projects }) {
    return (
        <div class="card mt-3" style={{ border: 'none' }}>
            <div class="card-body">
                <h4 class="card-title mb-4">
                    My Projects
                </h4>
                <div class="table-responsive">
                    <table class="table table-nowrap table-hover mb-0">
                        <thead   >
                            <tr   >
                                <th scope="col">#</th>
                                <th scope="col">Project Title</th>
                                <th scope="col">Start Date</th>
                                <th scope="col">End Date</th>
                                <th scope="col">Budget</th>
                            </tr>
                        </thead>
                        <tbody   >
                            {
                                projects != undefined ? projects.map((item) => (
                                    <tr   >
                                        <th scope="row">4</th>
                                        <td   >{item.projectTitle}</td>
                                        <td   >{item.startDate}</td>
                                        <td   >{item.endDate}</td>
                                        <td   >{item.budget}</td>
                                    </tr>
                                )) : (<></>)
                            }
                            {/* <tr   >
                                <th scope="row">1</th>
                                <td   >Upper Board Admin UI</td>
                                <td   >2 Sep, 2019</td>
                                <td   >20 Oct, 2019</td>
                                <td   >$506</td>
                            </tr><tr   ><th scope="row">2</th>
                                <td   >Upper Board admin Logo</td>
                                <td   >1 Sep, 2019</td>
                                <td   >2 Sep, 2019</td>
                                <td   >$94</td></tr>
                            <tr   ><th scope="row">3</th>
                                <td>Redesign - Landing page</td>
                                <td >21 Sep, 2019</td>
                                <td   >29 Sep, 2019</td>
                                <td   >$156</td>
                            </tr><tr   >
                                <th scope="row">4</th>
                                <td   >App Landing UI</td>
                                <td   >29 Sep, 2019</td>
                                <td   >04 Oct, 2019</td>
                                <td   >$122</td>
                            </tr><tr   >
                                <th scope="row">5</th>
                                <td   >Blog Template</td>
                                <td   >05 Oct, 2019</td>
                                <td   >16 Oct, 2019</td>
                                <td   >$164</td>
                            </tr><tr   >
                                <th scope="row">6</th>
                                <td   >Redesign - Multipurpose Landing</td>
                                <td   >17 Oct, 2019</td>
                                <td   >05 Nov, 2019</td>
                                <td   >$192</td></tr>
                            <tr   ><th scope="row">7</th>
                                <td   >Logo Branding</td>
                                <td   >04 Nov, 2019</td>
                                <td   >05 Nov, 2019</td>
                                <td   >$94</td>
                            </tr> */}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default MyProjectsTable