import React from 'react'
import { styles } from './styles/skillsStyles'
function Skills({ about, skills }) {
    return (
        <section style={styles.section}>
            <div >
                <div class="row">
                    <div class="col-lg-4">
                        <div >
                            <div >
                                <h2 >
                                    <span style={styles.span}>About</span>
                                </h2>
                                <p >{about}</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-8">
                        <div >
                            <h2 className='text-center' >
                                <span >Skills</span>
                            </h2>
                            {
                                skills !== undefined &&
                                skills.map((item, index) => {
                                    if (item !== null) {
                                        return (
                                            <div style={styles.skillsDiv}>
                                                <div class="row" style={styles.skillsRow}>
                                                    <div class="col-lg-3">
                                                        <h6>{item.title && item.title}</h6>
                                                    </div>
                                                    <div class="col-lg-6">
                                                        <p>{item.description}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }

                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Skills