import React from 'react'
import { useNavigate } from 'react-router-dom'

function TransparentTopBar() {
    const navigate = useNavigate()


    return (
        <div className='transparent-top-bar'>
            <img src='/assets/images/logo-text-black.svg' className='transparent-top-bar-img' />
            <div onClick={() => navigate('login')} className='top-bar-item'>
                <span class="material-symbols-outlined">
                    account_circle
                </span>
                Login
            </div>
            <div onClick={() => navigate('apply')} className='top-bar-item'>
                <span class="material-symbols-outlined">
                    person
                </span>
                Sign Up
            </div>
            <div onClick={() => navigate('collective')} className='top-bar-item'>
                <span class="material-symbols-outlined">
                    deployed_code
                </span>
                Collective
            </div>
            <a
                href='https://www.uppersocial.com.tr/'
                class="btn btn-dark btn-block transparent-top-bar-website" >
                Website
            </a>
        </div >
    )
}

export default TransparentTopBar