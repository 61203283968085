import React, { useState } from 'react'
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
    Input,
    FormGroup,
    Label,
    Button,
} from "reactstrap"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css";
import { getAuthRoleFromCookie, getUserIdFromCookie } from '../../../helpers/cookieHelper';
import { CREATE_PROJECT } from '../../../constants/links';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
function CreateProject({ userProfile }) {
    const [project, setProject] = useState({
        authRole: getAuthRoleFromCookie(),
        projectOwnerId: getUserIdFromCookie()
    })

    const navigate = useNavigate()

    const handleTextInputs = (value, name) => {
        let updatedValue = { [name]: value };
        setProject(info => ({
            ...info,
            ...updatedValue
        }));
    }

    const createProject = () => {

        if (getAuthRoleFromCookie() == 'freelancer') {

            axios.post(CREATE_PROJECT, {
                ...project,
                userProfile: {
                    userId: userProfile.userId,
                    fullName: userProfile.fullName,
                    profileImg: userProfile.profileImg,
                    role: userProfile.role,
                    linkNick: userProfile.linkNick,
                }
            }, {
                headers: {
                    userid: getUserIdFromCookie()
                }
            })
                .then((res) => {
                    toast.success('Project Successfully Created', { theme: "colored", position: "bottom-right" })
                    setTimeout(() => {
                        navigate('/dashboard/projects', {
                            state: {
                                projectId: res.data._id
                            }
                        })

                    }, 2000);
                    setProject({})
                })
                .catch((err) => {
                    console.log(err)
                    toast.error(err.data.response.message, { theme: "colored", position: "bottom-right" })
                    setProject({})

                })
        }
        else {
            axios.post(CREATE_PROJECT, {
                ...project,
                userProfile: {
                    userId: userProfile.userId,
                    fullName: userProfile.fullName,
                    profileImg: userProfile.logo,
                }
            }, {
                headers: {
                    userid: getUserIdFromCookie()
                }
            })
                .then((res) => {
                    toast.success('Project Successfully Created', { theme: "colored", position: "bottom-right" })
                    setTimeout(() => {
                        setTimeout(() => {
                            navigate('/dashboard/projects', {
                                state: {
                                    projectId: res.data._id
                                }
                            })

                        }, 2000);

                    }, 2000);
                    setProject({})
                })
                .catch((err) => {
                    console.log(err)
                    toast.error(err.data.response.message, { theme: "colored", position: "bottom-right" })
                    setProject({})

                })
        }

    }

    return (
        <>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col lg="12">
                            <Card style={{ border: 'none' }}>
                                <CardBody>
                                    <CardTitle className="mb-4">Create New Project</CardTitle>
                                    <form className="outer-repeater">
                                        <div data-repeater-list="outer-group" className="outer">
                                            <div data-repeater-item className="outer">
                                                <FormGroup className="mb-4" row>
                                                    <Label
                                                        htmlFor="taskname"
                                                        className="col-form-label col-lg-2"
                                                    >
                                                        Project Name
                                                    </Label>
                                                    <Col lg="10">
                                                        <Input
                                                            value={project.projectTitle}
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Enter Project Name..."
                                                            onChange={(e) => handleTextInputs(e.target.value, "projectTitle")}
                                                        />
                                                    </Col>
                                                </FormGroup>
                                                <FormGroup className="mb-4" row>
                                                    <Label className="col-form-label col-lg-2">
                                                        Project Description
                                                    </Label>
                                                    <Col lg="10">
                                                        <Input
                                                            value={project.projectDescription}
                                                            type="textarea"
                                                            className="form-control"
                                                            placeholder="Enter Project Description..."
                                                            onChange={(e) => handleTextInputs(e.target.value, "projectDescription")}
                                                            style={{ height: 100 }}
                                                        />
                                                        {/* <CKEditor
                                                            editor={ClassicEditor}
                                                            data="<p>Hello from CKEditor 5!</p>"
                                                            onReady={editor => {
                                                                // You can store the "editor" and use when it is needed.
                                                                // console.log('Editor is ready to use!', editor);
                                                            }}
                                                            onChange={(event, editor) => {
                                                                const data = editor.getData();
                                                            }}
                                                        /> */}
                                                    </Col>
                                                </FormGroup>

                                                <FormGroup className="mb-4" row>
                                                    <Label className="col-form-label col-lg-2">
                                                        Project Date
                                                    </Label>
                                                    <Col lg="10">
                                                        <Row>
                                                            <Col md={6} className="pr-0">
                                                                <DatePicker
                                                                    className="form-control"
                                                                    placeholderText='Start Date'
                                                                    selected={project.startDate}
                                                                    onChange={(date) => handleTextInputs(date, "startDate")}
                                                                />
                                                            </Col>
                                                            <Col md={6} className="pl-0">
                                                                <DatePicker
                                                                    placeholderText='End Date'
                                                                    className="form-control"
                                                                    selected={project.endDate}
                                                                    onChange={(date) => handleTextInputs(date, "endDate")}
                                                                />
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </FormGroup>

                                                <FormGroup className="mb-4" row>
                                                    <label
                                                        htmlFor="taskbudget"
                                                        className="col-form-label col-lg-2"
                                                    >
                                                        Budget
                                                    </label>
                                                    <div className="col-lg-10">
                                                        <Input
                                                            value={project.budget}
                                                            type="text"
                                                            placeholder="Enter Task Budget..."
                                                            onChange={(e) => handleTextInputs(e.target.value, "budget")}
                                                            className="form-control"
                                                        />
                                                    </div>
                                                </FormGroup>
                                            </div>
                                        </div>
                                    </form>
                                    <Row className="justify-content-evenly">
                                        <Button style={{ width: 150, height: 40 }} type="submit" color="dark" data-dismiss="modal" onClick={createProject}>
                                            Create Project
                                        </Button>
                                        <Button style={{ width: 150, height: 40 }} type="submit" data-dismiss="modal" color="danger">
                                            Close
                                        </Button>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}

export default CreateProject