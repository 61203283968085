import React from 'react'
import { Container } from 'reactstrap'

function DeleteBlog({ deleteBlog, blogId }) {
    return (
        <div className="py-5" style={{ textAlign: 'center', color: 'black' }}>

            <Container className='d-flex' style={{ flexDirection: 'column', justifyContent: 'center' }}>


                <h4>Are you sure to delete this blog?</h4>
                <p>This action cannot be reversed!</p>

                <div style={{ marginTop: 30 }}>
                    <button className={'common-purple-button px-5 py-2'} style={{ marginRight: 20 }} data-dismiss="modal">No</button>
                    <button className={'common-purple-button px-5 py-2'} data-dismiss='modal' onClick={() => deleteBlog(blogId)}>Yes</button>
                </div>
            </Container>
        </div>
    )
}

export default DeleteBlog