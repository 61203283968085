import React from 'react';

function ErrorPage({ error }) {
    return (
        <div style={styles.container}>
            <h1 style={styles.message}>
                {error?.message || 'Something went wrong'}
            </h1>
            {error?.error && (
                <p style={styles.errorCode}>
                    Error Code: {error.error}
                </p>
            )}
        </div>
    );
}

const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        textAlign: 'center',
    },
    errorMessage: {
        fontSize: '2rem',
        color: '#ff4c4c',
    },
    errorCode: {
        fontSize: '1.5rem',
        color: '#ff4c4c',
    }
};

export default ErrorPage;
