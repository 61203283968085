import React from 'react'
import { Card, CardBody, Col, Row } from 'reactstrap'

function ProjectDetailCard({ name, description, detailsText, startDate, dueDate }) {
    return (
        <Col lg={8}>
            <Card style={{ border: 'none', marginBottom: 15 }}>
                <CardBody>
                    <div className="d-flex">
                        {/* <img src={'/assets/images/companies/img-1.png'} alt="" className="avatar-sm me-4" /> */}

                        <div className="flex-grow-1 overflow-hidden">
                            <h5 className="text-truncate font-size-15">{name}</h5>
                            <p className="text-muted">{description}</p>
                        </div>
                    </div>

                    <h5 className="font-size-15 mt-4">Project Details :</h5>

                    <p className="text-muted">
                        {detailsText}
                    </p>

                    {/* <div className="text-muted mt-4">
                    {project.projectDetails &&
                        map(project.projectDetails.points, (point, index) => (
                            <p key={index}>
                                <i className="mdi mdi-chevron-right text-primary me-1" />{" "}
                                {point}
                            </p>
                        ))}
                </div> */}

                    <Row className="task-dates">
                        <Col sm="4" xs="6">
                            <div className="mt-4">
                                <h5 className="font-size-14">
                                    <i className="bx bx-calendar me-1 text-primary" /> Start Date
                                </h5>
                                <p className="text-muted mb-0">{startDate}</p>
                            </div>
                        </Col>

                        <Col sm="4" xs="6">
                            <div className="mt-4">
                                <h5 className="font-size-14">
                                    <i className="bx bx-calendar-check me-1 text-primary" /> Due
                                    Date
                                </h5>
                                <p className="text-muted mb-0">{dueDate}</p>
                            </div>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        </Col>
    )
}

export default ProjectDetailCard