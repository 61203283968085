import React from 'react'
import { Link } from 'react-router-dom'
import { Card, CardBody, CardTitle, Col, Table } from 'reactstrap'

function AttachedFiles({ files }) {
    return (
        <Col lg={6}>
            <Card style={{ border: 'none', marginBottom: 15 }}>
                <CardBody>
                    <CardTitle className="mb-4">Attached Files</CardTitle>
                    <div className="table-responsive">
                        <Table className="table table-nowrap table-centered table-hover mb-0">
                            <tbody>
                                {files != undefined ? files.map((file, i) => (
                                    <tr key={"_file_" + i}>
                                        {/* <td style={{ width: "45px" }}>
                                            <div className="avatar-sm">
                                                <span className="avatar-title rounded-circle bg-soft bg-primary text-primary font-size-24">
                                                    <i className="bx bxs-file-doc" />
                                                </span>
                                            </div>
                                        </td> */}
                                        <td>
                                            <h5 className="font-size-14 mb-1">
                                                <Link to={file.fileLink} className="text-dark" style={{ textDecoration: 'none' }}>
                                                    {file.fileName}
                                                </Link>
                                            </h5>
                                            <small>Uploaded by {file.fullName}</small>
                                        </td>
                                        <td>
                                            <div className="text-center">
                                                <Link to={file.fileLink} className="text-dark">
                                                    <i className="bx bx-download h3 m-0" />
                                                </Link>
                                            </div>
                                        </td>
                                    </tr>
                                )) : (<></>)}
                            </tbody>
                        </Table>
                    </div>
                    <div className='text-center py-3'>
                        <button data-toggle="modal" data-target="#addFile" className='btn btn-primary'>Add New File</button>
                    </div>
                </CardBody>
            </Card>
        </Col>
    )
}

export default AttachedFiles