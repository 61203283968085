import axios from 'axios'
import React, { useState } from 'react'
import { ToastContainer, toast } from 'react-toastify'
import { Button, Card, CardBody, CardTitle, Col, Container, Input, Row } from 'reactstrap'
import { JOIN_PROJECT } from '../../../../constants/links'
import { getAuthRoleFromCookie, getUserIdFromCookie } from '../../../../helpers/cookieHelper';

function JoinWithID({ userProfile }) {
    const [inviteId, setInviteId] = useState()

    const join = () => {
        if (getAuthRoleFromCookie() == 'freelancer') {

            axios.post(JOIN_PROJECT, {
                userId: getUserIdFromCookie(),
                authRole: getAuthRoleFromCookie(),
                inviteId,
                userProfile: {
                    userId: userProfile.userId,
                    fullName: userProfile.fullName,
                    profileImg: userProfile.profileImg,
                    role: userProfile.role,
                    linkNick: userProfile.linkNick,
                }
            })
                .then((res) => {
                    console.log(res.data)
                    toast.success(res.data, { theme: "colored", position: "bottom-right" })

                    setInviteId('')
                })
                .catch((err) => {
                    console.log(err)
                    toast.error(err.data.response.message, { theme: "colored", position: "bottom-right" })
                    setInviteId('')

                })
        }
        else {
            axios.post(JOIN_PROJECT, {
                userId: getUserIdFromCookie(),
                authRole: getAuthRoleFromCookie(),
                inviteId,
                userProfile: {
                    userId: userProfile.userId,
                    fullName: userProfile.fullName,
                    profileImg: userProfile.logo,
                }
            })
                .then((res) => {

                    toast.success(res.data, { theme: "colored", position: "bottom-right" })

                    setInviteId('')
                })
                .catch((err) => {
                    console.log(err)
                    toast.error(err.data.response.message, { theme: "colored", position: "bottom-right" })
                    setInviteId('')


                })
        }
    }
    return (
        <div className="page-content" style={{ textAlign: 'center' }}>
            <ToastContainer />
            <Container className='d-flex' style={{ flexDirection: 'column', justifyContent: 'center' }}>

                <p>Join the project with ID</p>
                <h4>Enter Project ID</h4>
                <Input onChange={(e) => setInviteId(e.target.value)} />
                <Button type="submit" color="dark" className='mt-3' onClick={join} >
                    Join Project
                </Button>
                {/* <Col lg="12"> */}
                {/* <Card style={{ border: 'none' }}>
                    <CardBody>
                        <CardTitle className="mb-4">Create New Ticket</CardTitle> */}

                {/* <Row className="justify-content-end">
                    <Col lg="10">
                        <Button type="submit" color="dark" >
                            Create Ticket
                        </Button>
                    </Col>
                </Row> */}
                {/* </CardBody>
                </Card> */}
                {/* </Col> */}
            </Container>
        </div>
    )
}

export default JoinWithID