import { toast } from "react-toastify";
import { SEND_EMAIL } from "../constants/links"

export const sendEmail = async (toEmail, subject, message) => {
  try {
    const response = await fetch(SEND_EMAIL, { // Replace with your API endpoint
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        to: toEmail,
        subject: subject,
        message: message,
      }),
    });

    if (response.ok) {
      toast.success("Email Sent Successfully", {
        theme: "colored",
        position: "bottom-right",
      });
    } else {
      const errorData = await response.json(); // Try to get error details from the response
      toast.error(errorData.message || "Failed to send email", { 
        theme: "colored", 
        position: "bottom-right" 
      });
    }
  } catch (error) {
    console.error("Error sending email:", error);
    toast.error("An error occurred while sending the email", {
      theme: "colored",
      position: "bottom-right",
    });
  }
};
