import React from 'react'
import CollectiveProfileCard from '../../../../../components/CollectiveProfileCard'
import { readAndCompressImg } from '../../Events/imageEvents'

function ProfileCard({ profile, cardLoading, setCardLoading, handleTextInputs }) {

    return (
        <CollectiveProfileCard
            userName={profile.fullName}
            userRole={profile.role}
            userImage={profile.profileImg}
            isDisable={true}
            changeBackground={e => {
                setCardLoading(true)
                readAndCompressImg(e.target.files[0])
                    .then((compressedFile) => {
                        handleTextInputs(compressedFile, 'showcase')
                        setCardLoading(false)
                    })
            }}
            cardImg={profile.showcase}
            loading={cardLoading}
        />

    )
}

export default ProfileCard

{/* <div className='col-lg-4' style={{ justifyContent: 'center', display: 'flex', marginLeft: 30 }}>
                    <div style={{ textAlign: 'center' }}>
                        <Dropzone onDrop={acceptedFiles => {
                            setCardLoading(true)
                            readAndCompressImg(acceptedFiles[0])
                                .then((compressedFile) => {
                                    handleTextInputs(compressedFile, 'showcase')
                                    setCardLoading(false)
                                })
                        }}>
                            {({ getRootProps, getInputProps }) => (
                                <section style={{ backgroundColor: 'grey', display: 'flex', justifyContent: 'center', alignItems: 'center', height: 350, width: 350 }}>
                                    <div {...getRootProps()}>
                                        <input  {...getInputProps()} />
                                        <p style={{ padding: 10 }}>Drag and drop your card photo here, or click to select file</p>
                                    </div>

                                </section>
                            )}
                        </Dropzone>


                    </div>
                </div> */}