import axios from 'axios'
import Cookies from 'js-cookie'
import React, { useEffect } from 'react'
import ReactLoading from 'react-loading'
import { useLocation, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { ToastContainer } from 'react-toastify'
import { registerAndLoginFreelancer } from '../helpers/registerHelper'
import { useDispatch } from 'react-redux'
function Redirect() {
    const navigate = useNavigate()
    const location = useLocation()
    const dispatch = useDispatch()
    useEffect(() => {
        if (Cookies.get('jwt_user') !== undefined) {
            navigate('/dashboard')
        }
        else {
            if (location.state !== undefined && location.state !== null) {
                const { apply } = location.state
                registerAndLoginFreelancer(apply, navigate, dispatch)

            }
            else {
                navigate('/collective')
            }
        }
    }, [])

    return (
        <div
            style={{
                width: '100%',
                height: '100vh',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column'
            }}
        >
            <ToastContainer />
            <img src='/assets/images/upper-text-black.svg' style={{ width: '40%' }} />
            <ReactLoading type={'bars'} color={'black'} height={'20%'} width={'5%'} />
        </div>
    )
}

export default Redirect