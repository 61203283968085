import React from 'react';
import AuthRoleButton from '../components/AuthRoleButton';

function Step3({ authRoles, setAuthRole, setStep, step }) {
    return (
        <div className='appyl-inputs-div'>
            <h3 style={{ color: 'black', fontWeight: 'bold', marginBottom: 70 }}>
                What you want to use UpperBoard for?
            </h3>
            <div className='w-75'>
                {authRoles.map((item, index) => (
                    <AuthRoleButton
                        key={index}
                        onClick={setAuthRole}
                        text={item.text}
                        active={item.active}
                        index={index}
                    />
                ))}
            </div>
            <div className='button-row'>
                <button className='apply-buttons active' onClick={() => setStep(step - 1)}>Back</button>
                <button className='apply-buttons' onClick={() => setStep(step + 1)}>Continue</button>
            </div>
        </div>
    );
}

export default Step3;
