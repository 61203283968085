import React from 'react'
import { PREMIUM_TEXT } from '../../../../constants/payment'

function Step6({ handleTextInputs, register, step, setStep, plan }) {
    return (
        <div className='appyl-inputs-div'>
            <h5 style={{ color: 'black', fontWeight: 'bold' }}>Freelance boldly with UpperBoard</h5>
            <h6 style={{ color: 'black' }}>Everything a freelancer needs in one place</h6>
            <input className='col-sm-8 col-md-8 col-xl-5 apply-input' placeholder='Your Full Name' onChange={(e) => handleTextInputs(e.target.value, 'fullName')} />
            <input className='col-sm-8 col-md-8 col-xl-5 apply-input' placeholder='Password' type='password' onChange={(e) => handleTextInputs(e.target.value, 'password')} />
            <input className='col-sm-8 col-md-8 col-xl-5 apply-input' placeholder='Password Again' type='password' onChange={(e) => handleTextInputs(e.target.value, 'rePassword')} />

            <div className='button-row'>
                <button className='apply-buttons' onClick={() => plan == PREMIUM_TEXT ? setStep(step - 1) : setStep(step - 2)}>Back</button>
                <button className='apply-buttons' onClick={register}>Register</button>
            </div>
        </div>
    )
}

export default Step6