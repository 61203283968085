import { configureStore } from '@reduxjs/toolkit'
import portfolioSlice from './freelancer/portfolioSlice'
import topBarMenuSlice from './freelancer/topBarMenuSlice'
import companyAccountSlice from './company/companyAccountSlice'
import userProfileSlice from './userProfile/userProfileSlice'

export const store = configureStore({
    reducer: {
        portfolio: portfolioSlice,
        tobBarMenuData: topBarMenuSlice,
        companyAccount: companyAccountSlice,
        userProfile: userProfileSlice
    },
})