import React from 'react'
import { styles } from './styles/profileTopStyles'
function ProfileTop({ profileImg, fullName, role }) {
    return (
        <header style={styles.header}>
            <div >
                <div class="row justify-content-center">
                    <div class="col-lg-10">
                        <div class="text-center mb-50">
                            <h1>{fullName}</h1>
                            <p>{role}</p>
                        </div>
                    </div>
                </div>
                <div class="row justify-content-around">
                    <div class="col-lg-5">
                        <div style={styles.imgDiv}>
                            <img style={styles.img} src={profileImg} alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </header>
    )
}

export default ProfileTop