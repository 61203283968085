import React, { useState } from 'react';
import MenuItem from './MenuItem';
const SideMenu = ({ role }) => {
    const [menuItems, setMenuItems] = useState([
        // { title: "Home" },
        { iconClass: "material-symbols-outlined", iconName: "home", text: "Home", link: "/dashboard" },
        { iconClass: "material-symbols-outlined", iconName: "communities", text: "Collective", link: "/dashboard/collective" },
        { iconClass: "material-symbols-outlined", iconName: "notifications", text: "Notifications", link: "/dashboard/notifications" },
        // { title: "Coming Soon" },
        // { iconClass: "material-symbols-outlined", iconName: "home", text: "Dashboard", link: "/dashboard" },
        { iconClass: "material-symbols-outlined", iconName: "groups", text: "Teams & Projects", link: "/dashboard/projects" },
        { iconClass: "material-symbols-outlined", iconName: "task", text: "Tickets", link: "/dashboard/tickets" },
        // { iconClass: "material-symbols-outlined", iconName: "task", text: "Tasks", link: "#" },
        // { iconClass: "material-symbols-outlined", iconName: "chat", text: "Chat", link: "#" },
        // { iconClass: "material-symbols-outlined", iconName: "calendar_month", text: "Calendar", link: "#" },
        // // { title: "Menu" },
        // { iconClass: "material-symbols-outlined", iconName: "work", text: "Jobs", link: "#" },
    ])
    return (
        <div class="vertical-menu" >
            <div >
                <div class="simplebar-wrapper" style={{ margin: '0px' }}>
                    <div class="simplebar-height-auto-observer-wrapper">
                        <div class="simplebar-height-auto-observer">
                        </div>
                    </div>
                    <div class="simplebar-mask">
                        <div >
                            <div class="simplebar-content-wrapper" tabindex="0" role="region" aria-label="scrollable content" style={{ height: '100%', overflow: 'hidden' }}>
                                <div class="simplebar-content" style={{ padding: '0px' }}>
                                    <div>
                                        <div id="sidebar-menu">
                                            <ul id="side-menu" class="metismenu list-unstyled">
                                                {menuItems.map((item, index) => {
                                                    if (item.text == 'Jobs' && role == 'company') {
                                                        return (<></>)
                                                    }
                                                    else {
                                                        return (
                                                            <MenuItem
                                                                title={item.title}
                                                                iconClass={item.iconClass}
                                                                iconName={item.iconName}
                                                                text={item.text}
                                                                link={item.link} />
                                                        )
                                                    }
                                                }
                                                )}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div >

                </div >

            </div >
        </div >


    );
};

export default SideMenu;