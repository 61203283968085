import axios from 'axios'
import React, { useEffect } from 'react'
import { Container } from 'reactstrap'
import { CANCEL_TICKET_INVITE } from '../../../../constants/links'

function CancelTicketInvite({ cancelTicketInvite, freelancerId }) {


    return (
        <div className="py-5" style={{ textAlign: 'center' }}>

            <Container className='d-flex' style={{ flexDirection: 'column', justifyContent: 'center' }}>


                <h4>Are you sure to cancel the invite for this freelancer?</h4>

                <div style={{ marginTop: 30 }}>
                    <button className={'common-purple-button px-5 py-2'} style={{ marginRight: 20 }} data-dismiss="modal">No</button>
                    <button className={'common-purple-button px-5 py-2'} data-dismiss='modal' onClick={() => cancelTicketInvite()} >Yes</button>
                </div>
            </Container>
        </div>
    )
}

export default CancelTicketInvite