export const styles = {
    section: {
        paddingTop: 50,
        paddingLeft: 50,
        paddingRight: 50,
    },
    span: {
        color: 'white'
    },
    skillsDiv: {
        paddingTop: 30,
        paddingBottom: 30,
    },
    skillsRow: {
        justifyContent: 'end',
    }
}