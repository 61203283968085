import React from "react";
import "../createComponents2/Styles/Personal.scss";
import CreateUpperProfileButton from "./CreateUpperProfileButton";

function Skill({ skillSections, handleOnChangeSkills, handleSkillDelete, handleSkillAdd }) {
  return (
    <div className="personal-edit-container">
      <h2>Skills</h2>
      {skillSections.map((item, index) => (
        <div key={index} className="input-container">
          <div className="input-group">
            <div style={{ flex: 4 }}>
              <h3>Skill Title</h3>
              <input
                type="text"
                value={item.title}
                onChange={(e) => handleOnChangeSkills('title', index, e.target.value)}
              />
            </div>
            <div style={{ flex: 8 }}>
              <h3>Skill Description</h3>
              <input
                type="text"
                value={item.description}
                onChange={(e) => handleOnChangeSkills('description', index, e.target.value)}
              />
            </div>
            <div onClick={() => handleSkillDelete(index)} className="delete-skill-btn">
              <i className="bx bxs-trash"></i>
            </div>
          </div>
        </div>
      ))}
      <CreateUpperProfileButton onClick={handleSkillAdd} buttonText="Add Skill" />
    </div>
  );
}

export default Skill;
