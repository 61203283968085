import axios from 'axios'
import React, { useState } from 'react'
import { ToastContainer, toast } from 'react-toastify'
import { Button, Card, CardBody, CardTitle, Col, Container, FormGroup, Input, Label, Row } from 'reactstrap'
import { PROJECT_ADD_FILE } from '../../../../constants/links'
import { getUserIdFromCookie } from '../../../../helpers/cookieHelper'

function AddProjectFile({ fullName, projectId }) {

    const [file, setFile] = useState({})

    const handleTextInputs = (value, name) => {
        let updatedValue = { [name]: value };
        setFile(info => ({
            ...info,
            ...updatedValue
        }));
    }

    const addFile = () => {
        axios.post(PROJECT_ADD_FILE, {
            ...file,
            projectId: projectId,
            userId: getUserIdFromCookie(),
            fullName: fullName,
            date: Date.now().toString()
        })
            .then((res) => {
                setFile({
                    fileName: '',
                    fileLink: ''
                })
                toast.success('File Successfully Added', { theme: "colored", position: "bottom-right" })

            })
            .catch((err) => {
                toast.error('Something Went Wrong', { theme: "colored", position: "bottom-right" })

            })
    }

    return (
        <div className="page-content">
            <ToastContainer />
            <Container fluid>
                <Row>
                    <Col lg="12">
                        <Card style={{ border: 'none' }}>
                            <CardBody>
                                <CardTitle className="mb-4"><h3>Add New File</h3></CardTitle>
                                <form className="outer-repeater">
                                    <div data-repeater-list="outer-group" className="outer">
                                        <div data-repeater-item className="outer">
                                            <FormGroup className="mb-4" row>
                                                <Label
                                                    htmlFor="taskname"
                                                    className="col-form-label col-lg-2"
                                                >
                                                    File Name
                                                </Label>
                                                <Col lg="10">
                                                    <Input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Enter File Name..."
                                                        value={file.fileName}
                                                        onChange={(e) => handleTextInputs(e.target.value, 'fileName')}
                                                    />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup className="mb-4" row>
                                                <Label className="col-form-label col-lg-2">
                                                    File Link
                                                </Label>
                                                <Col lg="10">
                                                    <Input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Enter File Link Google Drive, WeTransfer etc..."
                                                        value={file.fileLink}

                                                        onChange={(e) => handleTextInputs(e.target.value, 'fileLink')}

                                                    />
                                                </Col>
                                            </FormGroup>
                                        </div>
                                    </div>
                                </form>
                                <Row className="justify-content-end">
                                    <Col lg="10">
                                        <Button type="submit" color="dark"
                                            onClick={addFile}
                                        >
                                            Add File
                                        </Button>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default AddProjectFile