import React from 'react'

function MiniChatMessage({ message, id }) {
    return (
        <li className={id == 'me' ? 'right' : ''}>
            <div class="conversation-list">

                <div class="ctext-wrap" style={{ maxWidth: 300 }}>
                    <div class="conversation-name">
                        {id == 'me' ? 'You' : 'Chat Bot'}
                    </div>
                    <p>
                        {message}
                    </p>
                    <p class="chat-time mb-0"><i class="bx bx-time-five align-middle mr-1"></i> datatime</p>
                </div>

            </div>

        </li>
    )
}

export default MiniChatMessage