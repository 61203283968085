import React from 'react'
import { Link } from 'react-router-dom'

function MenuItem({ iconClass, iconName, text, link, title }) {
    if (title) return (
        <li class="menu-title">{title}</li>
    )
    else {
        return (
            <li  >
                <Link class="side-nav-link-ref nav-link" to={link} style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div>
                        <i className={iconClass}>{iconName}</i>
                        <span > {text}</span>
                    </div>
                    {/* {text === 'Collective' || text === 'Home' || text === 'Notifications' || text === 'Teams & Projects' ? (<></>) : (<span style={{}} >Soon</span>)} */}

                </Link>
            </li>
        )
    }
}

export default MenuItem