import React from 'react'
import { toast } from 'react-toastify'
import { styles } from './styles/shareButtonStyles.js'
function ShareButton() {
    const copyLink = () => {
        const link = window.location.href

        if (window.isSecureContext && navigator.clipboard) {
            navigator.clipboard.writeText(link);
        } else {
            const textArea = document.createElement("textarea");
            textArea.value = link;
            document.body.appendChild(textArea);
            textArea.focus();
            textArea.select();
            try { document.execCommand('copy') }
            catch (err) { console.error('Unable to copy to clipboard', err) }
            document.body.removeChild(textArea)
        }

        toast.success('Link Copied to Clipboard', { theme: "colored", position: "bottom-center" })
    }
    return (
        <div>
            <div style={styles.main}>
                <button
                    style={styles.button}
                    onClick={copyLink}
                >
                    <span style={styles.span} class="material-symbols-outlined">
                        share
                    </span>
                    Share
                </button>

            </div>
        </div>
    )
}

export default ShareButton