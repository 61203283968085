import React from 'react'

function BlogAddItemSection({ addItem, blogBottomShown }) {
    return (
        <div className='create-blog-bottom'>
            <div className='create-blog-bottom-center-div'>
                {blogBottomShown ? (
                    <>
                        <div className='create-blog-bottom-center-top'>
                            <div className='blog-add-new-section-button' onClick={() => addItem('title')} >
                                <i className='bx bx-plus'></i>
                                Add Title
                            </div>
                            <div className='blog-add-new-section-button' onClick={() => addItem('text')}>
                                <i className='bx bx-plus'></i>
                                Add Pharagraph
                            </div>
                            <div className='blog-add-new-section-button' onClick={() => addItem('img')}>
                                <i className='bx bx-plus'></i>
                                Add Image
                            </div>
                        </div>
                        <div className='create-blog-bottom-center-bottom'>Add detail about your work on this area.</div>

                    </>
                ) : (
                    <div className='create-blog-bottom-center-bottom'>
                        If you want to open the project as a link, you cannot add content to the project.
                        To add content, you need to remove the link above.
                    </div>
                )}

            </div>
        </div>
    )
}

export default BlogAddItemSection