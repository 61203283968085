export const styles = {
    button: {
        display: 'flex',
        width: 'auto',
        justifyContent: 'center',
        alignItems: 'center',
        marginRight: 20,
        backgroundColor: 'transparent',
        fontSize: 19,
        color: 'white',
        border: 'none',
        padding: 10,
        borderRadius: 5
    },
    dropdownItem: {
        color: 'grey',
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer'
    }
}