import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';

function TagInput({ rolesState, handleTextInputs, role, addSelectedRole, removeSelectedRole, selectedRoles, setInputRole }) {

    // String ekleme


    // String silme


    return (
        <div className=" ">
            <form className="d-flex mb-3">
                <input
                    type="text"
                    className="form-control"
                    placeholder="Add a tag"
                    value={role}
                    data-toggle="dropdown" id="combobox"
                    onChange={(e) => setInputRole(e.target.value)}
                />
                <div class="dropdown-menu" aria-labelledby="combobox" style={{ backgroundColor: '#1a1a1a', width: 'auto', overflowY: 'auto', maxHeight: 200, display: rolesState.length == 0 ? 'none' : '' }}>
                    {
                        rolesState.map((item, index) => {
                            return (
                                <a onClick={() => setInputRole(item)} class="dropdown-item" href="#" style={{ color: 'white' }}>{item}</a>
                            )
                        })
                    }

                </div>
                <button onClick={() => addSelectedRole()} className="btn btn-primary ml-2" type="submit">
                    Add
                </button>
            </form>

            <div className="d-flex flex-wrap">
                {selectedRoles.map((tag, index) => (
                    <div
                        key={index}
                        className="badge badge-pill badge-info d-flex align-items-center mr-2 mb-2"
                        style={{ padding: '10px' }}
                    >
                        <span style={{ color: 'black' }}>{tag}</span>
                        <button
                            type="button"
                            className="btn btn-sm btn-danger mx-2"
                            onClick={() => removeSelectedRole(index)}
                            style={{ borderRadius: '50%' }}
                        >
                            &times;
                        </button>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default TagInput;
