import React from 'react'
import { useNavigate } from 'react-router-dom'
import PageLoadingAnimation from '../../../../../components/Loading/PageLoadingAnimation'
import { useGetBlogsByUserId } from '../../../../../customHooks/useGetBlogsByUserId'
function Works({ userId }) {
    const navigate = useNavigate()

    const [blogs, loading] = useGetBlogsByUserId(userId)

    if (loading) return <PageLoadingAnimation />
    return (
        <div className='blog-main'>
            <div className='blog-title-div'>
                <h3>Works</h3>
            </div>
            <div className='row justify-content-evenly w-100 '>
                {
                    blogs !== undefined &&
                    blogs.map((item, index) => {
                        if (typeof item === 'string') {
                            return (
                                <div className="col-lg-6 col-xl-6 col-md-12 col-sm-12 blog-img-div">
                                    <div>
                                        <a href="#">
                                            <img className='blog-card-img' src={item} alt="image" />
                                        </a>
                                    </div>
                                </div>
                            )
                        }
                        else {
                            if (item.directLink !== undefined && item.directLink !== '') {
                                return (
                                    <a className='blog-img-div'
                                        href={item.directLink}
                                        style={{ textDecoration: 'none', color: 'white' }}
                                    >
                                        <h3 style={{ fontWeight: 'bold', paddingBottom: 58 }}>{item.title}</h3>
                                        <div className='blog-img-content'>
                                            <img className='blog-card-img' src={item.cardImg} alt="image" />
                                            <div className='px-5 pt-5'>
                                                <p style={{ fontSize: 20 }}>{item.description}</p>
                                            </div>
                                        </div>
                                    </a>
                                )
                            } else {
                                return (
                                    <div className='blog-img-div'
                                        onClick={() => navigate('show-blog/' + item._id)}
                                    >
                                        <h3 style={{ fontWeight: 'bold', paddingBottom: 58 }}>{item.title}</h3>
                                        <div className='blog-img-content'>
                                            <img className='blog-card-img' src={item.cardImg} alt="image" />
                                            <div className='px-5 pt-5'>
                                                <p style={{ fontSize: 20 }}>{item.description}</p>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }

                        }

                    }
                    )}
            </div>

        </div >
    )
}

export default Works