import React, { useEffect, useRef, useState } from 'react'
import MiniChatMessage from './MiniChatMessage'
import { openai } from '../../config/openAiConfig'
function MiniChatContent() {
    const [text, setText] = useState('')
    const [chat, setChat] = useState([])

    const divRef = useRef(null);

    useEffect(() => {
        divRef.current?.lastElementChild?.scrollIntoView({ behavior: 'smooth' });
    }, [chat]);


    const handleSubmit = () => {

        let newValue = { 'id': 'me', 'message': text };
        setChat(chat => [...chat, newValue]);
        setText('')
        openai.createChatCompletion({
            model: "gpt-3.5-turbo",
            messages: [{ role: 'user', content: text }]
        })
            .then((result) => {
                newValue = { 'id': 'ai', 'message': result.data.choices[0].message.content };
                setChat(chat => [...chat, newValue]);
            })
            .catch((err) => {
                console.log(err)
            })
    }

    return (
        <div className="collapse mini-chat-content" id="collapseExample">
            <ul class="nav justify-content-center">
                <li class="nav-item">
                    <a class="nav-link active" aria-current="page" href="#">
                        <i class="material-symbols-outlined">
                            psychology
                        </i>
                    </a>
                </li>
                {/* <li class="nav-item">
                    <a class="nav-link active" aria-current="page" href="#">
                        <img _ngcontent-mcm-c191="" src="/assets/images/users/avatar-2.jpg" alt="" class="rounded-circle avatar-xs" />
                    </a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" href="#">
                        <img _ngcontent-mcm-c191="" src="/assets/images/users/avatar-1.jpg" alt="" class="rounded-circle avatar-xs" />
                    </a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" href="#">
                        <img _ngcontent-mcm-c191="" src="/assets/images/users/avatar-3.jpg" alt="" class="rounded-circle avatar-xs" />
                    </a>
                </li>
                <li class="nav-item">
                    <a class="nav-link disabled" href="#" tabindex="-1" aria-disabled="true">
                        <img _ngcontent-mcm-c191="" src="/assets/images/users/avatar-4.jpg" alt="" class="rounded-circle avatar-xs" />
                    </a>
                </li> */}
            </ul>

            <div class="card" style={{ backgroundColor: '#1b1b1b' }}>
                <div class="card-body border-bottom">
                    <div class="row">
                        <div class="col-md-4 col-9">
                            <h5 class="font-size-15 mb-1">Chat Bot</h5>
                            <p class="text-muted mb-0"><i class="mdi mdi-circle text-success align-middle me-1"></i>
                                Active now</p>
                        </div>
                        <div class="col-md-8 col-3">
                            <ul class="list-inline user-chat-nav text-end mb-0">
                                {/* <li class="list-inline-item d-none d-sm-inline-block">
                                    <div class="dropdown" >
                                        <button class="btn nav-btn dropdown-toggle" type="button" data-toggle="dropdown"
                                            aria-haspopup="true" aria-expanded="false" >
                                            <i class="bx bx-search-alt-2"></i>
                                        </button>
                                        <div class="dropdown-menu dropdown-menu-end dropdown-menu-md" >
                                            <form class="p-3">
                                                <div class="m-0">
                                                    <div class="input-group">
                                                        <input type="text" class="form-control" placeholder="Search ..."
                                                            aria-label="Recipient's username" />
                                                        <div class="input-group-append">
                                                            <button class="btn btn-primary" type="submit"><i
                                                                class="mdi mdi-magnify"></i></button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </li> */}
                                {/* <li class="list-inline-item d-none d-sm-inline-block">
                                    <div class="dropdown" >
                                        <button class="btn nav-btn dropdown-toggle" type="button" data-toggle="dropdown"
                                            aria-haspopup="true" aria-expanded="false" >
                                            <i class="bx bx-cog"></i>
                                        </button>
                                        <div class="dropdown-menu dropdown-menu-end" >
                                            <a class="dropdown-item" href="javascript: void(0);">View Profile</a>
                                            <a class="dropdown-item" href="javascript: void(0);">Clear chat</a>
                                            <a class="dropdown-item" href="javascript: void(0);">Muted</a>
                                            <a class="dropdown-item" href="javascript: void(0);">Delete</a>
                                        </div>
                                    </div>
                                </li> */}

                                {/* <li class="list-inline-item">
                                    <div class="dropdown" >
                                        <button class="btn nav-btn dropdown-toggle" type="button" data-toggle="dropdown"
                                            aria-haspopup="true" aria-expanded="false" >
                                            <i class="bx bx-dots-horizontal-rounded"></i>
                                        </button>
                                        <div class="dropdown-menu dropdown-menu-end" >
                                            <a class="dropdown-item" href="javascript: void(0);">Action</a>
                                            <a class="dropdown-item" href="javascript: void(0);">Another action</a>
                                            <a class="dropdown-item" href="javascript: void(0);">Something else</a>
                                        </div>
                                    </div>
                                </li> */}

                            </ul>
                        </div>
                    </div>
                </div>
                <div class="card-body pb-0">
                    <div >
                        <div class="chat-conversation" style={{ overflow: 'scroll', height: '260px' }}  >
                            <ul class="list-unstyled" ref={divRef}>

                                {/* <li>
                                    <div class="chat-day-title">
                                        <span class="title">Today</span>
                                    </div>
                                </li> */}
                                {chat.map((item, index) => (
                                    <MiniChatMessage message={item.message} id={item.id} />
                                ))}

                            </ul>
                        </div>

                    </div>
                </div>

                <div class="p-3 chat-input-section">

                    <div class="row">
                        <div class="col">
                            <div class="position-relative">
                                <input type="text" class="form-control rounded popup-chat-input"
                                    placeholder="Enter Message..."
                                    value={text}
                                    onChange={(e) => { setText(e.target.value) }}
                                    style={{ color: 'white' }}
                                />
                                <div class="invalid-feedback">
                                    <span>Message is required.</span>
                                </div>
                                {/* <div class="chat-input-links">
                                    <ul class="list-inline mb-0">
                                        <li class="list-inline-item"><a href="javascript: void(0);" data-placement="top"
                                            ngbTooltip="Emoji"><i class="mdi mdi-emoticon-happy-outline"></i></a>
                                        </li>
                                        <li class="list-inline-item"><a href="javascript: void(0);" data-placement="top"
                                            ngbTooltip="Images"><i class="mdi mdi-file-image-outline"></i></a></li>
                                        <li class="list-inline-item"><a href="javascript: void(0);" data-placement="top"
                                            ngbTooltip="Add Files"><i class="mdi mdi-file-document-outline"></i></a>
                                        </li>
                                    </ul>
                                </div> */}
                            </div>
                        </div>
                        <div class="col-auto">
                            <button onClick={() => {
                                handleSubmit()
                            }} class="btn btn-soft-primary chat-send w-md"><span
                                class="d-none d-sm-inline-block me-2">Send</span> <i
                                    class="mdi mdi-send"></i></button>
                        </div>
                    </div>

                </div >
            </div >
        </div>
    )
}

export default MiniChatContent
//Mesajın sağında bulunan 3 nokta
{/* <div class="dropdown" >
                                            <a class="dropdown-toggle" href="javascript: void(0);" role="button"
                                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                                            >
                                                <i class="bx bx-dots-vertical-rounded"></i>
                                            </a>
                                            <div class="dropdown-menu" >
                                                <a class="dropdown-item" href="javascript: void(0);">Copy</a>
                                                <a class="dropdown-item" href="javascript: void(0);">Save</a>
                                                <a class="dropdown-item" href="javascript: void(0);">Forward</a>
                                                <a class="dropdown-item" href="javascript: void(0);">Delete</a>
                                            </div>
                                        </div> */}