import React from 'react';

function Step5({ handleTextInputs, setStep, step }) {
    return (
        <div className='appyl-inputs-div'>
            <h5 style={{ color: 'black', fontWeight: 'bold' }}>Payment</h5>
            <h6 style={{ color: 'black' }}>Payment Informations</h6>
            <div className='input-row'>
                <input
                    className='apply-input half-width'
                    placeholder='Your Full Name'
                    onChange={(e) => handleTextInputs(e.target.value, 'fullName')}
                />
                <input
                    className='apply-input half-width'
                    placeholder='Card Number'
                    onChange={(e) => handleTextInputs(e.target.value, 'cardNumber')}
                />
            </div>
            <div className='input-row'>
                <input
                    className='apply-input custom-width'
                    placeholder='Expiry Date'
                    onChange={(e) => handleTextInputs(e.target.value, 'expiryDate')}
                />
                <input
                    className='apply-input custom-width'
                    placeholder='Security Code'
                    onChange={(e) => handleTextInputs(e.target.value, 'securityCode')}
                />
                <input
                    className='apply-input custom-width'
                    placeholder='Discount Code'
                    onChange={(e) => handleTextInputs(e.target.value, 'discountCode')}
                />
            </div>
            <div className='button-row'>
                <button className='apply-buttons active' onClick={() => setStep(step - 1)}>Back</button>
                <button className='apply-buttons' onClick={() => setStep(step + 1)}>Continue</button>
            </div>
            <style>
                {`
                    
                    .input-row {
                        display: flex;
                        flex-wrap: wrap;
                        justify-content: space-between;
                        width: 100%;
                        max-width: 700px;
                        margin-bottom: 20px;
                    }
                   
                    .half-width {
                        width: 48%;
                    }
                    .custom-width {
                        width:30%;
                    }
                    @media (max-width: 768px) {
                        .half-width, .custom-width {
                            width: 100%;
                        }
                    }
                `}
            </style>
        </div>
    );
}

export default Step5;
