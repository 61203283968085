import React from 'react'
import { useNavigate } from 'react-router-dom'

function Ticket({ ticket }) {
    const navigate = useNavigate()
    return (
        <div className="boxxx">
            <div className="group">
                <div className="overlap-group" onClick={() => navigate('/dashboard/ticket-detail', {
                    state: {
                        ticketId: ticket._id
                    }
                })} >
                    <div className="div">
                        <div className="frame">
                            <div className="ellipse" />
                            <div className="ellipse-2" />
                            <div className="ellipse-3" />
                            <div className="ellipse-4" />
                        </div>
                        <div className="text-wrapper">Active Ticket</div>
                        <div className="text-wrapper-2">Matched Freelancers</div>
                        <div className="text-wrapper-3 ">{ticket.title}</div>
                        {/* <div className="text-wrapper-4 py-2">{ticket.description}</div> */}
                        <div className="budget-time">{ticket.budget}
                            {/* &amp; */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Ticket