import axios from "axios"
import { useEffect, useState } from "react"
import { GET_BLOG_BY_USER_ID } from "../constants/links"

export const useGetBlogsByUserId = (userId, dependencies) => {
    const [works, setBlogs] = useState()
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        axios.get(GET_BLOG_BY_USER_ID, {
            headers: {
                userId
            }
        })
            .then((res) => {
                setBlogs(res.data)
                setLoading(false)
            })
            .catch((err) => console.log(err))
    }, dependencies)

    return [works, loading]
}