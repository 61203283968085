import axios from 'axios'
import Cookies from 'js-cookie'
import React, { useEffect, useState } from 'react'
import ReactLoading from 'react-loading'
import { useDispatch } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { GET_UNREAD_NOTIFICATIONS } from '../../constants/links'
import { getUserIdFromCookie } from '../../helpers/cookieHelper'
import useWindowDimensions from '../../helpers/useWindowDimension'
import { setUserProfile } from '../../redux/userProfile/userProfileSlice'
import { styles } from '../styles/topBarStyles'
import UserMenuButton from './components/UserMenuButton'
function TopBar({ isMenuOpen, setIsMenuOpen, userProfile }) {
    const { height, width } = useWindowDimensions();
    const navigate = useNavigate()
    const [loading, setLoading] = useState(true)
    const [unReadNotifications, setUnReadNotifications] = useState([])
    const dispatch = useDispatch()



    useEffect(() => {
        axios.get(GET_UNREAD_NOTIFICATIONS + getUserIdFromCookie())
            .then(res => {
                setUnReadNotifications(res.data)
                setLoading(false)
            })
            .catch((e) => {
                console.log(e)
            })

    }, [])

    const logout = () => {
        Cookies.remove('jwt_user')
        dispatch(setUserProfile({}))
        navigate('/login')
    }

    return (
        <header id="page-topbar" style={{ color: 'white', }}>
            <div class="navbar-header">
                <div class="d-flex">
                    <div class="navbar-brand-box p-2" style={isMenuOpen ? {} : { width: '81px' }}>
                        <a onClick={() => navigate("/dashboard")} style={{ cursor: 'pointer' }} class="">
                            {isMenuOpen && width > 530 ? (
                                <div style={{ height: 50, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <img src="/assets/images/logo-text-white.svg" alt="" height="35" />
                                </div>
                            )
                                :
                                (
                                    <span class="logo-lg">
                                        <img src="/assets/images/logo.png" alt="" height="45" />
                                    </span>
                                )}
                        </a>
                    </div>

                    <button onClick={() => setIsMenuOpen(!isMenuOpen)} type="button" class="btn btn-sm px-3 font-size-16 header-item" id="vertical-menu-btn"
                    >
                        <i class="fa fa-fw fa-bars"></i>
                    </button>


                </div>


                <div class="d-flex">
                    <button type="button" class="btn header-item " data-toggle="dropdown" id="notification-dropdown">
                        <i style={{ color: 'white', fontSize: 35, position: 'relative' }} class='bx bxs-bell' >
                            {
                                unReadNotifications.length !== 0 ? (
                                    <span style={{ backgroundColor: 'red', position: 'absolute', top: 0, right: 0, display: 'block', fontSize: 13, borderRadius: '50%' }} class="badge badge-pill badge-danger">
                                        {unReadNotifications.length}
                                    </span>

                                ) : (<></>)
                            }
                        </i>
                    </button>

                    {/* Sadece freelancer için */}
                    <div class="dropdown-menu left-0" aria-labelledby="notification-dropdown" style={{ backgroundColor: '#1a1a1a' }}>
                        {
                            unReadNotifications.length !== 0 ? unReadNotifications.map((notification) => {
                                return (
                                    <Link to={'/dashboard/notifications'} onClick={() => {
                                        setUnReadNotifications([])
                                    }} className='dropdown-item' style={{ color: 'white' }}>
                                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                                            <div>
                                                <h6>{notification.fullName}</h6>
                                                <p>{notification.jobDesc}</p>
                                            </div>
                                        </div>
                                    </Link>
                                )
                            }) : (
                                <div className='dropdown-item' style={{ color: 'white' }}>
                                    <h6>No Notifications</h6>
                                </div>
                            )
                        }
                    </div>

                    {
                        loading ? (
                            <ReactLoading type={'spinningBubbles'} color={'white'} height={'40px'} width={'40px'} />
                        )
                            : (
                                <div style={{ display: 'flex', flexDirection: 'row' }} >


                                    <UserMenuButton profileImg={userProfile.profileImg} fullName={userProfile.fullName} />


                                    <div class="dropdown-menu" aria-labelledby="page-header-user-dropdown" style={{ backgroundColor: '#1a1a1a' }}>

                                        <a onClick={() => navigate("/dashboard/account")} style={styles.dropdownItem} class="dropdown-item" >
                                            <i class="bx bx-user font-size-16 align-middle me-1"></i>
                                            My Account
                                        </a>

                                        <a onClick={() => navigate("/dashboard/" + userProfile.linkNick)} style={styles.dropdownItem} class="dropdown-item" >
                                            <i class="bx bx-user font-size-16 align-middle me-1"></i>
                                            My Portfolio
                                        </a>
                                        <a onClick={() => navigate("/dashboard/upper-profile-create")} style={styles.dropdownItem} class="dropdown-item" >
                                            <i class="bx bx-pencil font-size-16 align-middle me-1"></i>
                                            Edit Portfolio
                                        </a>

                                        <a onClick={logout} style={styles.dropdownItem} class="dropdown-item text-danger"><i
                                            class="bx bx-power-off font-size-16 align-middle me-1 text-danger"></i>
                                            Log Out
                                        </a>
                                    </div>

                                </div>
                            )
                    }

                </div >
            </div >
        </header >
    )
}

export default TopBar